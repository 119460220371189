import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./AllOrder.scss";
import { Link } from "react-router-dom";
import OrderCancelModal from "../../../components/Ui/Modal/OrderCancelModal";
import ShowOrderDetailsModal from "../../../components/Ui/Modal/ShowOrderDetailsModal";
import OrderReturnModal from "../../../components/Ui/Modal/OrderReturnModal";

const AllOrder = ({ order, viewDetail }) => {
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showReturnModal, setShowReturnModal] = useState(false)
  const { totalAmount, orderId, products, status, createdAt, report, updatedAt } = order || {};

  const deliveryDate = new Date(updatedAt);
  const today = new Date();

  // Convert both dates to milliseconds
  const deliveryDateMillisecond = deliveryDate.getTime();
  const todayMillisecond = today.getTime();
  const differenceMillisecond = deliveryDateMillisecond - todayMillisecond;
  const differenceDays = Math.abs(Math.floor(differenceMillisecond / (24 * 60 * 60 * 1000)));

  return (
    <div className="border p-2 my-3">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <p className="text-primary">Order Id: {orderId}</p>
          <small>
            Date: {`${new Date(createdAt).toLocaleString()}`}
          </small>
        </div>
        <div>
          <small className="fw-semibold">Total: {totalAmount}</small> <br />
          {viewDetail && <Link to="" className="text-decoration-none" style={{ fontSize: "0.9em" }} onClick={() => setShowDetailModal(true)}>DETAILS</Link>}
        </div>


      </div>
      {
        products?.map((orderData) => (
          <Row key={orderData?._id} className="mt-4">
            <Col xs={3} md={2}>
              <Image src={orderData?.product?.images?.[0]} fluid />
            </Col>
            <Col xs={7} md={8}>
              <p className="">{orderData?.product?.name}</p>
              <small className="order__status">
                Order  {status}
              </small>
            </Col>
            <Col xs={2} md={2}>
              <div className="d-flex align-items-center flex-column mt-0">
                <p>Qty</p>
                <p>{orderData?.quantity}</p>
              </div>
            </Col>
          </Row>
        ))
      }

      <div className="text-end">
        {(status === "pending" || status === "processing") && (
          <p>
            {
              report ? <p className="status_parent">Cancellation {report?.status}</p> : <button onClick={() => setShowCancelModal(true)} className={`btn btn-sm btn-outline-danger`}>Cancel</button>
            }
          </p>
        )
        }
        {status === "delivered"
          && (
            <p>

              <small className="me-2">{differenceDays} {differenceDays > 1 ? "days" : "day"} ago</small>

              {!report && differenceDays <= 7 && <button onClick={() => setShowReturnModal(true)} className={`btn btn-sm btn-success`}>Return</button>}
              {report && <p className="status_parent">Return {report?.status}</p>}

            </p>
          )
        }
      </div>
      <OrderCancelModal setShowCancelModal={setShowCancelModal} showCancelModal={showCancelModal} orderId={orderId} />
      <OrderReturnModal setShowReturnModal={setShowReturnModal} showReturnModal={showReturnModal} orderId={orderId} />
      <ShowOrderDetailsModal setShowDetailModal={setShowDetailModal} showDetailModal={showDetailModal} order={order} />
    </div >
  );
};

export default AllOrder;
