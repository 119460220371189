import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://server.koreanmallbd.org/v1",
    // baseUrl: "http://localhost:8000/v1",
  }),
  tagTypes: ["AddCartData"],
  endpoints: () => ({}),
});
