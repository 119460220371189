import { apiSlice } from "../api/apiSlice";

export const couponApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get all order price with coupon email
    checkCouponIsValidate: builder.mutation({
      query: (data) => ({
        url: `/coupon/verify/${data.email}`,
        method: "POST",
        body: data,

      }),
      invalidatesTags: ["AllCartData"]
    }),

  }),
});

export const {
  useCheckCouponIsValidateMutation,
  useInitialDataWithoutCouponMutation,
} = couponApi;
