import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useOrderCancelMutation } from '../../../redux/addToCart/addToCartApi';
import Error from '../error/Error';
import Success from '../error/Success';
import { useSelector } from 'react-redux';

const OrderReturnModal = ({ showReturnModal, setShowReturnModal, orderId }) => {
    const [orderCancel, { isLoading, isSuccess, error }] = useOrderCancelMutation();
    const { user } = useSelector(state => state.user);
    console.log(user)
    const closeModal = () => {
        setShowReturnModal(false)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const reason = e.target.reason.value;
        const description = e.target.description.value;
        const data = {
            email: user.email,
            requestedFor: "return",
            orderId,
            reason,
            description,

        }
        orderCancel(data)
    }



    return (
        <Modal
            className="d-flex  align-items-center"
            show={showReturnModal}
            onHide={closeModal}
        >
            <Modal.Header closeButton>
                <h5 className="fw-semibold ms-2">Return Order</h5>
            </Modal.Header>
            <Modal.Body>

                {error && <Error>{error?.data?.message || "Order cancel failed"}</Error>}
                {isSuccess && <Success>{"Request send successful, Wait for approval."}</Success>}

                <Form onSubmit={handleSubmit}>
                    <fieldset >
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledSelect">Why return this order</Form.Label>
                            <Form.Select name="reason" id="disabledSelect">
                                <option value="Shipped the wrong item">Shipped the wrong item</option>
                                <option value="Unmet expectations">Unmet expectations</option>
                                <option value="Changed mind">Damaged or defective products
                                </option>
                                <option value="Package being unclaimed at customs">Package being unclaimed at customs</option>

                                <option value="Other reasons">Other reasons</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="textArea">Tell us about return</Form.Label>
                            <textarea name="description" required minLength={10} className="form-control" id="textArea" rows="3"></textarea>
                        </Form.Group>


                        <div className='text-end'>
                            <Button type="submit">{isLoading ? "Loading..." : "Submit"}</Button>
                        </div>
                    </fieldset>
                </Form>
            </Modal.Body>

        </Modal>
    );
};

export default OrderReturnModal;