import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useOrderCancelMutation } from '../../../redux/addToCart/addToCartApi';
import Error from '../error/Error';
import Success from '../error/Success';
import { useSelector } from 'react-redux';

const OrderCancelModal = ({ showCancelModal, setShowCancelModal, orderId }) => {
    const [orderCancel, { isLoading, isSuccess, error }] = useOrderCancelMutation();
    const { user } = useSelector(state => state.user);

    const closeModal = () => {
        setShowCancelModal(false)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const reason = e.target.reason.value;
        const description = e.target.description.value;
        const data = {
            email: user.email,
            requestedFor: "cancel",
            orderId,
            reason,
            description,

        }
        orderCancel(data)
    }



    return (
        <Modal
            className="d-flex  align-items-center"
            show={showCancelModal}
            onHide={closeModal}
        >
            <Modal.Header closeButton>
                <h5 className="fw-semibold ms-2">Cancel Order</h5>
            </Modal.Header>
            <Modal.Body>

                {error && <Error>{error?.data?.message || "Order cancel failed"}</Error>}
                {isSuccess && <Success>{"Request send successful, Wait for approval."}</Success>}

                <Form onSubmit={handleSubmit}>
                    <fieldset >
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledSelect">Why cancel this order</Form.Label>
                            <Form.Select name="reason" id="disabledSelect">
                                <option value="Long delivery timeframes">Long delivery timeframes</option>
                                <option value="Changed mind">Changed mind
                                </option>
                                <option value="High shipping costs">High shipping costs</option>
                                <option value="Unfavorable return policy">Unfavorable return policy</option>
                                <option value="Rigorous payment options">Rigorous payment options</option>
                                <option value="Other reasons">Other reasons</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="textArea">Tell us about cancel</Form.Label>
                            <textarea name="description" required minLength={10} className="form-control" id="textArea" rows="3"></textarea>
                        </Form.Group>


                        <div className='text-end'>
                            <Button type="submit">{isLoading ? "Loading..." : "Submit"}</Button>
                        </div>
                    </fieldset>
                </Form>
            </Modal.Body>

        </Modal>
    );
};

export default OrderCancelModal;