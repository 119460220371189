import React from "react";
import CategoryCarusel from "./ShopBy/CategoryCarusel";
import { useGetCategoryByTitleQuery } from "../../../redux/categories/categoriesApi";

const SkinType = () => {
    const { data } = useGetCategoryByTitleQuery("subCategory=skin type");
    return <CategoryCarusel data={data?.data?.children} />;
};

export default SkinType;
