import React from "react";
import { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";

const SecondLabelDropdown = ({ subCategory, handleToggle }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const renderDropdownTitle = (title) => (
        <div className="d-flex justify-content-between align-items-center">
            <div
                style={{ display: "block", }}
                className=" d-flex align-items-center justify-content-between  "
            >
                {title}
            </div>
            {!dropdownOpen ? <AiOutlinePlus /> : <AiOutlineMinus />}
        </div>
    );

    const handleDropdownToggle = (isOpen) => {
        setDropdownOpen(isOpen);
    };
    return (
        <>
            {subCategory?.children?.length > 0 ? (
                <NavDropdown
                    style={{
                        marginLeft: "1rem",
                    }}
                    title={renderDropdownTitle(subCategory?.title)}
                    id={subCategory?._id}
                    onToggle={handleDropdownToggle}
                    renderMenuOnMount
                >
                    {subCategory?.children?.map((subCategoryChild) => (
                        <NavDropdown.Item
                            style={{
                                marginLeft: "1rem",
                                marginRight: "1rem",
                                paddingLeft: "0.8rem",
                                color: "#cccccc",
                            }}
                            key={subCategoryChild?._id}
                            as={Link}
                            onClick={handleToggle}
                            to={`products?category=${subCategoryChild?.slug}`}
                        >
                            {subCategoryChild?.title}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
            ) : (
                <NavDropdown.Item
                    style={{
                        marginLeft: "1.8rem",
                        color: "#ccc",
                        borderBottom: "1px solid #333",
                    }}
                    as={Link}
                    onClick={handleToggle}
                    to={`products?category=${subCategory.slug}`}
                >
                    {subCategory?.title}
                </NavDropdown.Item>
            )}
        </>
    );
};

export default SecondLabelDropdown;
