import { apiSlice } from "../api/apiSlice";

export const addToCartApi = apiSlice.injectEndpoints({
    tagTypes: ["AllAddress", "SingleAddress", "ActiveAddress"],
    endpoints: (builder) => ({
        //get all add to cart data by email address
        getAllAddress: builder.query({
            query: (email) => ({
                url: `/address/all/${email}`,
                method: "POST",
            }),
            providesTags: ["AllAddress"],
        }),

        //get all add to cart data by email address
        getSingleAddress: builder.query({
            query: (addressId) => ({
                url: `/address/${addressId}`,
                method: "POST",
            }),
            providesTags: ["SingleAddress"],
        }),

        createAddress: builder.mutation({
            query: ({ email, data }) => ({
                url: `/address/${email}`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["AllAddress", "ActiveAddress", "AllCartData"]
        }),

        //update address by email address
        updateAddress: builder.mutation({
            query: ({ id, data }) => ({
                url: `/address/${id}`,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["AllAddress", "ActiveAddress", "SingleAddress", "AllCartData"]
        }),

        //update address by email address
        setActiveAddress: builder.mutation({
            query: ({ email, id }) => ({
                url: `/address/active/${email}`,
                method: "PUT",
                body: { id }
            }),
            invalidatesTags: ["AllAddress", 'ActiveAddress', "AllCartData"]
        }),

        //get active address by email 
        getActiveAddress: builder.query({
            query: (email) => ({
                url: `/address/active/${email}`,
                method: "POST",
            }),
            providesTags: ["ActiveAddress"]
        }),

        //update address by email address
        deleteAddress: builder.mutation({
            query: ({ id, email }) => ({
                url: `/address/${email}`,
                method: "DELETE",
                body: { id }
            }),
            invalidatesTags: ["AllAddress"]
        }),


    }),
});

export const {
    useGetAllAddressQuery,
    useGetActiveAddressQuery,
    useGetSingleAddressQuery,
    useCreateAddressMutation,
    useUpdateAddressMutation,
    useDeleteAddressMutation,
    useSetActiveAddressMutation,

} = addToCartApi;
