import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import notFound from "../../assets/images/not_found.svg";
const NotFound = () => {
  return (
    <Container
      style={{ height: "80vh" }}
      className="w-100 d-flex justify-content-center align-items-center "
    >
      <Row className="d-flex flex-row ">
        <Col
          xs={12}
          md={6}
          lg={6}
          xl={6}
          className="d-flex justify-content-center align-items-center mt-5 order-md-1 order-sm-2"
        >
          <div>
            <h1 className="fs-2">
              <span className="text-danger">Oops!</span> The page not found!
            </h1>
            <h2>Please go back</h2>
            <Button as={Link} to="/" variant="dark">
              Go to home
            </Button>
          </div>
        </Col>
        <Col xs={12} md={6} lg={6} xl={6} className=" order-md-2">
          <Image fluid src={notFound} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
