import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './addToCart/addToCartSlice';
import { apiSlice } from './api/apiSlice';
import authSliceReducer from './auth/authSlice.js';
import comboReducer from './combo/comboSlice';
import modalSliceReducer from './modal/modalSlice';
import searchReducer from './products/productSlice';

const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		user: authSliceReducer,
		searchText: searchReducer,
		modal: modalSliceReducer,
		comboProducts: comboReducer,
		cartData: cartReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(apiSlice.middleware),
});
export default store;
