import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = ({ height }) => {
    return (
        <div
            style={{ height: `${height}` }}
            className=" w-100 d-flex align-items-center justify-content-center gap-1"
        >
            {" "}
            <Spinner animation="grow" size="sm" />
            <Spinner animation="grow" />
        </div>
    );
};

export default Loading;
