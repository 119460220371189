import React from "react";
import { Container } from "react-bootstrap";
import Heading from "../../../components/Ui/Heading";
import { useGetPrivacyPolicyQuery } from "../../../redux/pages/pagesApi";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';

const PrivacyPolicy = () => {
    const { data, isLoading } = useGetPrivacyPolicyQuery();

    const [language, setLanguage] = useState('English');

    const toggleLanguage = () => {
        if (language === 'English') {
            setLanguage('Bangla');
        } else {
            setLanguage('English');
        }
    };

    return (
        <Container>
            <Heading>Privacy Policy</Heading>
            {/* <div className="d-flex justify-content-between bg-info p-3 text-center">
                <div></div>
                <div><h1>{language === 'English' ? 'PRIVACY POLICY!' : 'গোপনীয়তা'}</h1></div>
                <div><Button onClick={toggleLanguage}>Eng/
                    বাংলা</Button></div>

            </div> */}
            {data && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: data?.data?.[0]?.description,
                    }}
                />
            )}



        </Container>
    );
};

export default PrivacyPolicy;
