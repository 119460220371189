import React from 'react';
import { Container } from 'react-bootstrap';
import Heading from '../../../components/Ui/Heading';

const SiteMap = () => {
	return (
		<Container>
			<Heading>Sitemap</Heading>
			<div className="text-center text-uppercase">
				This page is under development.
			</div>
		</Container>
	);
};

export default SiteMap;
