import React from "react";
const SortingSection = () => {
    return (
        <div
            className="fw-bold pb-2 mt-2"
            style={{ borderBottom: "3px solid black", width: "8rem" }}
        >
            Product Reviews
        </div>
    );
};

export default SortingSection;
