import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { MdAccessTimeFilled, MdCall, MdLocationPin } from 'react-icons/md';
import Heading from '../../../components/Ui/Heading';
import ContactForm from '../../../forms/ContactForm';
import { Form } from 'react-hook-form';
import { FaBuilding } from 'react-icons/fa';
import { ImPhone } from 'react-icons/im';

const ContactUs = () => {
	return (
		<section>
			<div>
				<h1 style={{ fontSize: "2	rem" }} className='py-3 text-center'>Contact Us <span className='fs-6'>Fell free to contact us</span></h1>
			</div>
			<Container>

				<Row className='border'>
					<Col className='bg-light' xs={12} sm={4} md={4}>
						<div className='p-3'>
							<h6 className='fw-bold'>Name</h6>
							<input style={{ width: "98%", height: "2.5rem" }} type="text" name="" id="" />
							<h6 className='fw-bold pt-3'>Email Address</h6>
							<input style={{ width: "98%", height: "2.5rem" }} type="email" name="" id="" />
							<h6 className='fw-bold pt-3'>Subject</h6>
							<input style={{ width: "98%", height: "2.5rem" }} type="text" name="" id="" />
						</div>
					</Col>
					<Col className='bg-light' xs={12} sm={4} md={4}>
						<div className='p-3'>
							<h6 className='fw-bold'>Message</h6>
							<input style={{ width: "98%", height: "14rem" }} type="text" name="" id="" />
							<div className='d-flex justify-content-end mt-4'>
								<button className='p-2 fw-bold bg-dark text-light me-2'>Send Message</button>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={4} md={4}>
						<div className='p-2'>
							<div className=''>
								<h3><  FaBuilding className='me-1' />Our Office</h3>
								<hr />
							</div>
							<div className='p-2'>

								<h6 className='fw-bold'>The Korean Mall <br /> Korean Cosmetics and Skin Care Products</h6>
								<p style={{ fontSize: ".9rem" }} className='fw-bold'>GULSHAN OUTLET</p>
								<small>Shop # 238, 1st Floor <br /> Police Plaza Concord Shopping Mall <br />
									Hatirjheel, Gulshan 1</small>

									<div className='mt-3'>
									<p style={{ fontSize: ".9rem" }} className='fw-bold'>DHANMONDI OUTLET</p>
								<small>1102, 1st Floor <br />Shimanto Shombhar Shopping Mall <br />
Dhanmondi, Dhaka, Bangladesh 1212</small>
									</div>
							</div>
							<div className='p-2 mt-2'>
								<h6 className='fw-bold'>Contact Number</h6>
								<p> <ImPhone />+8801756167724</p>
							</div>
						</div>
					</Col>

				</Row>
			</Container>
		</section>





		// <Container>
		// 	<Heading>Contact Us</Heading>
		// 	<Row>
		// 		<Col md={4}>
		// 			<Row className="text-center flex-column gy-5">
		// 				<Col>
		// 					<MdLocationPin style={{ fontSize: '4rem' }} />
		// 					<div className="mt-3">
		// 						<a
		// 							href="#"
		// 							rel="noreferrer noopener"
		// 							className="text-decoration-none text-dark"
		// 						>
		// 							Plot- 06, Road-14, Sector-06, <br /> Uttara, Dhaka
		// 						</a>
		// 					</div>
		// 				</Col>
		// 				<Col>
		// 					<MdCall style={{ fontSize: '4rem' }} />
		// 					<div className="mt-3">
		// 						<a
		// 							href="tel:+8801756167724"
		// 							className="text-decoration-none text-dark"
		// 						>
		// 							+88 01756 167724
		// 						</a>
		// 					</div>
		// 				</Col>
		// 				<Col>
		// 					<MdAccessTimeFilled style={{ fontSize: '4rem' }} />
		// 					<div className="mt-3">Always</div>
		// 				</Col>
		// 			</Row>
		// 		</Col>
		// 		<Col md={8}>
		// 			<ContactForm />
		// 		</Col>
		// 	</Row>
		// </Container>
	);
};

export default ContactUs;
