import React, { useEffect, useState } from 'react';
import { Button, Card, Stack } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IoIosArrowForward } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetAddToCartDataByEmailQuery } from '../../redux/addToCart/addToCartApi';
import { setCartDrawer } from '../../redux/modal/modalSlice';
import './AddToCartOffcanvas.scss';
import ProductHorizontalCard from './ProductHorizontalCard';
import CheckCoupon from '../../pages/Checkout/CheckCoupon';
import { AiOutlineCheckCircle } from "react-icons/ai"

const AddToCartOffcanvas = () => {
	const userEmail = useSelector((state) => state.user?.user?.email);
	const { cartDrawer } = useSelector((state) => state.modal);
	const dispatch = useDispatch();
	const { cartData } = useSelector((state) => state.cartData);
	const [loggedInUser, setLoggedInUser] = useState(true);
	const [showCoupon, setShowCoupon] = useState(false);

	const {
		data: allCartData,
		isLoading,
		isError,
	} = useGetAddToCartDataByEmailQuery(userEmail, {
		skip: loggedInUser,
	});

	useEffect(() => {
		if (userEmail) {
			setLoggedInUser(false);
		}
	}, [userEmail]);

	let content = null;

	if (isLoading) {
		content = <p>Loading..</p>;
	}
	if (!isLoading && isError) {
		content = <p className="text-danger">There is something wrong!</p>;
	}
	if (
		(!isLoading &&
			!isError &&
			allCartData?.data?.cartData?.products?.length === 0) ||
		cartData.length === 0
	) {
		content = (
			<div style={{ marginTop: '50%' }} className="border-bottom pb-4">
				<h4 className="text-center">Your bag is empty</h4>
				<p className="text-center">Add your favorite items to your bag.</p>
				<Button
					onClick={() => dispatch(setCartDrawer(false))}
					as={Link}
					to="/"
					className="shadow-none"
					style={{
						backgroundColor: '#7dcfb6',
						padding: '10px',
						margin: '20px 0px 10px 10px',
						fontSize: '18px',
						textAlign: 'center',
						fontWeight: 'bold',
						width: '95%',
						letterSpacing: '1px',
						border: 'none',
					}}
				>
					Shop Now
				</Button>
			</div>
		);
	}

	if (!userEmail) {
		if (cartData?.length > 0) {
			content = cartData?.map((cart) => (
				<ProductHorizontalCard key={cart?._id} cart={cart} />
			));
		}
	} else {
		if (
			!isLoading &&
			!isError &&
			allCartData?.data?.cartData?.products?.length > 0
		) {
			content = (
				<Stack gap={3}>
					{allCartData?.data?.cartData?.products?.map((cart) => (
						<ProductHorizontalCard key={cart?._id} cart={cart} />
					))}
				</Stack>
			);
		}
	}
	const calculateSubTotal = cartData.reduce((acc, item) => acc + (item.quantity * (item.product.offerPrice || item.product.price)), 0);
	return (
		<>
			<Offcanvas
				id="cart-offcanvas"
				show={cartDrawer}
				onHide={() => dispatch(setCartDrawer(false))}
				placement={'end'}
				style={{ width: '500px' }}
			>
				<Offcanvas.Header>
					<Offcanvas.Title>Your Bag</Offcanvas.Title>
					<div className="close_button_hover me-2">
						<MdClose
							size={22}
							onClick={() => dispatch(setCartDrawer(false))}
						/>
					</div>
				</Offcanvas.Header>

				<Offcanvas.Body>{content}</Offcanvas.Body>

				{/* if discount available then show this message */}
				{
					allCartData?.data?.discount > 0 && <div className='d-flex justify-content-between align-items-center rounded p-2 m-2 bg-success text-white '>
						<span><AiOutlineCheckCircle size={20} />You have discount:</span> {allCartData?.data?.discount} </div>}

				{/* if coupon applied but total amount less than activate coupon */}
				{
					allCartData?.data?.activateCoupon && <div className='text-xs d-flex gap-2 align-items-center rounded p-2 m-2 bg-warning'>
						<AiOutlineCheckCircle size={28} /> <span>In order to receive the discount, kindly add more than <span className='fw-bold '>৳{allCartData?.data?.activateCoupon}</span> to your purchase.</span> </div>
				}

				{
					userEmail && <div style={{ padding: "12px" }} className=' pe-3'>
						<p style={{ cursor: "pointer", textAlign: "center" }} onClick={() => setShowCoupon(!showCoupon)}>Have a special code  ?</p>
						{showCoupon && <CheckCoupon cartId={allCartData?.data?.cartData?._id} />}
					</div>
				}
				{(allCartData?.data?.cartData?.products?.length > 0 || cartData?.length > 0) && (
					<Card.Footer>
						<Button
							onClick={() => dispatch(setCartDrawer(false))}
							as={Link}
							to="/checkout"
							className="shadow-none"
							style={{
								backgroundColor: '#7dcfb6',
								padding: '10px',
								margin: '0px 0px 10px 10px',
								fontSize: '18px',
								textAlign: 'center',
								fontWeight: 'bold',
								width: '95%',
								letterSpacing: '1px',
								border: 'none',
							}}
						>
							Place Order {allCartData?.data?.total || calculateSubTotal}৳
						</Button>
						<Button
							as={Link}
							to="/shop-all"
							className="shadow-none continue_shopping"
							onClick={() => dispatch(setCartDrawer(false))}
						>
							Continue Shopping <IoIosArrowForward />
						</Button>
					</Card.Footer>
				)}
			</Offcanvas >
		</>
	);
};
export default AddToCartOffcanvas;
