import React, { useState } from "react";
import { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAddSingleReviewMutation } from "../../../redux/review/reviewApi";
import Error from "../../Ui/error/Error";
import Success from "../../Ui/error/Success";

const WriteReview = ({ product }) => {
    const userEmail = useSelector((state) => state?.user?.user?.email);
    const [showComment, setShowComment] = useState(false);
    const [rating, setRating] = useState(5);
    const navigate = useNavigate();
    const commentRef = useRef();

    const [addSingleReview, { isLoading, error, isSuccess }] =
        useAddSingleReviewMutation();

    const handleSubmit = (e) => {
        e.preventDefault();
        const comment = commentRef.current.value;
        const data = {
            description: comment,
            product: product._id,
            rating: rating,
        };

        if (product && userEmail) {
            addSingleReview({ data, userEmail });
            commentRef.current.value = "";
        } else {
            navigate("/account/login");
        }
    };

    return (
        <div>
            {showComment && (
                <div className="border-top border-bottom py-5">
                    <div>
                        <p className="fw-bold">
                            What would you rate this product?
                        </p>
                        <div>
                            <ReactStars
                                count={5}
                                onChange={(newRating) => setRating(newRating)}
                                size={24}
                                activeColor="#ffd700"
                                value={5}
                            />
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} className="mt-4">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            {error && <Error>{error?.data?.message || "Review added failed"}</Error>}
                            {isSuccess && <Success>Review added successfully</Success>}
                            <Form.Label className="fw-bold">
                                Tell us your feedback about the product
                            </Form.Label>
                            <Form.Control
                                type="text"
                                as="textarea"
                                rows={5}
                                ref={commentRef}
                                placeholder="Enter your comment"
                                className="shadow-none rounded-0"
                                required
                            />
                        </Form.Group>

                        <div className="d-flex justify-content-end gap-2">
                            <Button
                                variant="outline-dark"
                                className="cancel-comment px-4 py-2 "
                                style={{ border: "1px solid black" }}
                                type="button"
                                onClick={() => setShowComment(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outline-dark"
                                className="cancel-comment px-4 py-2 rounded-0"
                                style={{ border: "1px solid black" }}
                                type="submit"
                            // onClick={() => setShowComment(false)}
                            >
                                {isLoading ? "Loading..." : "Post"}
                            </Button>
                        </div>
                    </form>
                </div>
            )}
            {!showComment && (
                <div className="d-flex align-items-center justify-content-between  pb-4 border-bottom">
                    <div>
                        <div className="d-flex align-items-center">
                            <h1 className="my-0 p-0">{product?.averageRating?.$numberDecimal}</h1>
                            <div className="ms-2 d-flex align-items-center justify-content-start">
                                <ReactStars
                                    count={5}
                                    edit={false}
                                    size={24}
                                    half={true}
                                    activeColor="#ffd700"
                                    value={product?.averageRating?.$numberDecimal}
                                />
                            </div>
                        </div>
                        <div>Based on {product?.numReviews} reviews</div>
                    </div>
                    <Button
                        variant="dark"
                        style={{ height: "2.75rem", borderRadius: "4px" }}
                        className="shadow-none"
                        onClick={() => setShowComment(true)}
                    >
                        Write Review
                    </Button>
                </div>
            )}
        </div>
    );
};

export default WriteReview;
