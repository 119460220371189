import "./MobileNavbar.scss";
const NavbarBrandSection = () => {

    return (
        <>
            <div
                className="first-heading text-center bg-danger text-white d-flex align-items-center justify-content-center"
                style={{
                    position: "sticky",
                    top: "0",
                    zIndex: "1020",
                    height: "30px",
                }}
            >
                <div className="my-auto">WELCOME TO THE KOREAN MALL</div>
            </div>
        </>
    );
};

export default NavbarBrandSection;
