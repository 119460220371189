import React, { useLayoutEffect, useState } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";
import UiNavbar from "../../Home/Navbar";
import ResponsiveMobileNavbar from "../../Home/ResponsiveMobileNavbar/ResponsiveMobileNavbar";

const Header = ({ setShow }) => {
    const size = useWindowSize();
    const [isSmallDevice, setSmallDevice] = useState();

    useLayoutEffect(() => {
        if (size.width < 992) {
            setSmallDevice(true);
        } else {
            setSmallDevice(false);
        }
    }, [size]);

    return (
        <>
            {isSmallDevice ? (
                // <ResponsiveNavbar setShow={setShow} />
                <ResponsiveMobileNavbar />
            ) : (
                <UiNavbar setShow={setShow} />
                // <HeaderNavbar />
            )}
        </>
    );
};

export default Header;
