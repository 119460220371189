import { apiSlice } from "../api/apiSlice";

export const pagesApi = apiSlice.injectEndpoints({
    tagTypes: ["WishListData"],
    endpoints: (builder) => ({
        //GET STORE LOCATION PAGE
        getStoreLocation: builder.query({
            query: () => ({
                url: `/storeLocation`,
                method: "POST",
            }),
        }),
        //GET DELIVERY INFORMATION PAGE
        getDeliveryInfo: builder.query({
            query: () => ({
                url: `/deliveryInfo`,
                method: "POST",
            }),
        }),
        //GET TERMS & CONDITIONS PAGE
        getTermsCondition: builder.query({
            query: () => ({
                url: `/termsCondition`,
                method: "POST",
            }),
        }),
        //GET DELIVERY INFORMATION PAGE
        getReturnPage: builder.query({
            query: () => ({
                url: `/return`,
                method: "POST",
            }),
        }),
        //GET DELIVERY INFORMATION PAGE
        getPrivacyPolicy: builder.query({
            query: () => ({
                url: `/privacy-policy`,
                method: "POST",
            }),
        }),
        //GET DELIVERY INFORMATION PAGE
        getAllBlogs: builder.query({
            query: () => ({
                url: `/blog`,
                method: "POST",
            }),
        }),
    }),
});

export const {
    useGetAllBlogsQuery,
    useGetPrivacyPolicyQuery,
    useGetTermsConditionQuery,
    useGetReturnPageQuery,
    useGetDeliveryInfoQuery,
    useGetStoreLocationQuery,
} = pagesApi;
