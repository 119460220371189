import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import sslcommerzLogo from "../../../assets/images/sslcommerz-logo.png";
import koreanMall from "../../../assets/images/thekoreanmall-logo-invert.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer id="footer" className="mt-5 border-top bg-dark">
      <Container className="mt-3">
        <Row md={3} xs={1}>
          <Col>
            <Image width={200} fluid src={koreanMall} />
            <ul className="list-unstyled">
              <li className="mt-3">
                <h6>Gulshan</h6>
                Shop # 238, 1st Floor, Police Plaza Concord Shopping Mall
                Hatirjheel, Gulshan 1
              </li>
              <li className="mt-3">
                <h6>Dhanmondi</h6>
                Shop # 1102, 1st Floor, Shimanto Shombhar, Dhanmondi (+88) 01756
                167724 mail@thekoreanmall.com
              </li>

              <div className="social-icons mt-3">
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaTwitter className="social-icon" />
                </a>
                <a
                  href="https://www.facebook.com/thekoreanmallbd/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaFacebookF className="social-icon ms-2" />
                </a>
                <a
                  href="https://www.instagram.com/thekoreanmallbd/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaInstagram className="social-icon ms-2" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC-LVy_dKbP657BCCOsPpYCQ"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaYoutube className="social-icon ms-2" />
                </a>
                <a
                  href="mailto:mail@thekoreanmall.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <MdOutlineEmail className="social-icon ms-2" />
                </a>
              </div>
            </ul>
          </Col>

          <Col>
            <h5 className="heading text-white">About Us</h5>
            <ul className="list-unstyled">
              <li className="mt-3">
                <Link
                  to="/about-us"
                  className="text-decoration-none footer-link"
                >
                  About Us
                </Link>
              </li>
              <li className="mt-3">
                <Link
                  to="/store-location"
                  className="text-decoration-none footer-link"
                >
                  Store Location
                </Link>
              </li>
              <li className="mt-3">
                <Link
                  to="/delivery-information"
                  className="text-decoration-none footer-link"
                >
                  Delivery information
                </Link>
              </li>
              <li className="mt-3">
                <Link
                  to="/privacy-policy"
                  className="text-decoration-none footer-link"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="mt-3">
                <Link
                  to="/terms-and-conditions"
                  className="text-decoration-none footer-link"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="mt-3">
                <Link
                  to="/contact-us"
                  className="text-decoration-none footer-link"
                >
                  Contact us
                </Link>
              </li>
              {/* <li className="mt-3">
                <Link
                  to="/sitemap"
                  className="text-decoration-none footer-link"
                >
                  Sitemap
                </Link>
              </li> */}
            </ul>
          </Col>

          <Col>
            <h5 className="heading text-white">Account</h5>
            <ul className="list-unstyled">
              <li className="mt-3">
                <Link
                  to="/account"
                  className="text-decoration-none footer-link"
                >
                  My account
                </Link>
              </li>
              <li className="mt-3">
                <Link
                  to="/account/orders"
                  className="text-decoration-none footer-link"
                >
                  Order History
                </Link>
              </li>
              <li className="mt-3">
                <Link
                  to="/account/wishlist"
                  className="text-decoration-none footer-link"
                >
                  My Wishlist
                </Link>
              </li>
              {/* <li className="mt-3">
								<Link
									to="/account/newsletter"
									className="text-decoration-none footer-link"
								>
									Newsletter
								</Link>
							</li> */}
              {/* <li className="mt-3">
								<Link
									to="/account/returns"
									className="text-decoration-none footer-link"
								>
									Returns
								</Link>
							</li> */}
              {/* <li className="mt-3">
								<Link to="/brands" className="text-decoration-none footer-link">
									Favorite Brands
								</Link>
							</li> */}
              {/* <li className="mt-3">
								<Link
									to="/product-compare"
									className="text-decoration-none footer-link"
								>
									Product Compare
								</Link>
							</li> */}
            </ul>
          </Col>
        </Row>

        {/* <div
          className="mt-5 pt-4 text-center border-top text-white"
          style={{ fontSize: "13.5px" }}
        >
          We are a renowned leading Korean cosmetics company in Bangladesh. we
          are reputedly dealing with various brands in this industry for 4
          years. and by the time we have our own outlets in two different
          locations in the Dhaka city area. With a vision on, we will expand
          more with our authentic Korean cosmetic products.
        </div> */}
        <p
          className="text-center mt-2 text-white"
          style={{ fontSize: "13.5px" }}
        >
          {" "}
          Copyright &copy; {new Date().getFullYear()} The Korean Mall. All
          Rights Reserved. Developed by{" "}
          <a
            href="https://www.bengalsoftware.com/"
            target="_blank"
            rel="noreferrer noopener"
            className="text-white text-decoration-none"
          >
            Bengal Software
          </a>
          .
        </p>

        <div className="text-center mt-2">
          <div>Pay Securely</div>
          <Image
            className="mt-3 mb-5"
            src={sslcommerzLogo}
            alt="sslcommerz logo"
          // width={"160px"}
          />
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
