import React from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import "./AboutUs.scss"
import { ImPhone } from 'react-icons/im';

import { BsChevronDoubleRight } from 'react-icons/bs';

const AboutUs = () => {
  return (
    <Container>

      <h1 className='fw-bold fs-1 text-center my-3 '>About Us</h1>
      <Row className='mt-5'>
        <Col md={6}>
          <div className='d-flex'>
            <div className='pe-4 '>
              <img className='img-fluid rounded' src="https://thepixelcurve.com/html/techwix/techwix/assets/images/about-3.jpg" alt="" />
            </div>
            <div style={{ marginTop: "5rem" }}>
              <img className='img-fluid rounded ' src="https://thepixelcurve.com/html/techwix/techwix/assets/images/about-4.jpg" alt="" />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <Row className='ps-4 pe-4'>
            <p className='py-1 fw-bold text-danger whom-text'>WHO WE ARE</p>
            <h1  className='mbl-tle lg-tle'>
              High Quality Cosmetics, Collection & Support Services.          </h1>
            <p className=' mission-text '>
            We are a renowned leading Korean cosmetics company in Bangladesh. we are reputedly dealing with various brands in this industry for 4 years. and by the time we have our own outlets in two different locations in the Dhaka city area. With a vision on, we will expand more with our authentic Korean cosmetic products. 
            </p>
            <hr className='mt-3' />
            <div className='d-flex'>
              <div className='p-2'>
                <h4 className='fw-bold'>Our Mission</h4>
                <p className="mission-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quam amet consequuntur natus .</p>
              </div>
              <div className='p-2'>
                <h4 className='fw-bold'>Our Vission</h4>
                <p className="mission-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quam amet consequuntur natus </p>
              </div>
            </div>
          </Row>
        </Col>
        <Col md={12} className='scoring-mbl' >
          < Col className='py-4  scoring rounded d-flex justify-content-center align-item-center'>

            <div className='p-5 '>
              <h1 style={{ fontSize: "3rem" }}>3546+</h1>
              <p>Happy Clients</p>
            </div>

            <div className='p-5  '>
              <h1 style={{ fontSize: "3rem" }}>1169+</h1>
              <p>Product Collection</p>
            </div>
            <div className='p-5 '>
              <h1 style={{ fontSize: "3rem" }}>99%</h1>
              <p>Customer Positive Reviews</p>
            </div>
            <div className='p-5 '>
              <h1 style={{ fontSize: "3rem" }}>3218+</h1>
              <p>Clients Supoort Done
              </p>
            </div>

          </Col>
          <Row>
            <div className='text-center'>
              <p className='py-3 fw-bold text-danger'>REASON TO CHOOSE US</p>
              <h1 style={{ fontSize: "2.5rem" }}>We provide truly prominent <br /> Cosmetics solutions.</h1>
            </div>
          </Row>
          <Col className='bg-light'>
            <Row className='d-flex justify-content-center pt-5'>
              <Col sm={6} md={4} lg={3}>
                <Card>
                  <Card.Img style={{ width: "2rem" }} className='img-fluid m-3' variant="top" src="https://thepixelcurve.com/html/techwix/techwix/assets/images/ser-icon9.png" />
                  <Card.Body className='p-3'>
                    <Card.Title className='fw-bold'>Qualityfull Cosmetics point</Card.Title>
                    <Card.Text>
                      Accelerate innovation with world-class tech teams We’ll match you to an entire.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <Card >
                  <Card.Img style={{ width: "2rem" }} className='img-fluid m-3' variant="top" src="https://thepixelcurve.com/html/techwix/techwix/assets/images/ser-icon9.png" />
                  <Card.Body className='p-3'>
                    <Card.Title className='fw-bold'>Qualityfull Cosmetics Point</Card.Title>
                    <Card.Text>
                      Accelerate innovation with world-class  teams We’ll match you to an entire.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <Card >
                  <Card.Img style={{ width: "2rem" }} className='img-fluid m-3' variant="top" src="https://thepixelcurve.com/html/techwix/techwix/assets/images/ser-icon9.png" />
                  <Card.Body className='p-3'>
                    <Card.Title className='fw-bold'>Qualityfull Cosmetics Point</Card.Title>
                    <Card.Text>
                      Accelerate innovation with world-class tech teams We’ll match you to an entire.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

            </Row>

            <Row className='d-flex justify-content-center py-5'>
              <Col sm={6} md={4} lg={3}>
                <Card >
                  <Card.Img style={{ width: "2rem" }} className='img-fluid m-3' variant="top" src="https://thepixelcurve.com/html/techwix/techwix/assets/images/ser-icon9.png" />
                  <Card.Body className='p-3'>
                    <Card.Title className='fw-bold'>Qualityfull Cosmetics Point</Card.Title>
                    <Card.Text>
                      Accelerate innovation with world-class teams We’ll match you to an entire.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <Card >
                  <Card.Img style={{ width: "2rem" }} className='img-fluid m-3' variant="top" src="https://thepixelcurve.com/html/techwix/techwix/assets/images/ser-icon9.png" />
                  <Card.Body className='p-3'>
                    <Card.Title className='fw-bold'>Qualityfull Cosmetics point</Card.Title>
                    <Card.Text>
                      Accelerate innovation with world-class teams We’ll match you to an entire.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <Card >
                  <Card.Img style={{ width: "2rem" }} className='img-fluid m-3' variant="top" src="https://thepixelcurve.com/html/techwix/techwix/assets/images/ser-icon9.png" />
                  <Card.Body className='p-3'>
                    <Card.Title className='fw-bold'>Qualityfull Cosmetics point</Card.Title>
                    <Card.Text>
                      Accelerate innovation with world-class teams We’ll match you to an entire.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

            </Row>
          </Col>
        </Col>
        <Col>
          <Row>
            <Col className='p-4' md={6}>
              <div>
                <h1 className='mbl-tle lg-tle'>Preparing for your success, we provide truly prominent  solutions</h1>
                <div className='mb-4 text-mbl'>
                  <ul className='fw-bold   '> <BsChevronDoubleRight /> We always focus on our customer reviews</ul>
                  <ul className='fw-bold '><BsChevronDoubleRight />  We always focus on our customer reviews</ul>
                  <ul className='fw-bold '> <BsChevronDoubleRight />We always focus on our customer reviews</ul>
                </div>
              </div>
              <hr />
              <div className='d-flex justify-content-between ms-1'>
                <div className='address-mbl'>
                  <h5 className='address-mbl fw-bold'>Demo Name</h5>
                  <p className='address-mbl'>CEO,The Koreanmall</p>
                </div>
                <div>
                  <h5 className='address-mbl fw-bold'>Call to ask any question</h5>
                  <p className='address-mbl'>< ImPhone style={{ fontSize: ".9rem", marginRight: ".2rem", marginBottom: ".2rem"}} />0945XXXXXXX</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='p-3'>
                <h5 className='fw-bold pb-2'>Want to know about our company?</h5>
                <p className='mission-text' >
                We are a renowned leading Korean cosmetics company in Bangladesh. we are reputedly dealing with various brands in this industry for 4 years. and by the time we have our own outlets in two different locations in the Dhaka city area. With a vision on, we will expand more with our authentic Korean cosmetic products. 
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>

  );
};

export default AboutUs;
