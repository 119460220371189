import React from "react";

import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const PrivateRoute = ({ children }) => {
  const isLoggedIn = useAuth();

  const location = useLocation();
  // if (loading) {
  //   return <Spinner animation="border" variant="primary"></Spinner>;
  // }
  if (!isLoggedIn) {
    return (
      <Navigate
        to="/account/login"
        state={{ from: location }}
        replace
      ></Navigate>
    );
  }
  return children;
};

export default PrivateRoute;
