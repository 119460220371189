import React from 'react';

export default function AddressSVG() {
	return (
		<svg
			className="text-lime-950 cursor-pointer align-middle text-truncate"
			fill="none"
			stroke="currentColor"
			width={20}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			style={{
				stroke: 'rgb(32, 34, 2)',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				width: '100%',
				height: '100%',
			}}
			viewBox="0 0 24 24"
		>
			<path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
			<circle cx="12" cy="10" r="3" />
		</svg>
	);
}
