import React from "react";
import { Image, Stack } from "react-bootstrap";
import { MdAdd, MdRemove } from "react-icons/md";
import { useSelector } from "react-redux";
import SmallLoader from "../../components/Ui/loading/SmallLoader";
import {
    useAddToCartNewDataByEmailMutation,
    useDecreaseQuantityMutation,
} from "../../redux/addToCart/addToCartApi";
import { calculateDiscount } from "../../config/calculateDiscount";

const SingleProductCard = ({ cart }) => {
    const userEmail = useSelector((state) => state?.user?.user?.email);

    const [
        addToCartNewDataByEmail,
        { isLoading: increaseLoading, },
    ] = useAddToCartNewDataByEmailMutation();
    const [
        decreaseQuantity,
        { isLoading: decreaseLoading },
    ] = useDecreaseQuantityMutation();

    // get single product price after discount
    const priceAfterDiscount = calculateDiscount(cart?.product);

    // handle increase quantity
    const quantityIncrease = (id) => {
        const data = {
            product: id,
        };
        addToCartNewDataByEmail({ userEmail, data });
    };

    // handle decrease of quantity
    const quantityDecrease = (quantity) => {
        const data = {
            product: cart.product._id,
            minusQty: quantity,
        };
        decreaseQuantity({ userEmail, data });
    };
    const { _id, name, images, price } = cart?.product || {};

    return (
        <Stack direction="horizontal" gap={3} className="pb-3 ">
            <div style={{ width: "100px" }}>
                <Image
                    key={cart?._id}
                    src={images[0]}
                    style={{ width: "100px", height: "100px" }}
                />
            </div>
            <div className="w-100">
                <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                    {name}
                </div>
                <div style={{ fontSize: "14px" }}>Size: 30ml</div>
                <div style={{ fontSize: "14px", marginTop: "5px" }}>
                    {" "}
                    {priceAfterDiscount < price ? (
                        <span>
                            ৳ {priceAfterDiscount}
                            <br />
                            <strike className="text-secondary">
                                ৳ {price}
                            </strike>
                        </span>
                    ) : (
                        <span>৳ {price}</span>
                    )}
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-between">
                    <div
                        className="d-flex align-items-center justify-content-between border"
                        style={{ minWidth: "104px" }}
                    >
                        <button
                            onClick={() => quantityDecrease(cart.quantity)}
                            disabled={cart?.quantity <= 1}
                            style={{
                                cursor: `${cart?.quantity <= 1
                                    ? "not-allowed"
                                    : "pointer"
                                    }`,
                            }}
                            cursor={"pointer"}
                            className="px-2 py-1 d-flex align-items-center justify-content-center border-0 btn-decrease"
                        >
                            {decreaseLoading ? (
                                <SmallLoader />
                            ) : (
                                <MdRemove className="h6 m-0" />
                            )}
                        </button>
                        {cart.quantity}
                        <button
                            onClick={() => quantityIncrease(_id)}
                            cursor={"pointer"}
                            className="px-2 py-1 d-flex align-items-center justify-content-center border-0 btn-increase"
                        >
                            {increaseLoading ? (
                                <SmallLoader />
                            ) : (
                                <MdAdd className="h6 m-0" />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </Stack >
    );
};

export default SingleProductCard;
