import React from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetAllReviewsByProductIdQuery } from "../../../redux/review/reviewApi";
import SingleReview from "./SingleReview";
import SortingSection from "./SortingSection";
import WriteReview from "./WriteReview";

const ProductReviews = ({ product }) => {
    const { id } = useParams(); // its product slug
    const {
        data: reviews,
        isLoading,
    } = useGetAllReviewsByProductIdQuery(id);
    return (
        <div>
            <WriteReview product={product} />
            <SortingSection />
            {reviews?.length === 0 && (
                <h3 className="text-center">No Reviews Added</h3>
            )}
            {isLoading ? (
                <Spinner animation="border" variant="primary" />
            ) : (
                reviews?.map((review) => (
                    <SingleReview key={review?._id} review={review} />
                ))
            )}
        </div>
    );
};

export default ProductReviews;
