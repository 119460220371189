import React from "react";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Accordion, Col, Form } from "react-bootstrap";
import { setUrlParams } from "../../config/generateUrlParam";
import { AiFillCaretRight } from "react-icons/ai";
import { useRef } from "react";
import "./SearchingProducts.scss";
import {
    useGetAllCategoryQuery,
    useGetShopByCategoryQuery,
} from "../../redux/categories/categoriesApi";

const sizeArray = [
    { name: "30 ml", _id: 1 },
    { name: "60 ml", _id: 2 },
    { name: "90 ml", _id: 3 },
    { name: "100 ml", _id: 4 },
    { name: "500 ml", _id: 5 },
    { name: "1000 ml", _id: 6 },
];
const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const MobileFilterSection = ({ show, setShow, handleShow }) => {
    const handleClose = () => setShow(false);

    const minPriceRef = useRef();
    const maxPriceRef = useRef();
    const searchUrl = useLocation().search || "?";
    const [selectWeight, setSelectedWeight] = useState("");
    const navigate = useNavigate();

    // hooks
    const { data: allCategory, isLoading, isError } = useGetAllCategoryQuery();
    const { data: shopBy, isLoading: shopByLoading } =
        useGetShopByCategoryQuery();

    const handleUncheckAll = () => {
        setSelectedWeight("");
        minPriceRef.current.value = "";
        maxPriceRef.current.value = "";

        navigate("/products");
        goToTop();
    };

    const handlePriceSearch = (e) => {
        e.preventDefault();
        const string = `${minPriceRef.current.value}-${maxPriceRef.current.value}`;
        const url = setUrlParams(searchUrl, "price", string);
        navigate(url);
        goToTop();
    };

    return (
        <>
            <Offcanvas
                style={{ width: "70%" }}
                show={show}
                onHide={handleClose}
                placement="end"
            >
                <Offcanvas.Header closeButton className="mb-0 pb-0 pt-3">
                    <Offcanvas.Title >Search</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="mt-1 pt-1" >
                    <Col xs={12} sm={12} md={2} className="my-2">
                        <div className="d-flex gap-2 align-items-center mb-2 fw-bold justify-content-between">
                            <p>Filter Section :</p>
                            <button
                                className="btn btn-sm  text-primary "
                                onClick={handleUncheckAll}
                            >
                                <p className="fs-6 fw-bold">Clear All</p>
                            </button>
                        </div>
                        <hr />

                        {/* Category Filter */}
                        <div>
                            <Accordion className="">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header
                                        className="shadow-none"
                                        style={{ lineHeight: "0px" }}
                                    >
                                        All Categories
                                    </Accordion.Header>
                                    <Accordion.Body className="mt-0 pt-0">
                                        {allCategory?.result?.map(
                                            (category) => (
                                                <p
                                                    key={category?._id}
                                                    onClick={() => {
                                                        navigate(
                                                            setUrlParams(
                                                                searchUrl,
                                                                "category",
                                                                category._id
                                                            )
                                                        );
                                                        goToTop();
                                                    }}
                                                    className={
                                                        searchUrl.includes(
                                                            category?._id
                                                        )
                                                            ? "text-danger fw-bold"
                                                            : "filter-category"
                                                    }
                                                >
                                                    <span className="filter-font ">{category?.title}</span>                                                </p>
                                            )
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                        {/* shop by Filter */}
                        <div>
                            {shopBy?.data?.children?.map(
                                (subCategory, index) => (
                                    <Accordion key={subCategory?._id}>
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header
                                                className="shadow-none"
                                                style={{ lineHeight: "0px" }}
                                            >
                                                {subCategory?.title}
                                            </Accordion.Header>
                                            <Accordion.Body className="mt-0 pt-0">
                                                {subCategory?.children?.map(
                                                    (child) => (
                                                        <p
                                                            key={child?._id}
                                                            onClick={() => {
                                                                navigate(
                                                                    setUrlParams(
                                                                        searchUrl,
                                                                        "category",
                                                                        child._id
                                                                    )
                                                                );
                                                                goToTop();
                                                            }}
                                                            className={
                                                                searchUrl.includes(
                                                                    child?._id
                                                                )
                                                                    ? "text-danger fw-bold"
                                                                    : "filter-category"
                                                            }
                                                        >

                                                            <span className="filter-font">{child?.title}</span>
                                                        </p>
                                                    )
                                                )}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                )
                            )}
                        </div>

                        {/* Weight filter */}
                        <div>
                            <Accordion className="">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header
                                        className="shadow-none"
                                        style={{ lineHeight: "0px" }}
                                    >
                                        Weight
                                    </Accordion.Header>
                                    <Accordion.Body className="mt-0 pt-0">
                                        {sizeArray.map((size) => (
                                            <Form.Check
                                                key={size?._id}
                                                className="filter-font"
                                                label={size?.name}
                                                name="group1"
                                                type={"radio"}
                                                checked={
                                                    selectWeight === size?.name
                                                }
                                                onClick={() => {
                                                    setSelectedWeight(
                                                        size.name
                                                    );
                                                    navigate(
                                                        setUrlParams(
                                                            searchUrl,
                                                            "weight",
                                                            size?.name
                                                        )
                                                    );
                                                    goToTop();
                                                }}
                                                id={size?.name}
                                                value={size?.name}
                                            />
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                        {/* Price filter */}
                        <div>
                            <p className="my-2 fw-bold">Price:</p>
                            <Form onSubmit={handlePriceSearch}>
                                <div className="d-flex gap-1">
                                    <Form.Control
                                        placeholder="min"
                                        className="priceInputField"
                                        type="number"
                                        ref={minPriceRef}
                                    />
                                    <Form.Control
                                        placeholder="max"
                                        className="priceInputField"
                                        type="number"
                                        ref={maxPriceRef}
                                    />
                                    <button
                                        type="submit"
                                        style={{ width: "2rem" }}
                                        className="border-0 bg-primary text-white d-flex align-items-center justify-content-center"
                                    >
                                        <AiFillCaretRight />
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default MobileFilterSection;
