import React from 'react';
import { Container } from 'react-bootstrap';
import Heading from '../../../components/Ui/Heading';
import { useGetTermsConditionQuery } from '../../../redux/pages/pagesApi';

const TermsAndConditions = () => {
	const { data, isLoading } = useGetTermsConditionQuery()
	return (
		<Container>
			<Heading>Terms & Conditions</Heading>
			{data && (
				<div
					dangerouslySetInnerHTML={{
						__html: data?.data?.[0]?.description,
					}}
				/>
			)}

		</Container>
	);
};

export default TermsAndConditions;
