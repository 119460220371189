import React from "react";
import { Modal, Container, Row } from "react-bootstrap";
import "./billingModal.scss";
import { BsPlusSquareDotted } from "react-icons/bs";
import "./addressModal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  showAddressModal,
  showBillingModal,
} from "../../../redux/modal/modalSlice";
import {
  useGetAllAddressQuery,
} from "../../../redux/address/addressApi";
import SingleAddress from "./SingleAddress";

const BookingModal = () => {
  const { addressModal } = useSelector((state) => state.modal);
  const { email } = useSelector((state) => state.user.user) || {};
  const dispatch = useDispatch();
  const closeModal = () => dispatch(showAddressModal(false));
  const {
    data: allAddress,
    isLoading,
    isError,
  } = useGetAllAddressQuery(email, { skip: !email });

  let content = null;
  if (isLoading) {
    content = <p>Address loading...</p>;
  }
  if (!isLoading && !isError && allAddress?.length === 0) {
    content = <p>Address list empty</p>;
  }
  if (!isLoading && !isError && allAddress?.length > 0) {
    content = allAddress?.map((address) => (
      <SingleAddress key={address?._id} address={address} />
    ));
  }
  return (
    <>
      <Modal
        className="d-flex  align-items-center"
        show={addressModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <h5 className="fw-semibold ms-2">Delivery Address</h5>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {/* content */}
            <Row>{content}</Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between text-end">
          {/* new address button */}
          <button
            className="border-0 bg-white text-dark fw-semibold "
            onClick={() => dispatch(showBillingModal({ active: true, id: "" }))}
            style={{ fontSize: ".9rem" }}
          >
            <BsPlusSquareDotted className="me-1 mb-1" />
            Create Address
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BookingModal;
