import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Heading from '../../../components/Ui/Heading';

const StoreLocation = () => {
	return (
		<Container>
			<Heading>Our Outlet</Heading>

			<Row>
				<Col>
					<h2 className="h5 fw-bold text-uppercase">Gulshan Outlet</h2>
					<div className="mt-3 mb-2">
						Shop # 238, 1st Floor, Police Plaza Concord Shopping Mall, <br />
						Hatirjheel, Gulshan 1
					</div>
					<a
						href="tel:+8801756167724"
						className="text-decoration-none text-dark"
					>
						+88 01756167724
					</a>
				</Col>
				<Col>
					<h2 className="h5 fw-bold text-uppercase">Dhanmondi Outlet</h2>
					<div className="mt-3 mb-2">
						1102, 1st Floor, Shimanto Shombhar Shopping Mall, <br /> Dhanmondi,
						Dhaka, Bangladesh 1212
					</div>
					<a
						href="tel:+8801756167724"
						className="text-decoration-none text-dark"
					>
						+88 01756167724
					</a>
				</Col>
			</Row>
		</Container>
	);
};

export default StoreLocation;
