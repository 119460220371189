import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';
import avatar from '../../src/assets/avatar.png';
import AccountSVG from '../assets/svg/Account';
import AddressSVG from '../assets/svg/Address';
import OrderSVG from '../assets/svg/Order';
import ReviewSVG from '../assets/svg/Review';
import WishlistSVG from '../assets/svg/Wishlist';
import Footer from '../components/Partials/Footer';
import Header from '../components/Partials/Header';
import { useGetLoggedInUserInfoByEmailQuery } from '../redux/account/accountApi';
import './AccountLayout.scss';
import CancelSVG from '../assets/svg/CancelSVG';
import ReturnSVG from '../assets/svg/ReturnSVG';
import ProfileSVG from '../assets/svg/ProfileSVG';

const AccountLayout = () => {
	const location = useLocation();
	const path = location.pathname.split("/account/")
	const [selectClass, setSelectClass] = useState(path?.[1] || "account");
	const user = useSelector((state) => state?.user?.user);
	const { email } = user || {};
	const { data: userData } = useGetLoggedInUserInfoByEmailQuery(
		email,
	);

	const { photoUrl, name } = userData?.data || {}

	return (
		<>
			<Header />
			<Container className="mb-5">
				<Row className="mt-2">
					<Col md={3} className="border-end">
						<div className="text-center ">

							<div className="avatar mr-10 user__account_img">
								<img
									src={photoUrl ? `${photoUrl}` : avatar}
									alt="user"
									className="avatar-img rounded-circle"
									style={{
										width: '160px',
										height: '160px',
										objectFit: 'cover',
									}}
								/>
							</div>

							<div className="frcp-nav__title">
								<div className="frcp-nav__name">
									<div className="frcp-nav__first-name">
										{name}
									</div>
								</div>
								<div>{email}</div>
							</div>
							<div className="doted-container mt-3 mb-2"></div>
						</div>


						<div className="col-md-3 pt-0 w-100 outlet__button-parent">

							<Link
								onClick={() => setSelectClass('account')}
								to="/account"
								className={`link__outlet ${selectClass === 'account' && 'selected_class'
									}`}
							>
								<span
									className='link__account me-2'
								>
									<AccountSVG />
								</span>
								My Account
							</Link>

							<Link

								to="/account/orders"
								onClick={() => setSelectClass('orders')}
								className={`link__outlet ${selectClass === 'orders' && 'selected_class'
									}`}
							>
								<span
									className='link__account'
								>
									<OrderSVG />
								</span>{' '}
								My Orders
							</Link>
							<Link

								to="/account/returned"
								onClick={() => setSelectClass('returned')}
								className={`link__outlet ${selectClass === 'returned' && 'selected_class'
									}`}
							>
								<span
									className='link__account'
								>
									<ReturnSVG />
								</span>{' '}
								My Returned
							</Link>
							<Link

								to="/account/cancelled"
								onClick={() => setSelectClass('cancelled')}
								className={`link__outlet ${selectClass === 'cancelled' && 'selected_class'
									}`}
							>
								<span
									className='link__account me-2'
								>
									<CancelSVG />

								</span>
								My cancelled
							</Link>

							<Link

								to="/account/reviews"
								onClick={() => setSelectClass('reviews')}
								className={`link__outlet ${selectClass === 'reviews' && 'selected_class'
									}`}
							>
								<span
									className='link__account'
								>
									<ReviewSVG />
								</span>{' '}
								View Reviews
							</Link>
							<Link

								to="/account/wishlist"
								onClick={() => setSelectClass('wishList')}
								className={`link__outlet  ${selectClass === 'wishList' && 'selected_class'
									}`}
							>
								<span
									className='link__account'
								>
									<WishlistSVG />
								</span>{' '}
								My Wishlist
							</Link>

							<Link
								onClick={() => setSelectClass('address')}
								to={`/account/addresses`}
								className={`link__outlet ${selectClass === 'address' && 'selected_class'
									}`}
							>
								<span
									className='link__account'
								>
									<AddressSVG />
								</span>{' '}
								View Addresses
							</Link>
							<Link
								onClick={() => setSelectClass('account')}
								to="/account"
								className={`link__outlet`}
							>
								<span
									className='link__account me-2'
								>
									<ProfileSVG />
								</span>
								Update Profile
							</Link>
						</div>
					</Col>
					<Col md={9}>
						<Container>
							<Outlet />
						</Container>
					</Col>
				</Row>
			</Container>
			<Footer />
		</>
	);
};

export default AccountLayout;
