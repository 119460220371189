import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import NoData from "../../../components/Ui/NoData";
import { useGetWishListDataByEmailQuery } from "../../../redux/wishList/wishListApi";
import SingleWishList from "./SingleWishList";

const MyWishlist = () => {
  const userEmail = useSelector((state) => state?.user?.user?.email);
  const [haveUser, setHaveUser] = useState(true);

  const {
    data: wishListData,
    isLoading,
    isError,
  } = useGetWishListDataByEmailQuery(userEmail, { skip: haveUser });

  useMemo(() => {
    if (userEmail) {
      setHaveUser(false);
    }
  }, [userEmail]);

  let content = null;
  if (isLoading) {
    content = <Skeleton count={3} />;
  }
  if (!isLoading && isError) {
    content = <p className="text-center text-danger">There was en error</p>;
  }
  if (!isLoading && !isError && !wishListData?.products?.length) {
    content = <NoData />;
  }
  if (!isLoading && !isError && wishListData?.products?.length > 0) {
    const reverseOrders = [...wishListData?.products]?.reverse();

    content = reverseOrders?.map((wishList) => (
      <SingleWishList wishList={wishList?.product} userEmail={userEmail} />
    ));
  }

  return (
    <>
      <div>
        <h2 className="text-start">My Wishlist</h2>
        <div className="doted-container"></div>
      </div>
      {content}
    </>
  );
};

export default MyWishlist;
