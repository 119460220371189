import { createSlice } from '@reduxjs/toolkit';
import { getDb, updateCartDb } from '../../utils/localDb';

const initialState = {
	cartData: [],
};

export const addToCartSlice = createSlice({
	name: 'addToCart',
	initialState,
	reducers: {
		addToCart: (state, action) => {
			const { product, qty, color, size } = action.payload;

			const cartExist = getDb('shopping_cart');
			let shopping_cart = [];
			if (!cartExist) {
				shopping_cart.push({
					product: {
						_id: product._id,
						name: product.name,
						images: product.images,
						price: product.price,
						offerPrice: product.offerPrice,
						// color: color,
						size: product?.size?.[0]?.name,
					},
					quantity: qty,
				});
			} else {
				shopping_cart = JSON.parse(cartExist);

				const index = shopping_cart.findIndex(
					(item) => item.product._id === product._id
				);
				if (index != -1) {
					shopping_cart[index].quantity += qty;
				} else
					shopping_cart.push({
						product: {
							_id: product._id,
							name: product.name,
							images: product.images,
							price: product.price,
							offerPrice: product.offerPrice,
							size: product?.size?.[0]?.name,

						},
						quantity: qty,
					});
			}
			state.cartData = shopping_cart;
			updateCartDb(shopping_cart);
		},
		removeFromCart: (state, action) => {
			const id = action.payload;
			const cartExist = getDb('shopping_cart');
			if (!cartExist) {
			} else {
				const shopping_cart = JSON.parse(cartExist);
				const exist = shopping_cart.filter((item) => item.product._id !== id);
				state.cartData = exist;
				updateCartDb(exist);
			}
		},
		setInitialState: (state, action) => {
			state.cartData = action.payload;
		},
		deleteCart: (state, action) => {
			state.cartData = state.cartData.filter(
				(cart) => cart._id !== action.payload
			);
		},
		quantityIncrease: (state, action) => {
			const { id, add } = action.payload;

			const cartExist = getDb('shopping_cart');
			let shopping_cart = JSON.parse(cartExist);

			const index = shopping_cart.findIndex((item) => item.product._id === id);
			if (index != -1) {
				if (add) {
					shopping_cart[index].quantity += 1;
				} else {
					shopping_cart[index].quantity -= 1;
				}
			}
			state.cartData = shopping_cart;
			updateCartDb(shopping_cart);
		},
		removeAllFromCart: (state) => {
			const cartExist = getDb('shopping_cart');
			if (!cartExist) {
			} else {
				state.cartData = [];
				localStorage.removeItem('shopping_cart');
			}
		},
	},
});

export const {
	addToCart,
	deleteCart,
	setInitialState,
	removeAllFromCart,
	quantityIncrease,
	removeFromCart,
} = addToCartSlice.actions;
export default addToCartSlice.reducer;
