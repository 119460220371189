import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useState } from "react";
import SecondLabelDropdown from "./SecondLabelDropdown";

const SingleNavDropdown = ({ category, handleToggle }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const renderDropdownTitle = (title) => (
        <div className="d-flex justify-content-between align-items-center">
            <div
                style={{ display: "block" }}
                className=" d-flex align-items-center justify-content-between "
            >
                {title}
            </div>
            {!dropdownOpen ? <AiOutlinePlus /> : <AiOutlineMinus />}
        </div>
    );

    const handleDropdownToggle = (isOpen) => {
        setDropdownOpen(isOpen);
    };

    return (
        <>
            {category?.children?.length > 0 ? (
                <NavDropdown
                    title={renderDropdownTitle(category?.title)}
                    id={category?._id}
                    renderMenuOnMount
                    onToggle={handleDropdownToggle}
                >
                    {category?.title?.toLowerCase() === "brands"
                        ?
                        <NavDropdown.Item
                            // key={brand?._id}
                            as={Link}
                            onClick={handleToggle}
                            to={`product/brands`}
                            style={{
                                marginLeft: "1.6rem",
                                borderBottom: "0px",
                            }}
                        >
                            All Brands (A-Z)
                        </NavDropdown.Item>
                        // second stage subCategory Children looks {title: "", children: []}
                        : category?.children?.map((subCategory) => (
                            <SecondLabelDropdown
                                key={subCategory?._id}
                                subCategory={subCategory}
                                handleToggle={handleToggle}
                            />
                        ))}
                </NavDropdown>
            ) : (
                <NavDropdown.Item
                    style={{
                        borderBottom: "1px solid #333",
                        marginLeft: "0.8rem",
                    }}
                    as={Link}
                    to={`products?category=${category.slug}`}
                    onClick={handleToggle}
                >
                    {category?.title}
                </NavDropdown.Item>
            )}
        </>
    );
};

export default SingleNavDropdown;
