import React from 'react';

const UnderLine = () => {
    return (
        <svg width="137" height="7" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#EC138D" stroke-width="2" fill="none" fill-rule="evenodd">
                <path d="M0 1.06594c4.03712 0 4.03712 3.9566 8.07423 3.9566 4.03706 0 4.03706-3.9566 8.07404-3.9566 4.03719 0 4.03719 3.9566 8.07437 3.9566 4.03712 0 4.03712-3.9566 8.07423-3.9566 4.03719 0 4.03719 3.9566 8.07437 3.9566 4.03725 0 4.03725-3.9566 8.07443-3.9566 4.03719 0 4.03719 3.9566 8.07444 3.9566 4.03731 0 4.03731-3.9566 8.0747-3.9566 4.03737 0 4.03737 3.9566 8.07476 3.9566"></path>
                <path d="M64.33043 1.06594c4.03712 0 4.03712 3.9566 8.07424 3.9566 4.03705 0 4.03705-3.9566 8.07404-3.9566 4.03718 0 4.03718 3.9566 8.07436 3.9566 4.03712 0 4.03712-3.9566 8.07424-3.9566 4.03718 0 4.03718 3.9566 8.07437 3.9566 4.03724 0 4.03724-3.9566 8.07443-3.9566 4.03718 0 4.03718 3.9566 8.07443 3.9566 4.03732 0 4.03732-3.9566 8.0747-3.9566s4.03738 3.9566 8.07476 3.9566"></path>
            </g>
        </svg>
    );
};

export default UnderLine;