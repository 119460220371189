import React, { useState } from "react";
import { useEffect } from "react";
import { Image } from "react-bootstrap";

const ImageGallery = ({ singleProductData, isLoading, isError }) => {
    const [image, setImage] = useState("");
    useEffect(() => {
        if (singleProductData) {
            setImage(singleProductData?.images?.[0]);
        }
    }, [singleProductData]);

    return (
        <div
            className="d-flex flex-md-row flex-column-reverse"

        >
            <div className="d-flex flex-md-column flex-row justify-content-md-start justify-content-center me-md-4 mt-md-0 mt-3">

                {singleProductData?.images?.length > 0 &&
                    singleProductData?.images?.map((img, _idx) => (
                        <div
                            key={_idx}
                            style={{
                                height: "3.75rem",
                                width: "3.75rem",
                                // marginBottom: "5px",
                                cursor: "pointer",
                            }}
                            className={`${image === img &&
                                "border border-dark border-3 mt-2"
                                }`}
                        >
                            {isLoading && <p>Loading..</p>}
                            <Image
                                src={img}
                                alt="product image"
                                onClick={() => setImage(img)}
                                fluid
                            />
                        </div>
                    ))}
            </div>
            <div className="w-100">
                {isLoading && <p>Loading..</p>}
                {isError && (
                    <p className="text-danger mt-4 fs-3">
                        There is something wrong
                    </p>
                )}
                <Image
                    style={{ objectFit: "cover", width: "100%" }}
                    src={image}
                    alt="product images"
                    fluid
                />
            </div>
        </div>
    );
};

export default ImageGallery;
