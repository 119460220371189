import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
// import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import auth from "../../init.firebase";
import "../../forms/LoginForm/LoginForm.scss";
import { MdArrowForward } from "react-icons/md";

const ForgetPassword = () => {
  // const [sendPasswordResetEmail, sending, error, success] =
  //   useSendPasswordResetEmail(auth);

  let sendingError = "";

  // get react hook form credentials
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // handle reset by onClick
  const onSubmit = async (data) => {
    const { email } = data || {};
    // await sendPasswordResetEmail(email);
    toast.success("An Email is Sent check your mail or spam.");
    reset();
  };

  // if (error) {
  //   sendingError = error?.message;
  // }

  return (
    <>
      <div
        style={{ height: "50vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Form onSubmit={handleSubmit(onSubmit)} className="form_control">
          <Form.Group className="" controlId="email">
            <h2 className="text-center ">Forget your password?</h2>
            <Form.Label>Your Email</Form.Label>
            <Form.Control
              type="email"
              className="rounded-0 shadow-none"
              {...register("email", {
                required: {
                  value: true,
                  message: "Email is Required",
                },
                pattern: {
                  value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                  message: "Provide a valid email",
                },
              })}
            />

            <label className="label my-2">
              {errors.email?.type === "required" && (
                <p className="text-danger">{errors.email.message}</p>
              )}
              {errors.email?.type === "pattern" && (
                <p className="text-danger">{errors.email.message}</p>
              )}
            </label>
          </Form.Group>

          <Button
            // disabled={sending}
            variant="dark"
            type="submit"
            className="w-100 rounded-0 w-100"
          >
            {/* {sending ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              "Send Email"
            )} */}
            Send Email
          </Button>
          {sendingError && (
            <p className="text-danger fw-bold">{sendingError}</p>
          )}
          <Link
            to="/account/login"
            className="mt-2 d-flex justify-content-end text-decoration-none align-items-center gap-2"
          >
            Return Login page <MdArrowForward />
          </Link>
        </Form>
      </div>
    </>
  );
};

export default ForgetPassword;
