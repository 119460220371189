import React, { useState } from "react";
import theKoreanMallLogo from "../../../assets/images/thekoreanmall-logo-invert.png";
import { Spin as Hamburger } from "hamburger-react";

import { Nav, NavDropdown, Navbar } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  setCartDrawer,
} from "../../../redux/modal/modalSlice";
import "./MobileNavbar.scss";
import { Link } from "react-router-dom";
import { CgSearch } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineShoppingBag } from "react-icons/md";
import { useGetAllCategoryQuery } from "../../../redux/categories/categoriesApi";
import SingleNavDropdown from "./SingleNavDropdown";
import { userLoggedOut } from "../../../redux/auth/authSlice";
import SuggestionBar from "../../Ui/SuggestionBar/SuggestionBar";

const CategoryModal = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((prv) => !prv);
  };

  const { data: allCategories } = useGetAllCategoryQuery();


  //handle log out
  const handleLogout = async () => {
    localStorage.clear();
    dispatch(userLoggedOut());
    await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`);
  };
  //handle search
  const [showInput, setShowInput] = useState(false);

  const handleButtonClick = () => {
    setShowInput((prevState) => !prevState);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  return (
    <>
      <Navbar
        bg="dark"
        expand="lg"
        // collapseOnSelect
        expanded={expanded}
        // onSelect={handleSelect}
        className="p-0"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
          <Hamburger toggled={expanded} color="white" size={22} />
        </Navbar.Toggle>
        {/* image */}
        <Navbar.Brand as={Link} to="/" onClick={() => clearSearchQuery()}>
          <img className="" width={170} src={theKoreanMallLogo} alt="" />
        </Navbar.Brand>
        <Navbar.Brand>
          {showInput ? (
            <AiOutlineClose
              onClick={handleButtonClick}
              style={{
                width: "25px",
                height: "22px",
                marginTop: "4px",
                marginRight: "10px",
                color: "white",
              }}
            />
          ) : (
            <CgSearch
              onClick={handleButtonClick}
              style={{
                width: "25px",
                height: "22px",
                marginTop: "4px",
                marginRight: "10px",
                color: "white",
              }}
            />
          )}

          <div
            className="add_to_cart_icon"
            onClick={() => dispatch(setCartDrawer(true))}
          >
            <MdOutlineShoppingBag
              style={{
                width: "25px",
                height: "22px",
                marginRight: "6px",
                color: "white",
              }}
            />
            <div className="add_to_cart_number">
              <span>{0}</span>
            </div>
          </div>
        </Navbar.Brand>{" "}
        {showInput && (
          <div
            className="d-flex"
            style={{
              marginLeft: "auto",
              width: "100%",
              marginRight: "auto",
              marginTop: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SuggestionBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              searchBarWidth={220}
              searchIconColor={true}
            ></SuggestionBar>
          </div>
        )}

        {/* menu bar start */}
        {
          <Navbar.Collapse id="basic-navbar-nav" style={{ borderBottom: "1px solid white" }}>
            <Nav className="mr-auto">
              {/* categories mapping  */}
              {allCategories?.result?.map((category) => (
                <SingleNavDropdown
                  key={category?._id}
                  category={category}
                  handleToggle={handleToggle}
                />
              ))}
              <NavDropdown.Item
                style={{
                  borderBottom: "1px solid #333",
                  marginLeft: "0.8rem",
                }}
                as={Link}
                onClick={handleToggle}
                to={`/account`}
              >
                My account
              </NavDropdown.Item>

              {user ? <NavDropdown.Item
                style={{
                  borderBottom: "1px solid #333",
                  marginLeft: "0.8rem",
                }}
                onClick={() => {
                  handleLogout();
                  handleToggle();
                }}
              >
                Logout
              </NavDropdown.Item> :
                <NavDropdown.Item
                  as={Link}
                  to="/account/login"
                  style={{
                    borderBottom: "1px solid #333",
                    marginLeft: "0.8rem",
                  }}
                  onClick={() => {
                    handleToggle();
                  }}
                >
                  Login
                </NavDropdown.Item>}
            </Nav>
          </Navbar.Collapse>
        }
      </Navbar>
    </>
  );
};

export default CategoryModal;
