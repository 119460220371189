import React from "react";
import { useState } from "react";
import {
    Button,
    Col,
    Container,
    Image,
    Pagination,
    Row,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Heading from "../../components/Ui/Heading";
import ProductCard from "../../components/Ui/ProductCard/ProductCard";
import { useGetAllProductsQuery } from "../../redux/products/productsApi";
import { TbEqual } from "react-icons/tb";
import { useSelector } from "react-redux";

const ComboProducts = ({ show, setShow }) => {
    const comboProducts = useSelector((state) => state.comboProducts);

    let reverseComboProducts = null;

    if (comboProducts?.products?.length > 0) {
        reverseComboProducts = [...comboProducts.products]?.reverse();
    }
    let reverseComboProductsSlice = reverseComboProducts;
    const {
        data: allProducts,
        isLoading: productLoading,
        isError: productError,
    } = useGetAllProductsQuery();

    let content = null;
    if (productLoading) {
        content = <Skeleton count={4} />;
    }
    if (!productLoading && productError) {
        content = <p className="text-red fs-4">There was an error.</p>;
    }
    if (!productLoading && !productError && allProducts?.result.length === 0) {
        content = (
            <p className="text-center fs-4 text-primary">
                There is no product this type.
            </p>
        );
    }
    if (!productLoading && !productError && allProducts?.result.length > 0) {
        content = allProducts?.result?.map((cart) => (
            <Col className="mb-4">
                <ProductCard
                    cart={cart}
                    show={show}
                    setShow={setShow}
                    isCombo={true}
                />
            </Col>
        ));
    }

    const [activeValue, setActiveValue] = useState(1);
    let active = activeValue;
    let items = [];
    for (let number = 1; number <= 5; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === active}
                onClick={() => setActiveValue(number)}
            >
                {number}
            </Pagination.Item>
        );
    }
    return (
        <Container>
            <Heading className="text-center my-3">Make your own combo</Heading>
            <div className="mb-4 pb-2  d-flex align-items-center gap-3 justify-content-center border py-2">
                {reverseComboProducts?.length > 0 &&
                    reverseComboProductsSlice?.slice(0, 3).map((product) => (
                        <div
                            key={product?._id}
                            style={{ width: "200px" }}
                            className=" p-2 text-center"
                        >
                            <Image src="https://thekoreanmall.com/image/cache/catalog/A-Commonlabs/Commonlabs-Vitamin-E-Calming-Light-Cream-70g-100x100.jpg" />
                            <p className="text-truncate">{product?.name}</p>
                            <p>
                                <strike>TK.{product?.price}</strike>
                                <span className="ps-1">
                                    TK.{product?.price}
                                </span>
                            </p>
                        </div>
                    ))}

                {/* <BsPlusLg style={{ fontSize: "1.5rem" }} />
        <div style={{ width: "200px" }} className=" p-2 text-center">
          <Image src="https://thekoreanmall.com/image/cache/catalog/A-Commonlabs/Commonlabs-Vitamin-E-Calming-Light-Cream-70g-100x100.jpg" />
          <p className="text-truncate">
            SKIN1004 Madagascar Centella Tone Brightening Capsule Cream 75ml
          </p>
          <p>
            <strike>TK.840</strike>
            <span className="ps-1">TK.740</span>
          </p>
        </div> */}

                {reverseComboProducts?.length > 0 && (
                    <>
                        {" "}
                        <TbEqual style={{ fontSize: "2rem" }} />
                        <div className="px-3">
                            <p>Total Amount: TK. 1312</p>
                            <p style={{ color: "#6EC275" }}>Save TK. 312</p>
                        </div>
                    </>
                )}
                <div>
                    {reverseComboProducts?.length > 2 ? (
                        <Button variant="dark" className="rounded-0">
                            Add All to Bag
                        </Button>
                    ) : (
                        <Button variant="outline-dark" className="rounded-0">
                            Minimum Three Products Add
                        </Button>
                    )}
                </div>
            </div>
            <Row md={5} className="">
                {content}
            </Row>
            <div className="d-flex justify-content-center mt-4">
                <Pagination>{items}</Pagination>
            </div>
        </Container>
    );
};

export default ComboProducts;
