import React from 'react';
import { Container } from 'react-bootstrap';
import CategorizedProductCarousel from '../../components/Home/CategorizedProductCarousel';
import { useGetProductsByTypeQuery } from '../../redux/products/productsApi';

const FeaturedProducts = () => {
    const {
        data: featuredProducts,
        isLoading,
        isError,
    } = useGetProductsByTypeQuery("featured-product");
    return (
        <Container>
            {" "}
            {
                <CategorizedProductCarousel
                    title={"Featured Products"}
                    productList={featuredProducts?.result}
                    isLoading={isLoading}
                    isError={isError}
                />
            }
        </Container>
    );
};

export default FeaturedProducts;