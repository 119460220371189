import React, { useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import './NewArrivalsTabs.scss';
import Routine from './Routine';
import SkinConcern from './SkinConcern';
import SkinType from './SkinType';
import BestSeller from './BestSeller';
import { useGetProductsByTypeQuery } from "../../../redux/products/productsApi"
import SliderProductLoading from '../../Ui/Loder/SliderProductLoading';
import { Link } from 'react-router-dom';
const NewArrivalsTabs = () => {
	const [activeKye, setActiveKey] = useState("1")
	const {
		data: bestSellerProducts,
		isLoading,
	} = useGetProductsByTypeQuery("best-seller");
	const handleTabSelect = (key) => {
		setActiveKey(key);
	};
	return (
		<Container>
			<Tabs
				defaultActiveKey="1"
				id="tabs-parent"
				className="mb-3 mt-3"
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '10px',
				}}
				onSelect={handleTabSelect}
			>
				{/* best sellers */}
				<Tab eventKey="1" title={activeKye === "1" ? <Link className='text-decoration-none tab-link' to="/products?productType=best-seller">SHOP BY BEST SELLERS</Link> : "SHOP BY BEST SELLERS"}>
					{isLoading ? <SliderProductLoading /> : <BestSeller products={bestSellerProducts?.result} />}

				</Tab>
				{/* shop by skin type */}
				<Tab eventKey="2" title="SHOP BY SKIN TYPE" >
					<SkinType />
				</Tab>

				{/* shop by skin concern */}
				<Tab eventKey="3" title="SHOP BY SKIN CONCERN">
					<SkinConcern categoryList={''} />
				</Tab>

				{/* shop by routine */}
				<Tab eventKey="4" title="SHOP BY ROUTINE">
					<Routine categoryList={''} />
				</Tab>
			</Tabs>
		</Container >
	);
};

export default NewArrivalsTabs;
