import React from 'react';
import "./ProductLoding.scss"
import { Col, Container, Row } from 'react-bootstrap';


const ProductLoading = () => {
    return (
        <section>
            <Container className='d-flex justify-content-center align-item-center my-5' >
                <Row>
                    <Col md={3} xs={6} >
                        <div className="loading-container">
                            <div className="loading-product" style={{ height: "17.5rem", width: "13.75rem" }} />
                            <div className='d-flex align-items-center justify-content-center gap-2'>
                                <div className="loading-price" />
                                <div className="loading-price" />
                            </div>
                            <div className="loading-text" />
                            <div className="loading-text" />
                        </div>
                    </Col>
                    <Col md={3} xs={6} >
                        <div className="loading-container">
                            <div className="loading-product" style={{ height: "17.5rem", width: "13.75rem" }} />
                            <div className='d-flex align-items-center justify-content-center gap-2'>
                                <div className="loading-price" />
                                <div className="loading-price" />
                            </div>
                            <div className="loading-text" />
                            <div className="loading-text" />
                        </div>
                    </Col>
                    <Col md={3} xs={6} >
                        <div className="loading-container">
                            <div className="loading-product" style={{ height: "17.5rem", width: "13.75rem" }} />
                            <div className='d-flex align-items-center justify-content-center gap-2'>
                                <div className="loading-price" />
                                <div className="loading-price" />
                            </div>
                            <div className="loading-text" />
                            <div className="loading-text" />
                        </div>
                    </Col>
                    <Col md={3} xs={6} >
                        <div className="loading-container">
                            <div className="loading-product" style={{ height: "17.5rem", width: "13.75rem" }} />
                            <div className='d-flex align-items-center justify-content-center gap-2'>
                                <div className="loading-price" />
                                <div className="loading-price" />
                            </div>
                            <div className="loading-text" />
                            <div className="loading-text" />
                        </div>
                    </Col>

                </Row>
            </Container>
            <Container className='d-flex justify-content-center align-item-center my-5' >
                <Row>
                    <Col md={3} xs={6} >
                        <div className="loading-container">
                            <div className="loading-product" style={{ height: "17.5rem", width: "13.75rem" }} />
                            <div className='d-flex align-items-center justify-content-center gap-2'>
                                <div className="loading-price" />
                                <div className="loading-price" />
                            </div>
                            <div className="loading-text" />
                            <div className="loading-text" />
                        </div>
                    </Col>
                    <Col md={3} xs={6} >
                        <div className="loading-container">
                            <div className="loading-product" style={{ height: "17.5rem", width: "13.75rem" }} />
                            <div className='d-flex align-items-center justify-content-center gap-2'>
                                <div className="loading-price" />
                                <div className="loading-price" />
                            </div>
                            <div className="loading-text" />
                            <div className="loading-text" />
                        </div>
                    </Col>
                    <Col md={3} xs={6} >
                        <div className="loading-container">
                            <div className="loading-product" style={{ height: "17.5rem", width: "13.75rem" }} />
                            <div className='d-flex align-items-center justify-content-center gap-2'>
                                <div className="loading-price" />
                                <div className="loading-price" />
                            </div>
                            <div className="loading-text" />
                            <div className="loading-text" />
                        </div>
                    </Col>
                    <Col md={3} xs={6} >
                        <div className="loading-container">
                            <div className="loading-product" style={{ height: "17.5rem", width: "13.75rem" }} />
                            <div className='d-flex align-items-center justify-content-center gap-2'>
                                <div className="loading-price" />
                                <div className="loading-price" />
                            </div>
                            <div className="loading-text" />
                            <div className="loading-text" />
                        </div>
                    </Col>

                </Row>
            </Container>

        </section>



    );
};

export default ProductLoading;