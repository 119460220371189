import React from 'react';
import { Row } from 'react-bootstrap';
import NoData from '../../../components/Ui/NoData';
import { useGetAllAddressQuery } from '../../../redux/address/addressApi';
import { useDispatch, useSelector } from 'react-redux';
import SingleAddress from '../../../components/Ui/Modal/SingleAddress';
import { showBillingModal } from '../../../redux/modal/modalSlice';

const ViewAddresses = () => {
	const { email } = useSelector((state) => state.user.user) || {};
	const dispatch = useDispatch();

	const {
		data: allAddress,
		isLoading,
		isError,
	} = useGetAllAddressQuery(email, { skip: !email });

	let content = null;
	if (isLoading) {
		content = <p>Address loading...</p>;
	}
	if (!isLoading && !isError && allAddress?.length === 0) {
		content = <NoData />

	}
	if (!isLoading && !isError && allAddress?.length > 0) {
		content = allAddress?.map((address) => (
			<SingleAddress key={address?._id} address={address} />
		));
	}
	return (
		<>
			<div className='d-flex justify-content-between align-items-center'>
				<h2 className="text-start">View Addresses</h2>
				<button
					onClick={() => dispatch(showBillingModal({ active: true, id: "" }))}
					className="btn btn-sm btn-dark"
				>+ Create New</button>
			</div>
			<div className="doted-container"></div>
			<Row className="gy-3">
				{content}
			</Row>
		</>
	);
};

export default ViewAddresses;
