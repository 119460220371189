import React from 'react';
import { useGetAllSortedBrandsQuery } from '../../redux/categories/categoriesApi';
import Loading from '../../components/Ui/loading/Loading';
import "./brand.css";
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const AllBrands = () => {
    const { data: brands, isLoading } = useGetAllSortedBrandsQuery();
    let content = null;
    if (isLoading) {
        content = <Loading />
    }
    if (!isLoading && brands?.result?.length > 0) {
        content = brands?.result.map((brand) => <div key={brand?.letter} className='mt-4'>
            <div style={{ backgroundColor: "rgba(0,0,0,0.01)" }} id={brand.letter} className='custom-border fw-bold'>{brand.letter}</div>
            <div className='custom-border no-top-border'>
                <div className='brand-parent ms-1'>
                    {
                        brand.brands.map(b => <ul key={b.name} className='single-brand'>
                            <li style={{ fontSize: "0.9em" }}> <Link className='brand-text  text-decoration-none' to={`/brand-products/${b.slug}`}>{b.name}</Link></li>
                        </ul>)
                    }
                </div>
            </div>
        </div>)
    }
    return (
        <Container className='my-4 px-2'>
            <div className='text-center mb-2'>
                <h1 style={{ display: "inline-block", borderBottom: "2px solid red" }}>Find Your Favorite Brand</h1>

                <div className='d-flex align-items-center justify-content-center  gap-1'>
                    <Link to="/" className='text-decoration-none text-black'>Home</Link> - <Link className='text-decoration-none text-black'>Brand</Link>
                </div>
            </div>

            <div>
                <div className='d-flex gap-2  align-items-center flex-wrap' style={{ fontSize: "1rem" }}>
                    Brand Index:
                    {brands?.result.map(brand => <a key={brand?._id} className='fw-bold text-decoration-none brand-text' href={`#${brand.letter}`} style={{ fontSize: "0.9em", textTransform: "uppercase", cursor: "pointer" }}>{brand.letter}</a>)}
                </div>
                {content}

            </div>
        </Container>
    );
};


export default AllBrands;