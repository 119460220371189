//update to loc

export const getDb = (cart) => {
	return localStorage.getItem(cart);
};

export const updateCartDb = (cart) => {
	localStorage.setItem('shopping_cart', JSON.stringify(cart));
};

//Get from Local

const getFromLocal = () => {
	const exists = getDb('shopping_cart');

	return exists ? JSON.parse(exists) : [];
};

//Remove from local

const removeFromLocalStorage = (id) => {
	const cartExist = getDb('shopping_cart');
	if (!cartExist) {
	} else {
		const shopping_cart = JSON.parse(cartExist);
		const exist = shopping_cart.filter((item) => item.product._id !== id);
		updateCartDb(exist);
	}
};
const clearTheCart = () => {
	localStorage.removeItem('shopping_cart');
};

export { removeFromLocalStorage, clearTheCart, getFromLocal };
