import { apiSlice } from "../api/apiSlice";

export const shippingApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        //get all reviews BY EMAIL
        getAllDivision: builder.query({
            query: () => ({
                url: `/division`,
                method: "POST"
            }),
        }),

        //get all reviews BY EMAIL
        getAllDistrict: builder.query({
            query: (division) => ({
                url: `/district/filter?parent=${division}`,
                method: "POST"
            }),
        }),

        //get all reviews BY EMAIL
        getAllSubDistrict: builder.query({
            query: (district) => ({
                url: `/sub-district/filter?parent=${district}`,
                method: "POST"
            }),
        }),


    }),
});

export const {
    useGetAllDivisionQuery,
    useGetAllDistrictQuery,
    useGetAllSubDistrictQuery,
} = shippingApi;
