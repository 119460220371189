import React from 'react';

const Success = ({ children }) => {
    return (
        <div className='my-1 bg-success' style={{ border: "1px solid #198754", color: "white", borderRadius: ".25rem", padding: ".75rem 1.25rem" }}>
            {children}
        </div>
    );
};

export default Success;