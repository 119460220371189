import { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import './Navbar.scss';

import { useLayoutEffect } from 'react';
import { MdOutlineShoppingBag } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useGetAddToCartDataByEmailQuery } from '../../../redux/addToCart/addToCartApi';
import { useGetAllCategoryQuery } from '../../../redux/categories/categoriesApi';
import { setCartDrawer } from '../../../redux/modal/modalSlice';
import SingleCategory from './SingleCategory';
import TopNavbar from './TopNavbar';

const UiNavbar = () => {
	const userEmail = useSelector((state) => state?.user?.user?.email);
	const size = useWindowSize();
	const [isSmallDevice, setSmallDevice] = useState();
	const [haveUser, setHaveUser] = useState(true);
	const [showLogoIcon, setShowLogoIcon] = useState(false);
	const dispatch = useDispatch();

	const { data: addToCartData } = useGetAddToCartDataByEmailQuery(
		userEmail,
		{
			skip: haveUser,
		}
	);


	const {
		data: allCategories,
		isLoading: categoriesLoading,
	} = useGetAllCategoryQuery();

	useLayoutEffect(() => {
		if (size.width < 1020) {
			setSmallDevice(true);
		} else {
			setSmallDevice(false);
		}
	}, [size]);
	const { cartData } = useSelector((state) => state.cartData);
	const localQuantity = cartData.reduce((acc, item) => acc + item.quantity, 0);
	useEffect(() => {
		if (userEmail) {
			setHaveUser(false);
		}
	}, [userEmail]);

	const changeLogo = () => {
		if (window.scrollY >= 130) {
			setShowLogoIcon(true);
		} else {
			setShowLogoIcon(false);
		}
	};
	window.addEventListener('scroll', changeLogo);

	let content = null;
	if (categoriesLoading) {
		content = <div className='d-flex justify-content-center align-items-center py-4 gap-4'>
			<p className='loading-product' style={{ height: "12px", width: "30px" }}></p>
			<p className='loading-product' style={{ height: "12px", width: "30px" }}></p>
			<p className='loading-product' style={{ height: "12px", width: "60px" }}></p>
			<p className='loading-product' style={{ height: "12px", width: "60px" }}></p>
			<p className='loading-product' style={{ height: "12px", width: "60px" }}></p>
			<p className='loading-product' style={{ height: "12px", width: "70px" }}></p>
			<p className='loading-product' style={{ height: "12px", width: "60px" }}></p>
			<p className='loading-product' style={{ height: "12px", width: "90px" }}></p>
			<p className='loading-product' style={{ height: "12px", width: "90px" }}></p>
		</div>
	}
	if (!categoriesLoading && allCategories?.result?.length > 0) {
		content = allCategories?.result?.map((grandParent) => {
			return grandParent?.children?.length > 0 ? (
				<SingleCategory grandParent={grandParent} />
			) : (
				<Nav.Link
					as={Link}
					to={`/products?category=${grandParent?.slug}`}
					className="me-3 single_link_parent"
				>
					<span className="single_link_child pb-1">{grandParent?.title}</span>
				</Nav.Link>
			);
		});
	}

	return (
		<>
			<TopNavbar totalQuantity={addToCartData?.data?.totalQuantity} />

			<Navbar
				id="navbar_Link_parent"
				sticky="top"
				expand="lg"
				className=" px-3 bg-dark py-0"
			>

				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse>
					<Nav className="m-auto" style={{ height: "5rem" }}>{content}</Nav>
				</Navbar.Collapse>

				{/* bag */}
				<div
					onClick={() => dispatch(setCartDrawer(true))}
					className={`${!isSmallDevice && showLogoIcon
						? 'm-auto d-flex align-items-center justify-content-center fw-normal add_cart_parent d-block'
						: 'd-none'
						}`}
					style={{ fontSize: '12px' }}
				>
					<MdOutlineShoppingBag
						style={{
							width: '25px',
							height: '22px',
							cursor: 'pointer',
							color: 'white',
						}}
					/>
					<div className="add_cart">
						{userEmail ? (
							<span>{addToCartData?.data?.totalQuantity || 0}</span>
						) : (
							<span>{localQuantity}</span>
						)}
					</div>
				</div>
			</Navbar>
		</>
	);
};

export default UiNavbar;
