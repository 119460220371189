import React, { useState } from "react";
import { Button, NavDropdown } from "react-bootstrap";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { useGetAllBrandsQuery } from "../../../redux/categories/categoriesApi";
import Loading from "../../Ui/loading/Loading";

const SingleCategory = ({ grandParent }) => {
  const {
    data: allBrands,
    isLoading
  } = useGetAllBrandsQuery();
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  if (isLoading) return <Loading />
  let brandList = [];
  brandList = allBrands?.result?.slice(0, 29).map((brand) => {
    return (
      <React.Fragment key={brand?._id}>
        {
          <div>
            <NavDropdown.Item
              as={Link}
              to={`/brand-products/${brand?.slug}`}
              className="text-uppercase "
              style={{
                width: "180px",
              }}
            >
              {brand?.name}
            </NavDropdown.Item>
            <NavDropdown.Divider
              className="bg-light m-0"
              style={{ opacity: "0.2" }}
            />
          </div>
        }
      </React.Fragment>
    );
  })
  return (
    <TkmNavDropdown
      title={
        <div>
          <Link
            className="parent_hover_bg text-decoration-none text-white "
            to={currentPath}
          >
            {grandParent?.title}
          </Link>
          <MdOutlineKeyboardArrowDown style={{ fontSize: "1.2rem" }} />
        </div>
      }
      id="basic-nav-dropdown"
      className={`nav_dropdown_parent ${grandParent?.title?.toLowerCase() === "brands" &&
        "nav_dropdown_parent_brand"
        } me-2 parent_hover`}
    >
      {grandParent?.title?.toLowerCase() === "brands" ? (
        <div className="brand_parent">
          {[...brandList, <Button as={Link} to="/product/brands" className="text-decoration-none" variant="danger"><div className=" text-white" style={{ fontSize: "0.9em" }}>OTHERS <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path d="M19.1643 12.0001L12.9572 5.79297L11.543 7.20718L16.3359 12.0001L11.543 16.793L12.9572 18.2072L19.1643 12.0001ZM13.5144 12.0001L7.30728 5.79297L5.89307 7.20718L10.686 12.0001L5.89307 16.793L7.30728 18.2072L13.5144 12.0001Z" fill="rgba(255,255,255,1)"></path></svg></div></Button>]}

        </div>
      ) : (
        grandParent?.children?.map((child) => {
          return child?.children?.length > 0 ? (
            <>
              <TkmNavDropdown
                title={
                  <Link
                    to={currentPath}
                    className="d-flex align-items-center justify-content-between child_hover text-capitalize text-decoration-none text-white"
                    style={{
                      minWidth: "240px",
                      maxWidth: "100%",
                    }}
                  >
                    {child?.title}
                    <MdOutlineKeyboardArrowRight
                      style={{
                        fontSize: "1.2rem",
                      }}
                    />
                  </Link>
                }
                className="nav_dropdown parent_hover2 "
              >
                {child?.children?.map((grandChild) => (
                  <>
                    <NavDropdown.Item
                      as={Link}
                      to={`/products?category=${grandChild?.slug}`}
                      className="text-capitalize child_hover2"
                    >
                      {grandChild?.title}
                    </NavDropdown.Item>
                    <NavDropdown.Divider
                      className="bg-light m-0"
                      style={{ opacity: "0.2" }}
                    />
                  </>
                ))}
              </TkmNavDropdown>
              <NavDropdown.Divider
                className="bg-light m-0"
                style={{ opacity: "0.2" }}
              />
            </>
          ) : (
            <>
              <NavDropdown.Item
                as={Link}
                to={`/products?category=${child?.slug}`}
                className="text-capitalize"
              // style={{ width: "200px" }}
              >
                {child?.title}
              </NavDropdown.Item>
              <NavDropdown.Divider
                className="bg-white m-0"
                style={{ opacity: "0.3" }}
              />
            </>
          );
        })
      )}
    </TkmNavDropdown>
  );
};

export default SingleCategory;

const TkmNavDropdown = ({ title, children, id, className }) => {
  const [show, setShow] = useState(false);

  return (
    <NavDropdown
      title={title}
      id={id}
      className={className}
      show={show}
      onMouseEnter={() => setShow((prevState) => !prevState)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
    </NavDropdown>
  );
};
