import React from 'react';
import Countdown from 'react-countdown';
import './countDown.scss';

const CountDown = ({ timeStamps }) => {
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (!completed) {
            return (
                <div className="">
                    <div className="d-flex justify-content-center count-down">
                        <div className="counter counter-mbl">
                            <p className="counter-number counter-mbl">{days}</p>
                            <p className="p-0 counter-string counter-string-mbl">
                                {days > 1 ? 'Days' : 'Day'}
                            </p>
                        </div>
                        <div className="counter counter-mbl">
                            <p className="counter-number counter-mbl">{hours}</p>
                            <p className="p-0 counter-string counter-string-mbl">
                                {hours > 1 ? 'Hours' : 'Hour'}
                            </p>
                        </div>
                        <div className="counter counter-mbl">
                            <p className="counter-number counter-mbl">{minutes}</p>
                            <p className="p-0 counter-string counter-string-mbl">Min</p>
                        </div>
                        <div className="counter counter-mbl">
                            <p className="counter-number counter-mbl">{seconds}</p>
                            <p className="p-0 counter-string counter-string-mbl">Sec</p>
                        </div>
                    </div>
                </div>
            );
        }
    };
    return <Countdown date={timeStamps} renderer={renderer} />;
};

export default CountDown;
