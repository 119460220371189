import { apiSlice } from "../api/apiSlice";

export const addToCartApi = apiSlice.injectEndpoints({
    tagTypes: ["AllOrders"],
    endpoints: (builder) => ({
        //get all add to cart data by email address
        getAddToCartDataByEmail: builder.query({
            query: (email) => ({
                url: `/cart/myCart/${email}`,
                method: "POST",
            }),
            providesTags: ["AllCartData"],
        }),

        mergeDataFromLocalStorage: builder.mutation({
            query: ({ email, data }) => ({
                url: `/cart/mergeProduct/${email}`,
                method: "POST",
                body: { products: data }
            }),
            invalidatesTags: ["AllCartData"]
        }),

        addToCartNewDataByEmail: builder.mutation({
            query: ({ userEmail, data }) => ({
                url: `/cart/cartAdd/${userEmail}`,
                method: "POST",
                body: data,
            }),
            // async onQueryStarted({ userEmail }, { dispatch, queryFulfilled }) {
            //     try {
            //         const res = await queryFulfilled;
            //         const products = res.data.data.products || {};
            //         console.log("res", res)
            //         dispatch(
            //             apiSlice.util.updateQueryData("getAddToCartDataByEmail", userEmail, (draft) => {
            //                 draft.data = products
            //             })
            //         )
            //     } catch (error) {
            //         // patchResult.undo()
            //     }
            // },
            invalidatesTags: ["AllCartData"]

        }),

        // quantity decrease
        decreaseQuantity: builder.mutation({
            query: ({ userEmail, data }) => ({
                url: `/cart/cartAdd/${userEmail}`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["AllCartData"],
        }),

        deleteCardDataByEmailId: builder.mutation({
            query: ({ productId, userEmail }) => ({
                url: `/cart/cartEdit/${userEmail}`,
                method: "DELETE",
                body: productId,
            }),
            invalidatesTags: ["AllCartData"],
        }),

        //post single order by email
        placeSingleOrderByEmail: builder.mutation({
            query: ({ userEmail, modifiedData }) => ({
                url: `/order/add/newOrder/${userEmail}`,
                method: "POST",
                body: modifiedData,
            }),
            invalidatesTags: ["AllCartData", "AllOrders"],
        }),

        //post single order by email
        orderCancel: builder.mutation({
            query: (data) => ({
                url: `/report/post`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["AllOrders"],
        }),

        // order api here for performance

        //get all orders by user email
        getAllOrdersByEmail: builder.query({
            query: ({ userEmail, status }) => ({
                url: `/order/user/all/${userEmail}?status=${status}`,
                method: "POST",
            }),
            providesTags: ["AllOrders"],
        }),
    }),
});

export const {
    useGetAllOrdersByEmailQuery,
    usePlaceSingleOrderByEmailMutation,
    useMergeDataFromLocalStorageMutation,
    useGetAddToCartDataByEmailQuery,
    useAddToCartNewDataByEmailMutation,
    useDeleteCardDataByEmailIdMutation,
    useDecreaseQuantityMutation,
    useOrderCancelMutation,
} = addToCartApi;
