import React from "react";
import { Container } from "react-bootstrap";
import BlogCard from "../../components/Ui/Blog/BlogCard";
import { useGetAllBlogsDataQuery } from "../../redux/blog/blogApi";
import "./singleBlog.scss"
import ProductLoading from "../../components/Ui/Loder/ProductLoading";

const AllBlogs = () => {
  const { data: blogs, isLoading, isError } = useGetAllBlogsDataQuery();

  let content = null;
  if (isLoading) {
    content = <ProductLoading />
  }

  if (!isLoading && blogs?.data?.length === 0) {
    content = <h1 className="text-center">There is no blogs</h1>;
  }

  if (!isLoading && blogs?.data?.length > 0) {
    content = <div className="all-blog-container">
      {blogs?.data.map((item) => (
        <BlogCard key={item._id} blog={item} />
      ))}
    </div>
  }

  return (
    <Container className="my-4">
      <div className="mb-5  rounded-0" >
        <h3 className="text-center">All Blogs</h3>
      </div>
      {content}
    </Container>
  );
};

export default AllBlogs;
