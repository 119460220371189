import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import NoData from "../../../components/Ui/NoData";
import { useGetAllReviewsByEmailQuery } from "../../../redux/review/reviewApi";
import ReactStars from "react-rating-stars-component";


const ViewReviews = () => {
    const userEmail = useSelector((state) => state?.user?.user?.email);

    const [haveUser, setHaveUser] = useState(true);

    const {
        data: allReviews,
        isLoading,
        isError,
    } = useGetAllReviewsByEmailQuery(userEmail, { skip: haveUser });

    useEffect(() => {
        if (userEmail) {
            setHaveUser(false);
        }
    }, [userEmail]);
    console.log(allReviews)

    let content = null;
    if (isLoading) {
        content = <Skeleton count={3} />;
    }
    if (!isLoading && isError) {
        content = <p className="text-danger">There is Something wrong!</p>;
    }
    if (!isLoading && !isError && allReviews?.length === 0) {
        content = <NoData />;
    }
    if (!isLoading && !isError && allReviews?.length > 0) {
        const reverseAllReviews = [...allReviews]?.reverse();
        content = (
            <div>
                <p>Total Reviews: {allReviews?.length}</p>
                {reverseAllReviews?.map((review) => (
                    <Row key={review?._id} className="d-flex justify-content-center align-items-center border">
                        <Col xs={4} md={2}>
                            <Image src={review?.product?.images?.[0]} fluid />
                        </Col>
                        <Col xs={8} md={6}>
                            <p className="">
                                {review?.product?.name}
                            </p>
                            <small>
                                <span className="fw-semibold">
                                    Your comment:
                                </span>{" "}
                                {review?.description}
                            </small>
                        </Col>
                        <Col xs={6} md={2}>
                            <ReactStars
                                count={5}
                                size={15}
                                edit={false}
                                activeColor="#ffd700"
                                value={review?.rating}
                            />
                        </Col>
                        <Col xs={2} md={2}>
                            <div className="d-flex align-items-center flex-column mt-0">
                                <small>
                                    {new Date(
                                        review?.createdAt
                                    ).toLocaleDateString()}
                                </small>
                            </div>
                        </Col>
                    </Row>
                ))}
            </div>
        );
    }

    return (
        <>
            <div>
                <h2 className="text-start">View Reviews</h2>
                <div className="doted-container">{content}</div>
            </div>
        </>
    );
};

export default ViewReviews;
