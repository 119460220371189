import React from "react";
import "./sidebarCountDown.scss";
import Countdown from "react-countdown";

const SideBarCountDown = ({ timeStamps }) => {
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (!completed) {
            return (
                <div>
                    <div className="d-flex  count-down-rs">
                        <div className="counter-rs">
                            <p className="counter-number-rs">{days}</p>
                            <p className="p-0 counter-string-rs">
                                {days > 1 ? "Days" : "Day"}
                            </p>
                        </div>
                        <div className="counter-rs">
                            <p className="counter-number-rs">{hours}</p>
                            <p className="p-0 counter-string-rs">
                                {hours > 1 ? "Hours" : "Hour"}
                            </p>
                        </div>
                        <div className="counter-rs">
                            <p className="counter-number-rs">{minutes}</p>
                            <p className="p-0 counter-string-rs">Min</p>
                        </div>
                        <div className="counter-rs">
                            <p className="counter-number-rs">{seconds}</p>
                            <p className="p-0 counter-string-rs">Sec</p>
                        </div>
                    </div>
                </div>
            );
        }
    };
    return <Countdown date={timeStamps} renderer={renderer} />;
};

export default SideBarCountDown;
