import React from "react";
import CategoryCarusel from "./ShopBy/CategoryCarusel";
import { useGetCategoryByTitleQuery } from "../../../redux/categories/categoriesApi";

const SkinConcern = () => {
    const { data } = useGetCategoryByTitleQuery("subCategory=skin concern");

    return <CategoryCarusel data={data?.data?.children} />;
};

export default SkinConcern;
