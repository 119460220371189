import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchText: "",
};

export const productSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    searchResult: (state, action) => {
      state.searchText = action.payload;
    },
  },
});

export const { searchResult } = productSlice.actions;
export default productSlice.reducer;
