import React from "react";
import Navbar from "./Navbar";
import NavbarBrandSection from "./NavbarBrandSection";

const ResponsiveMobileNavbar = () => {

    return (
        <div id="mobileNavbar">
            {/* navbar brand */}
            <NavbarBrandSection />
            {/* modal */}
            <Navbar />
        </div>
    );
};

export default ResponsiveMobileNavbar;
