import React, { useEffect, useState, useRef } from "react";
import { ListGroup } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useGetSearchSuggestionQuery } from "../../../redux/products/productsApi";
import { useNavigate } from "react-router-dom";
import "./SuggestionBar.scss";

const SuggestionBar = ({
  setSearchQuery,
  searchQuery,
  searchIconColor,
  searchBarWidth,
}) => {
  const [showSuggestionBar, setShowSuggestionBar] = useState(false);
  const { data } = useGetSearchSuggestionQuery(searchQuery, { skip: !searchQuery });
  const [itemClicked, setItemClicked] = useState(false);
  const navigate = useNavigate();
  const suggestionBarRef = useRef(null);

  useEffect(() => {
    if (data?.tags?.length > 0) {
      setShowSuggestionBar(true);
    }
    if (itemClicked) {
      setShowSuggestionBar(false);
      setItemClicked(false);
    }
  }, [data, itemClicked]);

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      suggestionBarRef.current &&
      !suggestionBarRef.current.contains(event.target)
    ) {
      setShowSuggestionBar(false);
    }
  };

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery === "") {
      navigate(`/`);
    } else {
      setSearchQuery(searchQuery);
      navigate(`products?search=${searchQuery}`);
    }
  };

  const handleItemClick = (tag) => {
    navigate(`products?search=${tag}`);
    setSearchQuery(tag);
    setShowSuggestionBar(false);
    setItemClicked(!itemClicked);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        zIndex: "1021",
      }}
    >
      <form
        onSubmit={handleSearch}
        style={{ display: "flex", alignItems: "center" }}
      >
        <input
          type="text"
          placeholder="Search for products..."
          value={searchQuery}
          onChange={handleInputChange}
          style={
            searchBarWidth
              ? {
                marginRight: "0px",
                width: `${searchBarWidth}px`,
                padding: ".585rem 1rem",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
                border: "none",
                backgroundColor: "#EEEEEE",
                outline: "none",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
              }
              : {
                marginRight: "0px",
                width: "15rem",
                padding: ".585rem 1rem",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
                border: "none",
                backgroundColor: "#EEEEEE",
                outline: "none",
                fontSize: "14px",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
              }
          }
          inputMode="text"
        />

        <button
          className={`border-0  ${searchIconColor
            ? "bg-light text-dark padding-mobile"
            : "bg-dark text-light padding-desktop"
            }`}
          type="submit"
        >
          <BsSearch className="" />
        </button>
      </form>
      {showSuggestionBar && (
        <div
          ref={suggestionBarRef}
          style={{
            position: "absolute",
            top: "100%",
            left: "0",
            width: "100%",
            backgroundColor: "#EEEEEE",
            borderRadius: "15px",
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
            zIndex: 1021,
            overflow: "hidden",
          }}
        >
          <ListGroup style={{ maxHeight: "200px", overflowY: "auto" }}>
            {data?.tags?.map((product, index) => (
              <ListGroup.Item
                key={index}
                style={{
                  border: "none",
                  cursor: "pointer",
                  padding: "5px 15px",
                }}
                className="suggestion-bar-item"
                onClick={() => handleItemClick(product)}
              >
                {product}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}
    </div>
  );
};

export default SuggestionBar;
