import React from "react";
import { Container, Image } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useGetBrandProductsByBrandSlugQuery } from "../../redux/products/productsApi";
import ProductCard from "../../components/Ui/ProductCard/ProductCard";
import "./Brand.scss";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlHome } from "react-icons/sl";
import ProductLoading from "../../components/Ui/Loder/ProductLoading";
import PaginationButton from "../../components/Ui/Button/PaginationButton";

const BrandsProduct = () => {
    const { brandId } = useParams();
    const page = useLocation().search || "?";

    const {
        data: brandProducts,
        isFetching: isLoading,
    } = useGetBrandProductsByBrandSlugQuery({ brandId, page });

    let content = null;
    if (isLoading) {
        content = <ProductLoading />
    }
    if (!isLoading && brandProducts?.result?.length === 0) {
        content = (
            <h1 className="text-center">There is no products on this brand</h1>
        );
    }
    if (!isLoading && brandProducts?.result?.length > 0) {
        content = (
            <div className="product_container">
                {brandProducts?.result?.map((product) => (
                    <ProductCard key={product?._id} cart={product} height={true} shortHeight={true} />
                ))}
            </div>
        );
    }
    return (
        <Container id="brand_product">
            {brandProducts?.brand && (
                <Image
                    className="mt-1"
                    style={{
                        width: "100%",
                        objectFit: "cover",
                        height: "auto",
                    }}
                    src={brandProducts?.brand?.image}
                />
            )}
            <div className="d-flex gap-1 align-items-center my-2">
                <Link to="/" className="mb-1">
                    {" "}
                    <SlHome />
                </Link>
                <MdOutlineKeyboardArrowRight />
                <Link to="." className="text-decoration-none">
                    <p className=" text-black ">Brands </p>
                </Link>
                <MdOutlineKeyboardArrowRight />
                <Link to="." className="text-decoration-none">
                    <p className=" text-black ">{brandProducts?.brand?.name}</p>
                </Link>
            </div>
            {content}
            <PaginationButton totalPageNumber={brandProducts?.totalPageNumber} />
        </Container>
    );
};

export default BrandsProduct;
