import SlickIcon from "../../../assets/svg/Slick";
import SlickLeftIcon from "../../../assets/svg/SlickLeft";


export const ArrowRight = ({ onClick }) => (
    <button
        className="btn p-2 custom-arrow custom-arrow-right"
        onClick={onClick}
        style={{
            background: '#f4f4f4',
            height: '48px',
            width: '46px',
            right: '2rem',
            borderRadius: '50%',
            zIndex: '1',

        }}
    >
        <SlickIcon />
    </button>
);


export const ArrowLeft = ({ onClick }) => (
    <button
        className="btn p-2 custom-arrow custom-arrow-left"
        onClick={onClick}
        style={{
            background: '#f4f4f4',
            height: '48px',
            width: '46px',
            left: '2rem',
            zIndex: '1',
            borderRadius: '50%',
        }}
    >
        <SlickLeftIcon />
    </button>
);