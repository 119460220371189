import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import "./billingModal.scss"
import { showBillingModal } from '../../../redux/modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateAddressMutation, useGetSingleAddressQuery, useUpdateAddressMutation } from '../../../redux/address/addressApi';
import { useGetAllDistrictQuery, useGetAllDivisionQuery, useGetAllSubDistrictQuery } from '../../../redux/shipping/shippingApi';


const BillingModal = () => {
  const { active, id } = useSelector(state => state.modal.billingModal);
  const { email } = useSelector(state => state.user.user) || {};
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const closeModal = () => dispatch(showBillingModal({ active: false, id: "" }));
  const [createAddress, { isLoading: addLoading, isError: addError, isSuccess }] = useCreateAddressMutation();
  const [updateAddress, { isLoading: updateLoading, isError: updateError, isSuccess: updateSuccess }] = useUpdateAddressMutation();

  const [selectDivision, setSelectDivision] = useState("");
  const [selectDistrict, setSelectDistrict] = useState("");

  const { data: divisions } = useGetAllDivisionQuery(undefined, { skip: !email })
  const { data: districts } = useGetAllDistrictQuery(selectDivision, { skip: !selectDivision })
  const { data: subDistricts } = useGetAllSubDistrictQuery(selectDistrict, { skip: !selectDistrict })
  const { data: defaultAddress } = useGetSingleAddressQuery(id, { skip: !id })



  const onSubmit = (data) => {
    data.country = "Bangladesh";
    const filteredObj = {};
    for (let key in data) {
      if (data?.[key] !== undefined && data?.[key] !== '') {
        filteredObj[key] = data[key];
      }
    }
    if (id) {
      updateAddress({ id: id, data: filteredObj })
    } else {
      createAddress({ email, data: filteredObj })
    }
  };

  useEffect(() => {
    dispatch(showBillingModal(false))
  }, [isSuccess, updateSuccess, dispatch]);

  useEffect(() => {
    if (defaultAddress?.data) {

      const defaultData = defaultAddress?.data || {};

      setSelectDivision(defaultData?.division)
      setSelectDistrict(defaultData?.district)

      for (const key in defaultData) {
        if (key === "expireDate") {
          setValue(key, new Date(defaultData[key]).toLocaleDateString());
        } else {
          setValue(key, defaultData[key]);
        }
      }

    }
  }, [defaultAddress?.data, setValue]);


  const handleDivisionChange = (selectedOptions) => {
    setSelectDivision(selectedOptions);
    setSelectDistrict("")
  };

  const handleDistrictChange = (selectedOptions) => {
    setSelectDistrict(selectedOptions);
  };

  const makeOptions = (options) => {
    let allOptions = [];
    allOptions.push(<option value="">Select one</option>)
    options?.forEach((op, index) => {
      allOptions.push(<option key={index} value={op?.title} >{op?.title}</option>)
    })
    return allOptions;
  }
  return (
    <>
      <Modal show={active} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1.5rem", fontWeight: "bold" }}>BILLING ADDRESS</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-md-4'>
          {
            (addError || updateError) && <h6 className='text-danger text-center'>There is something wrong</h6>
          }
          <Form id='billingForm' onSubmit={handleSubmit(onSubmit)}>
            <div className='row '>
              <div className='col-12 sm:col-6 px-1'>
                <span>Name <span className='text-danger'>*</span></span>
                <input type="text" className="form-control input-size" id="formGroupExampleInput" placeholder=""   {...register("shippingName", {
                  required: "Name is required",
                  pattern: {
                    value: /^[A-Za-z\s]+$/,
                    message: "Invalid name"
                  }
                })} />
                {errors?.shippingName && <span className="text-danger modal-error">{errors.shippingName.message}</span>}
              </div>
              <div className='col px-1 margin-top'>
                <span>Town/City <span className='text-danger'>*</span></span>

                <input type="text" className="form-control input-size" id="formGroupExampleInput" placeholder=""
                  {...register("city",)}
                />
              </div>

            </div>
            <div className='row margin-top'>
              <div className='col px-1' >
                <span>Select Division <span className='text-danger'>*</span></span>

                <select className='form-control input-size input-class' id="firstField"
                  {...register("division",)}
                  onChange={(e) => handleDivisionChange(e.target.value)}>
                  {
                    makeOptions(divisions?.result)
                  }
                </select>

                {errors?.division && <span className="text-danger modal-error">{"Division is required"}</span>}

              </div>
              <div className='col px-1' >
                <span>Zip Code</span>

                <input type="text" className="form-control input-size input-class" id="formGroupExampleInput" placeholder=""  {...register("postCode",)} />
              </div>

            </div>
            <div className='row margin-top'>
              <div className='col px-1'>
                <span>Select District <span className="text-danger">*</span></span>
                <select
                  disabled={!selectDivision}
                  // defaultValue={defaultAddress?.data?.district}
                  className='form-control input-size input-class' id="firstField"
                  {...register("district",)}
                  onChange={(e) => handleDistrictChange(e.target.value)}>
                  {
                    makeOptions(districts?.result)
                  }
                </select>
                {errors?.district && <span className="text-danger modal-error">District is required</span>}

              </div>
              <div className='col px-1'>
                <span>Email  </span>

                <input type="email" className="form-control input-size input-class" id="formGroupExampleInput" placeholder=""  {...register("shippingEmail",

                  {
                    ...register("shippingEmail", {
                      required: "Email is required",
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email"
                      }
                    })
                  })}
                />
                {errors?.shippingEmail && <span className="text-danger modal-error">{errors.shippingEmail.message}</span>}


              </div>

            </div>
            <div className='row margin-top'>
              <div className='col px-1'>
                <span>Select Sub District <span className="text-danger">*</span></span>


                <select
                  disabled={!selectDistrict}
                  // defaultValue={defaultAddress?.data?.upazila}
                  className='form-control input-size input-class' id="firstField"
                  {...register("upazila",)}
                >
                  {
                    makeOptions(subDistricts?.result)
                  }
                </select>

                {errors?.subDistrict && <span className="text-danger modal-error">Sub District is required</span>}
              </div>
              <div className='col px-1'>
                <small>Phone</small>
                <input
                  className="form-control input-size input-class"
                  type="tel"
                  {...register("shippingPhone", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^01\d{9}$/,
                      message: "Invalid phone number"
                    }
                  })}
                />
                {errors?.shippingPhone && <span className="text-danger modal-error">{errors.shippingPhone.message}</span>}
              </div>
            </div>
            <div className='row px-1 margin-top'>
              <span className='p-0'>Street Address <span className='text-danger'>*</span></span>
              <input type="text" className="form-control input-size px-1" id="formGroupExampleInput" placeholder="House number and street name"   {...register("address", { required: true })} />
              {errors?.address && <span className="text-danger modal-error">
                Street address is required</span>}
              <input type="text" className="form-control input-size mt-2 px-1" id="formGroupExampleInput" placeholder="Optional address"  {...register("optionalAddress",)} />
            </div>
          </Form>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
          <Button form='billingForm' type='submit' variant="primary">{addLoading || updateLoading ? "Saving..." : "Save"}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BillingModal;
