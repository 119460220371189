import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../components/Ui/Heading";
import { useGetAddToCartDataByEmailQuery } from "../../redux/addToCart/addToCartApi";
import SingleProductCard from "./SingleProductCard";
import SubTotal from "./SubTotal";
import { AiOutlinePlus } from 'react-icons/ai';

import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Cart = () => {
    const [haveUser, setHaveUser] = useState(true);
    const userEmail = useSelector((state) => state?.user?.user?.email);

    const {
        data: addToCartData,
        isLoading,
        isError,
    } = useGetAddToCartDataByEmailQuery(
        userEmail,
        { skip: haveUser }
    );

    useEffect(() => {
        if (userEmail) {
            setHaveUser(false);
        }
    }, [userEmail]);

    let content = null;
    if (userEmail) {
        if (isLoading) {
            content = <p>Loading..</p>;
        }
        if (!isLoading && isError) {
            content = <p className="text-danger">There is something wrong!</p>;
        }
        if (
            !isLoading &&
            !isError &&
            addToCartData?.data?.cartData?.products?.length === 0
        ) {
            content = <p>Your bag is empty</p>;
        }
        if (
            !isLoading &&
            !isError &&
            addToCartData?.data?.cartData?.products?.length > 0
        ) {
            content = addToCartData?.data?.cartData?.products?.map((cart) => (
                <SingleProductCard cart={cart} />
            ));
        }
    }

    return (
        <div>
            <Heading className="text-center fs-5">Shopping Bag</Heading>
            <Container>
                <Row>
                    <Col lg={8}>
                        <div className="d-flex justify-content-center shadow p-3 mb-5 bg-white rounded">
                            <button className="bg-light border-0">< AiOutlinePlus /> Add NeW Delivery Address</button>
                        </div>

                        <div className="d-flex justify-content-center shadow p-3 mb-5 bg-white rounded ">
                            <div style={{ fontSize: ".9rem" }} className="container ">
                                <p>Deliver to: Md.Nazmus Sakib</p>
                                <p><span className="p-1 rounded me-1" style={{ backgroundColor: "#EBF4F6" }}>Home</span>0120000000 | Mirpur,1, Mirpur 1, Dhaka - North, Dhaka <a className="text-decoration-none ms-2 fw-semibold" href="/">change</a></p>
                                <div className="border p-2 my-2 rounded">
                                    <p className="fw-semibold" style={{ color: "#187787" }}>Collect your parcel from the nearest Daraz Pick-up Point with a reduced shipping fee</p>
                                    <p>12 suggested collection point(s) nearby<a className="text-decoration-none ms-3 fw-semibold" href="/">Check Pick-up Points</a> </p>
                                </div>
                                <div className="my-2">
                                    <p>Bill to the same address<a className="text-decoration-none ms-3 fw-semibold" href="/">Edit</a> </p>
                                    <p>Email to
                                        abc@gmail.com<a className="text-decoration-none ms-3 fw-semibold" href="/">Edit</a> </p>
                                </div>
                            </div>
                        </div>



                        {content}

                    </Col>
                    <Col lg={4}>
                        <SubTotal
                            subTotalPrice={addToCartData?.data?.subtotal}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Cart;
