import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ImageGallery from "../../components/ProductDetails/ImageGallery";
import ProductReviews from "../../components/ProductDetails/ProductReviews";
import RightSidebar from "../../components/ProductDetails/RightSidebar/RightSidebar";
import Loading from "../../components/Ui/loading/Loading";
import { useGetSingleProductQuery } from "../../redux/products/productsApi";
import RelatedProducts from "./RelatedProducts";

const ProductDetails = ({ setShow }) => {
    const { id } = useParams();
    const {
        data: singleProductData,
        isLoading,
    } = useGetSingleProductQuery(id);

    return (
        <div>
            {isLoading ? (
                <Loading height={"100vh"} />
            ) : (
                <Container style={{ marginTop: "1rem" }} className="px-2">
                    {/* bread crumb */}
                    {/* <div className="d-flex gap-1">
                        {
                            singleProductData?.breadcrumb?.map((br, idx) => <Link className="text-decoration-none d-flex align-items-center gap-1" key={idx} to={`/products?category=${br.slug}`}>{br.title}
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"></path></svg>
                            </Link>)
                        }
                        {singleProductData?.result?.name}
                    </div> */}
                    <Row style={{ marginTop: "1rem" }}>
                        <Col className="position-sticky " md={7}>
                            <ImageGallery
                                singleProductData={singleProductData?.result}
                            />
                        </Col>
                        <Col md={5}>
                            <RightSidebar
                                singleProductData={singleProductData?.result}
                            />
                        </Col>
                    </Row>
                    <RelatedProducts brand={singleProductData?.result?.manufacturer}
                    />
                    <ProductReviews product={singleProductData?.result} />
                </Container>
            )}
        </div>
    );
};

export default ProductDetails;
