import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  products: [],
};

export const comboSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addToCombo: (state, action) => {
      const isExists = state.products.find(
        (product) => product?._id === action.payload._id
      );
      if (!isExists) {
        state.products.push(action.payload);
        toast.success("This product added combo");
      } else {
        toast.error("Already this product added");
      }
    },
  },
});

export const { addToCombo } = comboSlice.actions;
export default comboSlice.reducer;
