import React from "react";
import CategoryCarusel from "./ShopBy/CategoryCarusel";
import { useGetCategoryByTitleQuery } from "../../../redux/categories/categoriesApi";

const Routine = () => {
    const { data } = useGetCategoryByTitleQuery("category=routine");

    return <CategoryCarusel data={data?.data?.children} />;
};

export default Routine;
