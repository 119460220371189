import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { CgSearch } from "react-icons/cg";
import { MdOutlineShoppingBag } from "react-icons/md";
import { RiAccountCircleFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import theKoreanMallLogo from "../../../assets/images/thekoreanmall-logo.png";
import { userLoggedOut } from "../../../redux/auth/authSlice";
import { setCartDrawer } from "../../../redux/modal/modalSlice";
import SuggestionBar from "../../Ui/SuggestionBar/SuggestionBar";

const TopNavbar = ({ totalQuantity }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userEmail = useSelector((state) => state?.user?.user?.email);
  const { cartData } = useSelector((state) => state.cartData);
  //handle sign out
  const handleSignOut = async () => {
    localStorage.clear();
    dispatch(userLoggedOut());
    await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`);
  };

  // handleSearch
  const handleSearch = (e) => {
    e.preventDefault();
    if (e.target?.value?.length > 0) {
      const query = `products?search=${e.target.value}`;
      navigate(query);
    }
  };
  const quantity = cartData.reduce((acc, item) => acc + item.quantity, 0);

  //suggestion part

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  return (
    <>
      <p
        className="text-center text-white"
        style={{ backgroundColor: "crimson", fontSize: "0.875rem" }}
      >
        WELCOME TO THE KOREAN MALL
      </p>

      <div className="d-flex container justify-content-between w-100 px-3 align-items-center py-4">
        <SuggestionBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        ></SuggestionBar>
        {/* suggestion part */}

        <Navbar.Brand
          as={Link}
          to="/"
          onClick={() => clearSearchQuery()}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "-26px",
          }}
        >
          <img className="brand_logo_image" src={theKoreanMallLogo} alt="" />
        </Navbar.Brand>

        <CgSearch
          className="search_small_icon"
          style={{
            width: "25px",
            height: "22px",
            marginRight: "10px",
          }}
        />

        <div
          className={`d-flex gap-4 ${userEmail ? "" : "justify-content-end"}`}
          style={{ minWidth: "14rem" }}
        >
          {userEmail && (
            <Nav.Link
              as={Link}
              to="/account"
              className="d-flex align-items-center justify-content-center "
            >
              Account{" "}
              <RiAccountCircleFill
                className="ms-2"
                style={{ fontSize: "1rem" }}
              />
            </Nav.Link>
          )}
          <div className="d-flex align-items-center">
            {userEmail ? (
              <Nav.Link className="me-3" onClick={handleSignOut}>
                Logout
              </Nav.Link>
            ) : (
              <Nav.Link as={Link} to="/account/login" className="me-3">
                Login
              </Nav.Link>
            )}
            <div
              onClick={() => dispatch(setCartDrawer(true))}
              className="m-auto d-flex align-items-center justify-content-center fw-normal add_cart_parent"
              style={{ fontSize: "12px" }}
            >
              <MdOutlineShoppingBag
                style={{
                  width: "25px",
                  height: "22px",
                  cursor: "pointer",
                }}
              />
              <div className="add_cart">
                {userEmail ? (
                  <span>{totalQuantity || 0}</span>
                ) : (
                  <span>{quantity || 0}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNavbar;
