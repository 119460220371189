import React, { lazy, Suspense, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./components/auth/PrivateRoute";
import AddToCartOffcanvas from "./components/Cart/AddToCartOffcanvas";
import Loading from "./components/Ui/loading/Loading";
import VerificationEmail from "./components/Ui/VarificationEmail/VerificationEmail";
import AccountLayout from "./layouts/AccountLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import MyWishlist from "./pages/Account/MyWishlist";
import ViewAddresses from "./pages/Account/ViewAddresses";
import ViewOrders from "./pages/Account/ViewOrders";
import ViewReviews from "./pages/Account/ViewReviews";
import Cart from "./pages/Cart";
import ComboProducts from "./pages/comboProducts/ComboProducts";
import CongressPage from "./pages/congress/CongressPage";
import AboutUs from "./pages/Footer/AboutUs/AboutUs";
import ContactUs from "./pages/Footer/ContactUs/ContactUs";
import DeliveryInformation from "./pages/Footer/DeliveryInformation/DeliveryInformation";
import PrivacyPolicy from "./pages/Footer/PrivacyPolicy/PrivacyPolicy";
import SiteMap from "./pages/Footer/SiteMap";
import StoreLocation from "./pages/Footer/StoreLocation/StoreLocation";
import TermsAndConditions from "./pages/Footer/TermsAndConditions";
import NotFound from "./pages/NotFound/NotFound";
import ProductDetails from "./pages/ProductDetails";
import SearchingProducts from "./pages/SearchingProducts/SearchingProducts";
import ShopAll from "./pages/ShopAll/ShopAll";
import ShowProductByCategory from "./pages/ShowProductByCategory/ShowProductByCategory";
import ForgetPassword from "./pages/SignIn/ForgetPassword";
import SslCommerzPayment from "./pages/sslcommerz/SslCommerzPayment";
import BrandsProduct from "./pages/brandsProducts/BrandsProduct";
import SingleBlog from "./pages/blog/SingleBlog";
import AllBlogs from "./pages/blog/AllBlogs";
import Faq from "./pages/Footer/Faq/Faq";
import CancelOrder from "./pages/Account/CancelOrder/CancelOrder";
import ReturnOrder from "./pages/Account/ReturnOrder/ReturnOrder";
import AllBrands from "./pages/brands/AllBrands";

const Home = lazy(() => import("./pages/Home"));
const Checkout = lazy(() => import("./pages/Checkout"));
const SignIn = lazy(() => import("./pages/SignIn"));
const SignUp = lazy(() => import("./pages/SignUp"));
const MyAccount = lazy(() => import("./pages/Account/MyAccount"));
// const AllBrands = lazy(() => import("./pages/brands/AllBrands"));
const CampaignProduct = lazy(() =>
  import("./pages/campaignProducts/CampaignProducts")
);

const AppRouter = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="router-wrapper">
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading height={"100vh"} />}>
              <DefaultLayout setShow={setShow} />
            </Suspense>
          }
        >
          <Route index element={<Home setShow={setShow} />} />
          <Route path="home" element={<Home />} />
          <Route path="/product/brands" element={<AllBrands />} />

          <Route path="shop-all" element={<ShopAll />} setShow={setShow} />
          <Route
            path="checkout"
            element={
              <PrivateRoute>
                {/* <VerifyRoute> */}
                <Checkout />
                {/* </VerifyRoute> */}
              </PrivateRoute>
            }
          />
          <Route path="account/login" element={<SignIn />} />
          <Route path="/account/forget-password" element={<ForgetPassword />} />
          <Route path="account/register" element={<SignUp />} />
          <Route path="/cart" element={<Cart />} />
          <Route
            path="/congress"
            element={
              <PrivateRoute>
                <CongressPage />
              </PrivateRoute>
            }
          />
          <Route
            path="sslcommerz-payment"
            element={
              <PrivateRoute>
                <SslCommerzPayment />
              </PrivateRoute>
            }
          />
          <Route
            path="/products"
            element={<SearchingProducts setShow={setShow} />}
          />
          <Route
            path="product-detail/:id"
            element={<ProductDetails setShow={setShow} />}
          />

          <Route
            path="/verification"
            element={
              <PrivateRoute>
                <VerificationEmail />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/category/children/:id"
            element={<ShowProductByCategory show={show} setShow={setShow} />}
          />
          <Route
            path="combo-product"
            element={<ComboProducts show={show} setShow={setShow} />}
          />
          <Route path="brand-products/:brandId" element={<BrandsProduct />} />
          <Route path="campaign-products/:slug" element={<CampaignProduct />} />
          <Route path="offer-products" element={<CampaignProduct />} />
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="faq" element={<Faq />}></Route>
          <Route path="/store-location" element={<StoreLocation />}></Route>
          <Route
            path="/delivery-information"
            element={<DeliveryInformation />}
          ></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>

          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          ></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/sitemap" element={<SiteMap />}></Route>
          <Route path="/blog/:slug" element={<SingleBlog />}></Route>
          <Route path="/allBlogs/:all" element={<AllBlogs />}></Route>

          <Route path="*" element={<NotFound />}></Route>
        </Route>


        {/* account outlet routes start */}
        <Route
          path="/account"
          element={
            <Suspense
              fallback={
                <div
                  style={{ height: "100vh" }}
                  className=" w-100 d-flex align-items-center justify-content-center"
                >
                  <Spinner animation="grow" />
                </div>
              }
            >
              <PrivateRoute>
                <AccountLayout setShow={setShow} />
              </PrivateRoute>
            </Suspense>
          }
        >
          <Route index element={<MyAccount />} />
          <Route path="addresses" element={<ViewAddresses />} />
          <Route path="orders" element={<ViewOrders />} />
          <Route path="cancelled" element={<CancelOrder />} />
          <Route path="returned" element={<ReturnOrder />} />
          <Route path="reviews" element={<ViewReviews />} />
          <Route path="wishlist" element={<MyWishlist />} />
        </Route>
        {/* account outlet routes end */}

      </Routes>
      <AddToCartOffcanvas show={show} setShow={setShow} />

      <ToastContainer />
    </div>
  );
};

export default AppRouter;
