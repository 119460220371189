import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { setUrlParams } from "../../../config/generateUrlParam";

function PaginationButton({ totalPageNumber }) {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    let pageNumber = !totalPageNumber
        ? []
        : Array.from(
            { length: totalPageNumber },
            (v, k) => k + 1
        );

    const MAX_PAGES = 5;
    let limitedPageNumbers = [];

    if (pageNumber.length <= MAX_PAGES) {
        limitedPageNumbers = pageNumber;
    } else {
        const startIndex = Math.max(0, currentPage - Math.floor(MAX_PAGES / 2) - 1);
        const endIndex = Math.min(
            pageNumber.length - 1,
            startIndex + MAX_PAGES - 1
        );

        if (startIndex > 0) {
            limitedPageNumbers.push(1);
            if (startIndex > 1) {
                limitedPageNumbers.push('...');
            }
        }

        for (let i = startIndex; i <= endIndex; i++) {
            limitedPageNumbers.push(pageNumber[i]);
        }

        if (endIndex < pageNumber.length - 1) {
            if (endIndex < pageNumber.length - 2) {
                limitedPageNumbers.push('...');
            }
            limitedPageNumbers.push(pageNumber[pageNumber.length - 1]);
        }
    }

    return (
        <div className="d-flex justify-content-center my-2 container">
            <div className="btn-group">
                {limitedPageNumbers.map((page) => (
                    <button
                        key={page}
                        className={`btn   ${currentPage === page ? 'btn-dark' : 'border'
                            }`}
                        disabled={typeof page !== "number"}
                        onClick={() => {
                            const pageNumber =
                                typeof page === 'number' ? page : parseInt(page);
                            setCurrentPage(pageNumber);
                            if (page >= 0) {
                                navigate(
                                    setUrlParams(location.search || "?", 'page', page.toString())
                                );
                            }
                        }}
                    >
                        {page}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default PaginationButton;