import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Heading from "../../components/Ui/Heading";
import { useGetAllProductsBySearchQuery } from "../../redux/products/productsApi";
import FilterSection from "./FilterSection";
import SortingSection from "./SortingSection";
import ProductCard from "../../components/Ui/ProductCard/ProductCard";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useLayoutEffect } from "react";
import MobileFilterSection from "./MobileFilterSection";
import PaginationButton from "../../components/Ui/Button/PaginationButton";
import ProductLoading from "../../components/Ui/Loder/ProductLoading";
import UnderLine from "../../components/Ui/svg/UnderLine";

const SearchingProducts = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const size = useWindowSize();
  const [isSmallDevice, setSmallDevice] = useState();
  useLayoutEffect(() => {
    if (size.width < 992) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  }, [size]);

  const {
    data: products,
    isFetching: isLoading,
    isError,
  } = useGetAllProductsBySearchQuery(location.search);
  console.log(products?.result?.categoryData)

  let content = null;
  if (isLoading) {
    content = <ProductLoading />
  }
  if (!isLoading && isError) {
    content = (
      <div className="text-danger text-center">There is something wrong</div>
    );
  }
  if (!isLoading && !isError && products?.result?.data?.length === 0) {
    content = (
      <div>
        <h3 className="font-bold text-center">There is no search product</h3>
        <h5 className="font-bold text-center">Try to different way</h5>
      </div>
    );
  }
  if (!isLoading && !isError && products?.result?.data?.length > 0) {
    content =
      <div className="grid-container mbl-grid-container mt-4 ms-3">
        {products?.result?.data?.map((product) => (

          <ProductCard
            key={product?._id}
            setShow={setShow}
            cart={product}
            height={true}
            shortHeight={true}
          />

        ))}
      </div>
  }

  return (
    <Container>

      {
        products?.result?.categoryData?.title ? <div className="d-flex justify-content-center my-2 my-md-4">
          <div className="text-center" style={{ maxWidth: "760px" }} >
            <h1 style={{ fontSize: "2rem", fontWeight: "500" }} className="p-0 m-0">{products?.result?.categoryData.title}
              <h2 className="p-0 m-0">
                <UnderLine />
              </h2>
            </h1>
            <p style={{ fontSize: "1.2rem" }}>{products?.result?.categoryData.description}</p>
          </div>

        </div> : <div className="text-center mt-2 my-md-4"> <h1 style={{ fontSize: "1.5rem", fontWeight: "500" }} className="p-0 m-0">Searched Products</h1>
          <h2 className="p-0 m-0">
            <UnderLine />
          </h2>
        </div>
      }
      <Row className="g-2">
        {!isSmallDevice ? (
          <FilterSection />
        ) : (
          <MobileFilterSection
            handleShow={handleShow}
            show={show}
            setShow={setShow}
          />
        )}
        <Col xs={12} sm={12} md={10}>
          <div className={`d-flex  ${isSmallDevice ? "justify-content-center" : "justify-content-end"} px-md-4 my-2 align-items-center`}>
            <SortingSection isSmallDevice={isSmallDevice} />
            {isSmallDevice && <Button
              variant="outline border "
              onClick={handleShow}
              className="ms-1 px-4 d-flex gap-2 justify-content-center align-items-center"
              style={{ width: "80%" }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path d="M21 18V21H19V18H17V15H23V18H21ZM5 18V21H3V18H1V15H7V18H5ZM11 6V3H13V6H15V9H9V6H11ZM11 11H13V21H11V11ZM3 13V3H5V13H3ZM19 13V3H21V13H19Z"></path></svg>  FILTER & SORT
            </Button>}
          </div>
          {
            content
          }
        </Col>
      </Row>
      <div className="d-flex justify-content-center mt-4">

        <PaginationButton totalPageNumber={products?.result?.totalPageNumber} />
      </div>
    </Container>
  );
};

export default SearchingProducts;
