import React from 'react';

const Error = ({ children }) => {
    return (
        <div className='my-1' style={{ backgroundColor: "#fcdae2", border: "1px solid #fac8d4", color: "#8f2b43", borderRadius: ".25rem", padding: ".75rem 1.25rem" }}>
            {children}
        </div>
    );
};

export default Error;