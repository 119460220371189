import React from "react";
import Skeleton from "react-loading-skeleton";
import NoData from "../../../components/Ui/NoData";
import "react-loading-skeleton/dist/skeleton.css";
import { useGetAllOrdersByEmailQuery } from "../../../redux/addToCart/addToCartApi";
import { useSelector } from "react-redux";
import AllOrder from "../ViewOrders/AllOrder";

const ReturnOrder = () => {
    const userEmail = useSelector((state) => state?.user?.user?.email);
    const {
        data: orders,
        isLoading,
        isError,
    } = useGetAllOrdersByEmailQuery({ userEmail, status: "returned" });


    let content = null;
    if (isLoading) {
        content = <Skeleton count={3} />;
    }
    if (!isLoading && isError) {
        content = <p className="text-center text-danger">There was en error</p>;
    }
    if (!isLoading && !isError && !orders?.products?.length) {
        content = <NoData />;
    }
    if (!isLoading && !isError && orders?.length > 0) {


        content = orders?.map((order) => <AllOrder order={order} />);
    }

    return (
        <>
            <div>
                <h2 className="text-start">View Orders</h2>
                <div className="doted-container"></div>
            </div>
            {content}
        </>
    );
};

export default ReturnOrder;
