import React from 'react';
import { useGetProductsByTypeQuery } from '../../redux/products/productsApi';
import { Container } from 'react-bootstrap';
import CategorizedProductCarousel from '../../components/Home/CategorizedProductCarousel';

const PopularProducts = () => {
    const {
        data: popularProducts,
        isLoading,
        isError,
    } = useGetProductsByTypeQuery("popular-product");
    return (
        <Container>
            {" "}
            {
                <CategorizedProductCarousel
                    title={"Popular Products"}
                    productList={popularProducts?.result}
                    isLoading={isLoading}
                    isError={isError}
                />
            }
        </Container>
    );
};

export default PopularProducts;