import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addressModal: false,
  billingModal: { active: false, id: "" },
  subCategoryModal: false,
  menuModal: false,
  cartDrawer: false,
  mobileModal: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setMenuModal: (state, action) => {
      state.menuModal = action.payload;
    },
    setSubCategoryModal: (state, action) => {
      state.menuModal = action.payload;
    },
    showAddressModal: (state, action) => {
      state.addressModal = action.payload
    },
    showBillingModal: (state, action) => {
      state.billingModal.active = action.payload.active;
      state.billingModal.id = action.payload.id;
    },
    setCartDrawer: (state, action) => {
      state.cartDrawer = action.payload;
    },
    setResponsiveMobileModal: (state, action) => {
      state.mobileModal = action.payload;
    },
  },
});

export const {
  setMenuModal,
  showAddressModal,
  showBillingModal,
  setSubCategoryModal,
  setCartDrawer,
  setResponsiveMobileModal,
} = modalSlice.actions;
export default modalSlice.reducer;
