import Heading from "../../Ui/Heading";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SwiperCarousel from "../../Ui/SwiperCarousel/SwiperCarousel";

const CategorizedProductCarousel = ({
  title,
  productList,
  setShow,
  isLoading,
  isError,
} = {}) => {
  return (
    <div className="mb-5">
      <Heading className={"container text-center"}>{title}</Heading>
      {isLoading && <Skeleton count={5} />}
      {isError && (
        <p className="text-center text-danger fs-3">
          There are something wrong!..
        </p>
      )}
      <SwiperCarousel setShow={setShow} productList={productList} />
    </div>
  );
};

export default CategorizedProductCarousel;
