import { apiSlice } from "../api/apiSlice";

export const categoriesApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        //get all  category
        getAllCategory: builder.query({
            query: () => ({
                url: `/category`,
                method: "POST",
            }),
        }),

        //get breadcrumb category
        getBreadCrumbCategory: builder.query({
            query: () => ({
                url: `/category/breadcrumb`,
                method: "POST",
            }),
        }),

        //get all  category
        getAllWeight: builder.query({
            query: () => ({
                url: `/size`,
                method: "POST",
            }),
        }),

        //get shop by  category
        getShopByCategory: builder.query({
            query: () => ({
                url: `/category/shop-by`,
                method: "POST",
            }),
        }),

        //get category by title
        getCategoryByTitle: builder.query({
            query: (query) => ({
                url: `/category/filter?${query}`,
                method: "POST",
            }),
        }),

        //get all  brands => manufacturer
        getAllBrands: builder.query({
            query: () => ({
                url: `/manufacturer`,
                method: "POST",
            }),
        }),
        //get all  brands => manufacturer
        getAllSortedBrands: builder.query({
            query: () => ({
                url: `/manufacturer/sort-brands`,
                method: "POST",
            }),
        }),

        //get category by id
        getCategoryById: builder.query({
            query: (id) => ({
                url: `/category/${id}`,
                method: "POST",
            }),
        }),
    }),
});

export const {
    useGetAllCategoryQuery,
    useGetBreadCrumbCategoryQuery,
    useGetAllWeightQuery,
    useGetCategoryByIdQuery,
    useGetShopByCategoryQuery,
    useGetAllBrandsQuery,
    useGetAllSortedBrandsQuery,
    useGetCategoryByTitleQuery,
} = categoriesApi;
