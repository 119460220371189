import React from "react";
import { useState } from "react";
import { Col, Container, Pagination, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import Heading from "../../components/Ui/Heading";
import ProductCard from "../../components/Ui/ProductCard/ProductCard";
import { useGetCategoryByIdQuery } from "../../redux/categories/categoriesApi";
import { useGetAllProductsByCategoryQuery } from "../../redux/products/productsApi";

const ShowProductByCategory = ({ show, setShow }) => {
    const { id } = useParams();

    const {
        data: allCategoryData,
        isLoading: categoriesLoading,
        isError: categoriesError,
    } = useGetAllProductsByCategoryQuery(id);
    const { data: category } = useGetCategoryByIdQuery(id);

    let content = null;
    if (categoriesLoading) {
        content = <Skeleton count={4} />;
    }
    if (!categoriesLoading && categoriesError) {
        content = <p className="text-red fs-4">There was an error.</p>;
    }
    if (
        !categoriesLoading &&
        !categoriesError &&
        allCategoryData?.result.length === 0
    ) {
        content = (
            <p className="text-center m-auto fs-4 text-primary">
                There is no product this type.
            </p>
        );
    }
    if (
        !categoriesLoading &&
        !categoriesError &&
        allCategoryData?.result.length > 0
    ) {
        content = allCategoryData?.result?.map((cart) => (
            <Col key={cart?._id} className="mb-4">
                <ProductCard cart={cart} show={show} setShow={setShow} />
            </Col>
        ));
    }

    const [activeValue, setActiveValue] = useState(1);
    let active = activeValue;
    let items = [];
    for (let number = 1; number <= 5; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === active}
                onClick={() => setActiveValue(number)}
            >
                {number}
            </Pagination.Item>
        );
    }
    return (
        <Container>
            <Heading className="text-center my-3">
                {category?.result?.title}
            </Heading>

            <Row md={5} className="">
                {content}
            </Row>
            <div className="d-flex justify-content-center mt-4">
                <Pagination>{items}</Pagination>
            </div>
        </Container>
    );
};

export default ShowProductByCategory;
