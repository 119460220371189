
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CategorizedProductCarousel from "../../components/Home/CategorizedProductCarousel";
import {
    useGetBrandsProductsByBrandIdQuery,
    useGetRelatedProductsByProductIdQuery,
} from "../../redux/products/productsApi";

const RelatedProducts = ({ brand }) => {
    const { id } = useParams()
    const { data: brandProducts } = useGetBrandsProductsByBrandIdQuery(
        { brandId: brand._id, page: "?" }, { skip: !brand }
    );
    const { data: relatedProduct } = useGetRelatedProductsByProductIdQuery(id);
    console.log("brandProducts", brandProducts)
    return (
        <>
            <Container>
                <CategorizedProductCarousel
                    title={"You Might Also Like"}
                    productList={relatedProduct?.result}
                />
                <CategorizedProductCarousel
                    title={"More Products From This Brand"}
                    productList={brandProducts?.result}
                />
            </Container>
        </>
    );
};
export default RelatedProducts;
