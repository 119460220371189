import React from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../../Home/blog/blog.scss"

const BlogCard = ({ blog }) => {
    const { blogTitle, image, description, slug } = blog || {};

    return (


        <Card className="w-100 p-1 blog-container border-0 rounded-0">
            <Image

                // height={200}
                variant="top"
                src={image}
                alt={slug}
                className="rounded-0 blog-img mb-2"
            />

            <Card.Body className="p-0 ">
                <Card.Title className="blog-title">
                    {blogTitle}
                </Card.Title>
                <p className="lh-sm ">
                    <div
                        className="blog-description"
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></div>
                </p>

                <div className='text-center mt-2'>
                    <Link
                        to={`/blog/${slug}`}
                        className="mt-4 text-decoration-none "
                    >
                        <Button
                            style={{
                                letterSpacing: "0.1rem",
                                fontSize: "12px",
                                borderRadius: "2px",
                                padding: "6px 20px",
                            }}
                            variant="outline-danger"
                            className="btn "
                        >
                            READ MORE
                        </Button>
                    </Link>
                </div>
            </Card.Body>
        </Card>

    );
};

export default BlogCard;