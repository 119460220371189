import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./CongressPage.scss";
import congress from "../../../src/assets/illustrations/congressImage.svg";
import { Link } from "react-router-dom";
const CongressPage = () => {
  return (
    <Container style={{ height: "80vh" }} className="w-100">
      <Row className="d-flex flex-row ">
        <Col
          xs={12}
          md={6}
          lg={6}
          xl={6}
          className="d-flex justify-content-center align-items-center mt-5 order-2 order-md-1"
        >
          <div className="text-center">
            <h1 className="fs-2 ">
              <span className="text-primary">Congrats!</span> Your Order is
              successfully booked!
            </h1>

            <Button as={Link} to="/" variant="dark">
              Go to home
            </Button>
          </div>
        </Col>
        <Col xs={12} md={6} lg={6} xl={6} className=" order-1 order-md-2">
          <Image src={congress} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default CongressPage;
