import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteWishListDataByEmailIdMutation } from "../../../redux/wishList/wishListApi";
import { useEffect } from "react";
import SmallLoader from "../../../components/Ui/loading/SmallLoader";
import { calculateDiscount } from "../../../config/calculateDiscount";

const SingleWishList = ({ wishList, userEmail }) => {
  // wishList delete hook redux
  const [deleteWishListDataByEmailId, { isSuccess, isError, isLoading }] =
    useDeleteWishListDataByEmailIdMutation();

  // handle delete wishlist
  const handleDeleteWishList = (id) => {
    const data = { productId: id };

    deleteWishListDataByEmailId({ userEmail, data });
  };

  useEffect(() => {
    isSuccess && toast.success("Delete wishlist item successfully");
    isError && toast.error("Delete failed");
  }, [isSuccess, isError]);
  const discountPrice = calculateDiscount(wishList)
  return (
    <Row className="">
      <Col xs={4} md={2}>
        <Image src={wishList.images?.[0]} fluid />
      </Col>
      <Col xs={6} md={8} className="d-flex flex-column justify-content-center">
        <Link
          to={`/product-detail/${wishList?.slug}`}
          className="order_name text-decoration-none"
        >
          {wishList?.name}
        </Link>
        <small>Model: {wishList?.model}</small>
        <small>Price: {discountPrice}</small>
      </Col>
      <Col xs={2} md={2} className="d-flex justify-content-center">
        <button
          onClick={() => handleDeleteWishList(wishList?._id)}
          className="border-0 bg-transparent p-2 "
        >
          {" "}
          {isLoading ? <SmallLoader /> : <AiOutlineDelete size={18} />}
        </button>
      </Col>
    </Row>
  );
};

export default SingleWishList;
