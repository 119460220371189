import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { ImYoutube } from "react-icons/im";
import { IoMdHeart } from "react-icons/io";
import { TiSocialFacebook } from "react-icons/ti";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { calculateDiscount } from "../../../config/calculateDiscount";
import { useAddToCartNewDataByEmailMutation } from "../../../redux/addToCart/addToCartApi";
import { addToCart } from "../../../redux/addToCart/addToCartSlice";
import { useAddToWishListByEmailMutation } from "../../../redux/wishList/wishListApi";
import AccordionSection from "./AccordionSection";
import "./AccordionSection.scss";
import SideBarCountDown from "./SideBarCountDown";
import { setCartDrawer } from "../../../redux/modal/modalSlice";
import SmallLoader from "../../Ui/loading/SmallLoader";
import { useNavigate } from "react-router-dom";

const reviewTotal = (review) => {
    switch (review) {
        case 0:
            return "No reviews";
        case 1:
            return "One review";

        default:
            return `${review} reviews`;
    }
};

const RightSidebar = ({ setShow, singleProductData }) => {
    const userEmail = useSelector((state) => state?.user?.user?.email);
    const [cartInputValue, setCartInputValue] = useState(1);
    const navigate = useNavigate();

    //::::: add to wishlist data redux hook ::::://
    const [
        addToWishListByEmail,
        {
            isLoading: wishListLoading,
            isSuccess: wishListSuccess,
            error: wishListError,
        },
    ] = useAddToWishListByEmailMutation();

    const { name, reviews, price, tags, manufacturer, weight, timeStamps, offer } =
        singleProductData || {};
    const { timeStamps: offerTimeStamps } = offer || {};

    const dispatch = useDispatch();

    const [addToCartNewDataByEmail, { isLoading, isError, isSuccess }] =
        useAddToCartNewDataByEmailMutation();
    const priceAfterDiscount = calculateDiscount(singleProductData);

    const addToCartProduct = () => {
        if (userEmail) {
            const data = {
                product: singleProductData._id,
                quantity: cartInputValue,
                offerPrice: priceAfterDiscount,
            };

            addToCartNewDataByEmail({ userEmail, data });
        } else {
            dispatch(
                addToCart({
                    product: singleProductData,
                    qty: cartInputValue,
                })
            );
        }
        dispatch(setCartDrawer(true))
    };

    useEffect(() => {
        isSuccess && toast.success("Product added successfully");
        isError && toast.error("Product could not be added to cart");
        wishListError &&
            toast.error(wishListError?.data.message || "Already added to wishlist", { id: "wError" });
        wishListSuccess &&
            toast.success("Added wish list successfully", { id: "wSuccess" });
    }, [isError, isSuccess, wishListSuccess, wishListError]);


    // add to wishlist handler
    const handleWishList = (productId) => {
        const data = { product: productId };

        if (userEmail) {
            addToWishListByEmail({ userEmail, data });
        } else {
            navigate("/account/login")

        }
    };

    return (
        <>
            <div className="prod-text-sec" >
                <p className="fw-bold">{manufacturer?.name}</p>
                <h2>{name}</h2>

                <div
                    className="prod-rating-dvi"
                    style={{
                        display: "flex",
                        columnDirection: "row",
                        alignItem: "center",
                        marginBottom: "10px",
                    }}
                >
                    <div
                        style={{ fontSize: "1.25rem" }}
                        className="d-flex gap-2 align-items-center"
                    >
                        <ReactStars
                            count={5}
                            edit={false}
                            size={24}
                            activeColor="#ffb639"
                            value={singleProductData?.averageRating?.$numberDecimal || 5}
                        />
                        <span style={{ fontSize: "0.75rem" }}>
                            ({reviewTotal(reviews ? reviews.length : 0)})
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        border: "0",
                        borderColor: "#ccc",
                        borderBottomWidth: "1px",
                        borderBottomStyle: "dotted",
                    }}
                />
                <div className="mt-3">
                    <p
                        style={{
                            fontSize: "2rem",
                            color: "#333",
                            fontWeight: "700",
                        }}
                    >
                        {priceAfterDiscount < price ? (
                            <span className="d-flex align-items-center gap-2">
                                ৳{priceAfterDiscount}{" "}
                                <strike
                                    style={{ color: "#ccc", fontSize: "14px" }}
                                    className=" text-secondary"
                                >
                                    ৳{price}
                                </strike>
                                <span style={{ fontSize: "1.5rem" }}>
                                    {weight}
                                </span>
                            </span>
                        ) : (
                            <span>
                                ৳{price}{" "}
                                <span style={{ fontSize: "1.5rem" }}>
                                    {weight}
                                </span>
                            </span>
                        )}{" "}
                    </p>
                </div>
                {/* input field */}
                <Row className="my-3" style={{ paddingLeft: "12px" }}>
                    <Col xs={4} className="position-relative border">
                        <Form.Control
                            type="number"
                            min={1}
                            max={5}
                            value={cartInputValue}
                            required
                            onChange={(e) => {
                                if (e.target.value > 5) {
                                    setCartInputValue(5);
                                } else {
                                    setCartInputValue(e.target.value);
                                }
                            }}
                            className="border-0 shadow-none w-50 px-2"
                        />
                        <div
                            style={{
                                right: "0",
                                transform: "translate(-50%, -50%)",
                            }}
                            className="position-absolute d-flex justify-content-center align-items-center  top-50 gap-2"
                        >
                            <button
                                onClick={() => {
                                    if (cartInputValue <= 1) {
                                        setCartInputValue(1);
                                    } else {
                                        setCartInputValue(
                                            (prev) => parseInt(prev) - 1
                                        );
                                    }
                                }}
                                className="border-0  rounded-circle p-1 d-flex justify-content-center align-items-center"
                            >
                                <AiOutlineMinus />
                            </button>
                            <button
                                onClick={() => {
                                    if (cartInputValue >= 5) {
                                        setCartInputValue(5);
                                    } else {
                                        setCartInputValue(
                                            (prev) => parseInt(prev) + 1
                                        );
                                    }
                                }}
                                className="border-0  rounded-circle p-1 d-flex justify-content-center align-items-center"
                            >
                                <AiOutlinePlus />
                            </button>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <button
                            onClick={() => addToCartProduct(singleProductData)}
                            className={" shadow-none border-0 w-100 py-2 "}
                            style={{
                                background: "#000",
                                color: "white",
                                fontSize: "18px",
                            }}
                        >
                            <span>
                                {!isLoading
                                    ? ` Add to Bag`
                                    : "Adding to Bag ..."}
                            </span>
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={3}
                        className="d-flex  align-items-center gap-2 fs-4"
                    >
                        <button className="my_btn youtube">
                            <ImYoutube />
                        </button>
                        <button className="my_btn facebook">
                            <TiSocialFacebook />
                        </button>
                        <button className="my_btn instagram">
                            <FaInstagram />
                        </button>
                        <button
                            onClick={() =>
                                handleWishList(singleProductData?._id)
                            }
                            className="my_btn wishList"
                        >
                            {wishListLoading ? <SmallLoader /> : <IoMdHeart />}
                        </button>
                    </Col>
                </Row>
                {/* count down */}
                {(timeStamps || offerTimeStamps) && <SideBarCountDown timeStamps={timeStamps || offerTimeStamps} />}
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "4px",
                        marginTop: "1.5rem",
                        marginBottom: "1rem",
                    }}
                >
                    {tags
                        ? tags?.map((tag, _idx) => (
                            <span
                                key={_idx}
                                style={{
                                    backgroundColor: "#F5F5F5",
                                    padding: "2px 6px",
                                    fontSize: "0.85rem",
                                }}
                            >
                                {tag}
                            </span>
                        ))
                        : ""}
                </div>
                {singleProductData?.description && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: singleProductData?.description,
                        }}
                    />
                )}

                <AccordionSection product={singleProductData} />
            </div>
        </>
    );
};

export default RightSidebar;
