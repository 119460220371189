import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showBillingModal } from '../../../redux/modal/modalSlice';
import { Col } from 'react-bootstrap';
import { TbPhoneCall } from 'react-icons/tb';
import { BiHome } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { useDeleteAddressMutation, useSetActiveAddressMutation } from '../../../redux/address/addressApi';
import { useState } from 'react';

const SingleAddress = ({ address }) => {
    const { email } = useSelector((state) => state.user.user) || {};
    const dispatch = useDispatch();
    const [selected, setSelected] = useState();

    const [setActiveAddress, { isLoading: activeLoading }] =
        useSetActiveAddressMutation();
    const [deleteAddress] =
        useDeleteAddressMutation();



    //handle select
    const handleSelect = (id) => {
        setSelected(id);
    };

    // handle confirm
    const handleSetActive = (id) => {
        setActiveAddress({ id: id, email: email });
    };
    const handleDelete = (id) => {
        deleteAddress({ id: id, email: email });
    };
    const handleEdit = (id) => {
        dispatch(showBillingModal({ active: true, id: id }));
    };


    return (
        <Col sm={6} key={address?._id} onClick={() => handleSelect(address?._id)}>
            <div
                style={{ cursor: "pointer", minWidth: "200px" }}
                className={` ${selected === address?._id ? "selected-address" : "default-select"
                    } rounded p-0 mb-1`}
            >
                <div className="d-flex justify-content-between px-2 pt-2 mb-2 rounded">
                    <div>
                        <div
                            className="fw-bold  d-flex justify-content-between"
                            style={{ fontSize: ".9rem" }}
                        >
                            <p>{address?.shippingName}</p>
                            <small
                                className="text-primary"
                                onClick={() => handleEdit(address._id)}
                            >
                                Edit
                            </small>
                        </div>

                        <p
                            className="fw-bold"
                            style={{ fontSize: ".74rem", lineHeight: "1rem" }}
                        >
                            <TbPhoneCall color="blue" className="me-1" />
                            {address?.shippingPhone}
                        </p>
                        <p
                            className="fw-bold"
                            style={{ fontSize: ".74rem", lineHeight: "1rem" }}
                        >
                            <BiHome color="blue" /> House No: {address?.address}
                        </p>
                        <p
                            className="fw-bold"
                            style={{ fontSize: ".74rem", lineHeight: "1rem" }}
                        >
                            {address?.district},{address?.upazila}
                        </p>
                    </div>
                </div>
                {address?.selected ? (
                    <div className="d-flex justify-content-end ">
                        <button
                            className="border-0 px-2 py-1 me-2 mb-1 rounded text-light bg-success  fw-bold"
                            style={{ fontSize: ".67rem" }}
                        >
                            Selected
                        </button>
                    </div>
                ) : (
                    <div className="d-flex justify-content-end ">
                        <button
                            onClick={() => handleDelete(address?._id)}
                            className="border-0 px-2 py-1 me-2 mb-1 rounded text-light bg-danger  fw-bold"
                            style={{ fontSize: ".67rem" }}
                        >
                            <MdDelete className="fs-6" />
                        </button>

                        {/* confirm button */}
                        {selected === address?._id && (
                            <button
                                onClick={() => handleSetActive(address?._id)}
                                form="billingForm"
                                className="border-0 px-2 py-1 me-2 mb-1 rounded text-light bg-primary  fw-bold"
                                style={{ fontSize: ".67rem" }}
                                type="button"
                            >
                                {activeLoading ? "Saving.." : "Save"}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </Col>
    );
};

export default SingleAddress;