import React from 'react';
import "./ProductLoding.scss"
import { Col, Container, Row } from 'react-bootstrap';


const SliderProductLoading = () => {
    return (
        <section>
            <Container className='d-flex justify-content-center align-item-center my-5' >
                <Row>
                    <Col md={3} xs={6}>
                        <div className="loading-container" style={{ wid: "200px" }}>
                            <div className="loading-product" style={{ height: "200px", width: "150px" }} />
                            <div className="loading-text" style={{ width: "150px" }} />
                            <div className="loading-text" style={{ width: "150px" }} />
                        </div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className="loading-container" style={{ wid: "200px" }}>
                            <div className="loading-product" style={{ height: "200px", width: "150px" }} />
                            <div className="loading-text" style={{ width: "150px" }} />
                            <div className="loading-text" style={{ width: "150px" }} />
                        </div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className="loading-container" style={{ wid: "200px" }}>
                            <div className="loading-product" style={{ height: "200px", width: "150px" }} />
                            <div className="loading-text" style={{ width: "150px" }} />
                            <div className="loading-text" style={{ width: "150px" }} />
                        </div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className="loading-container" style={{ wid: "200px" }}>
                            <div className="loading-product" style={{ height: "200px", width: "150px" }} />
                            <div className="loading-text" style={{ width: "150px" }} />
                            <div className="loading-text" style={{ width: "150px" }} />
                        </div>
                    </Col>




                </Row>
            </Container>

        </section>



    );
};

export default SliderProductLoading;