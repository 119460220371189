import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Accordion, Button, Col, Form } from "react-bootstrap";
import { setUrlParams } from "../../config/generateUrlParam";
import { AiFillCaretRight } from "react-icons/ai";
import { useRef } from "react";
import "./SearchingProducts.scss";
import {
  useGetAllCategoryQuery,
  useGetAllWeightQuery,
  useGetShopByCategoryQuery,
} from "../../redux/categories/categoriesApi";
import "./FilterSection.scss";
const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
const FilterSection = () => {
  const minPriceRef = useRef();
  const maxPriceRef = useRef();
  const searchUrl = useLocation().search || "?";
  const [selectWeight, setSelectedWeight] = useState("");
  const navigate = useNavigate();

  // hooks
  const { data: allCategory } = useGetAllCategoryQuery();
  const { data: allWeight } = useGetAllWeightQuery();
  const { data: shopBy } =
    useGetShopByCategoryQuery();

  const handleUncheckAll = () => {
    setSelectedWeight("");
    minPriceRef.current.value = "";
    maxPriceRef.current.value = "";

    navigate("/products");
    goToTop();
  };

  const handlePriceSearch = (e) => {
    e.preventDefault();
    const string = `${minPriceRef.current.value}-${maxPriceRef.current.value}`;
    const url = setUrlParams(searchUrl, "price", string);
    navigate(url);
    goToTop();
  };


  return (
    <Col xs={12} sm={12} md={2} className="my-2">
      <div className="d-flex  align-items-center mb-2 fw-bold justify-content-between ">
        <small>Filter Section</small>
        <button
          className="btn  text-info "
          onClick={handleUncheckAll}
        >
          <small className="fw-bold ">Clear All</small>
        </button>
      </div>
      <hr />

      {/* Category Filter */}
      <div>
        <Accordion className="">
          <Accordion.Item eventKey="0">
            <Accordion.Header
              className="shadow-none  "
              style={{ lineHeight: "0px" }}
            >
              All Categories
            </Accordion.Header>
            <Accordion.Body className="pt-0 mt-0 ms-4">
              {allCategory?.result?.map((category) => (
                <p
                  onClick={() => {
                    navigate(`/products?category=${category.slug}`);
                    goToTop();
                  }}
                  className={
                    searchUrl.includes(category?.slug)
                      ? "text-danger fw-bold"
                      : "filter-category"
                  }
                  key={category?._id}
                >
                  <span className="filter-font">{category?.title}</span>
                </p>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      {/* shop by Filter */}
      <div>
        {shopBy?.data?.children?.map((subCategory, index) => (
          <Accordion className="">
            <Accordion.Item eventKey={index}>
              <Accordion.Header
                className="shadow-none"
                style={{ lineHeight: "0px" }}
              >
                {subCategory?.title}
              </Accordion.Header>
              <Accordion.Body className="pt-0 mt-0 ms-4">
                {subCategory?.children?.map((child) => (
                  <p
                    onClick={() => {
                      navigate(`/products?category=${child.slug}`);
                      goToTop();
                    }}
                    className={
                      searchUrl.includes(child?.slug)
                        ? "text-danger fw-bold"
                        : "filter-category"
                    }
                    key={child?._id}
                  >
                    <span className="filter-font">{child?.title}</span>
                  </p>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </div>

      {/* Weight filter */}
      <div>
        <Accordion className="">
          <Accordion.Item eventKey="0">
            <Accordion.Header
              className="shadow-none"
              style={{ lineHeight: "0px" }}
            >
              Weight
            </Accordion.Header>
            <Accordion.Body className="pt-0 mt-0 ms-4">
              {allWeight?.data?.map((size) => (
                <Form.Check
                  className="filter-font"
                  label={size.name}
                  name="group1"
                  type={"radio"}
                  checked={selectWeight === size.name}
                  onClick={() => {
                    setSelectedWeight(size.name);
                    navigate(setUrlParams(searchUrl, "weight", size.name));
                    goToTop();
                  }}
                  id={size.name}
                  value={size.name}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      {/* Price filter */}
      <div>
        <p className="my-2 fw-bold">Price:</p>
        <Form onSubmit={handlePriceSearch}>
          <div className="d-flex gap-1">
            <Form.Control
              placeholder="min"
              className="priceInputField "
              type="number"
              ref={minPriceRef}
            />
            <Form.Control
              placeholder="max"
              className="priceInputField"
              type="number"
              ref={maxPriceRef}
            />
            <button
              type="submit"
              style={{ width: "2rem" }}
              className="border-0 bg-primary text-white d-flex align-items-center justify-content-center"
            >
              <AiFillCaretRight />
            </button>
          </div>
        </Form>
      </div>
    </Col>
  );
};

export default FilterSection;
