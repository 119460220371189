import React from 'react';

export default function OrderSVG() {
	return (
		<svg
			className="text-lime-950 cursor-pointer align-middle text-truncate"
			fill="none"
			stroke="currentColor"
			width={20}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			style={{
				stroke: 'rgb(32, 34, 2)',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				width: '100%',
				height: '100%',
			}}
			viewBox="0 0 24 24"
		>
			<path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
			<line x1="3" x2="21" y1="6" y2="6" />
			<path d="M16 10a4 4 0 0 1-8 0" />
		</svg>
	);
}
