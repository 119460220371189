import React from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useCheckCouponIsValidateMutation } from "../../redux/coupons/couponApi";
import { useSelector } from "react-redux";

const CheckCoupon = ({ cartId }) => {
    const userEmail = useSelector((state) => state.user?.user?.email);

    // check coupon is validate
    const [
        checkCouponIsValidate,
        { isLoading: couponLoading, isSuccess, error },
    ] = useCheckCouponIsValidateMutation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleCheckCoupon = (data) => {
        // make the data for send to server
        const modifiedData = {
            couponCode: data.couponCode,
            cartId: cartId,
            email: userEmail,
        };

        checkCouponIsValidate(modifiedData);
    };

    return (
        <>
            <Form
                className="d-flex align-items-center"
                onSubmit={handleSubmit(handleCheckCoupon)}
            >
                <FloatingLabel
                    controlId="discountCodeInput"
                    label="Gift card or discount code"
                    className="me-3 w-100"
                >
                    <Form.Control
                        type="text"
                        placeholder="Enter your code"
                        className="shadow-none"
                        style={{ maxHeight: "3rem" }}
                        {...register("couponCode", {
                            required: {
                                value: true,
                            },
                        })}
                    />
                </FloatingLabel>
                <Button
                    variant="dark"
                    type="submit"
                    className="shadow-none"
                    style={{
                        width: "5.625rem",
                        height: "2.875rem",
                        fontSize: "1.125rem",
                        borderRadius: "4px",
                    }}
                >
                    {couponLoading ? "Applying" : "Apply"}
                </Button>
            </Form>

            {error && (
                <p className="text-danger my-1">{error?.data?.message}</p>
            )}
            {isSuccess && (
                <p className="text-success my-1">
                    Success! Your coupon is activate.
                </p>
            )}
        </>
    );
};

export default CheckCoupon;
