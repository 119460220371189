import React from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { setUrlParams } from "../../config/generateUrlParam";
import "./SearchingProducts.scss";

const sortOptions = [
  { _id: "1", name: "Default sorting", value: "" },
  { _id: "2", name: "Newest product", value: "newest" },
  { _id: "3", name: "Price low to high", value: "price-asc" },
  { _id: "4", name: "Price high to low", value: "price-desc" },
];
const showOptions = [
  { _id: "1", name: "Show 12", value: 12 },
  { _id: "2", name: "Show 24", value: 24 },
  { _id: "3", name: "Show 36", value: 36 },
  { _id: "4", name: "Show 50", value: 50 },
];
const SortingSection = ({ isSmallDevice }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Form.Select
        aria-label="Default select example"
        className={`select-sorting me-2 shadow-none rounded-0 ${isSmallDevice && "d-none"}`}
        style={{ fontSize: "0.9em" }}
        onChange={(e) => {
          const url = setUrlParams(
            location.search || "?",
            "sort",
            e.target.value
          );
          navigate(url);
        }}
      >
        {sortOptions.map((option) => (
          <option className="sort-font   " value={option.value}>
            {option.name}
          </option>
        ))}
      </Form.Select>
      <Form.Select
        aria-label="Default select example"
        className={`select-sorting me-2 shadow-none rounded-0 ${isSmallDevice && "d-none"}`}
        style={{ fontSize: "0.9em" }}
        onChange={(e) => {
          const url = setUrlParams(
            location.search || "?",
            "limit",
            e.target.value
          );
          navigate(url);
        }}
      >
        {showOptions.map((option) => (
          <option className="sort-font" key={option._id} value={option.value}>
            {option.name}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default SortingSection;
