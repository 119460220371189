import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './AppRouter';
import './styles/App.scss';

// import MessengerCustomerChat from "react-messenger-customer-chat";
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuthCheck from './hooks/useAuthCheck';
import { setInitialState } from './redux/addToCart/addToCartSlice';
import { userLoggedIn } from './redux/auth/authSlice';
import BookingModal from './components/Ui/Modal/AddressModal';
import BillingModal from './components/Ui/Modal/BillingModal';
import { useMergeDataFromLocalStorageMutation } from './redux/addToCart/addToCartApi';

const App = () => {
	const authChecked = useAuthCheck();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);
	const [mergeDataFromLocalStorage, { isSuccess }] =
		useMergeDataFromLocalStorageMutation();

	useEffect(() => {
		const getUser = () => {
			axios
				.get(`${process.env.REACT_APP_API_URL}/auth/login/success`, {
					withCredentials: true,
				})
				.then((res) => {
					if (res.data) {
						localStorage.setItem(
							'auth',
							JSON.stringify({
								accessToken: res.data?.token,
								user: res.data?.user,
							})
						);

						dispatch(
							userLoggedIn({
								accessToken: res.data?.token,
								user: res.data?.user,
							})
						);
					}
				});
		};
		getUser();
	}, [dispatch]);

	useEffect(() => {
		const storedItemsString = localStorage.getItem('shopping_cart');
		const storedItems = storedItemsString ? JSON.parse(storedItemsString) : [];
		dispatch(setInitialState(storedItems));
	}, [dispatch]);

	useEffect(() => {
		const storedItemsString = localStorage.getItem('shopping_cart');
		const storedItems = storedItemsString ? JSON.parse(storedItemsString) : [];

		if (user?.email && storedItems.length > 0) {
			const data = storedItems.map((item) => ({
				product: item.product._id,
				quantity: item.quantity,
				size: item.product.size,
				// offerPrice: item.product.offerPrice,
			}));

			mergeDataFromLocalStorage({ email: user.email, data });
		}
	}, [user, mergeDataFromLocalStorage]);

	useEffect(() => {
		if (isSuccess) {
			localStorage.removeItem('shopping_cart');
			dispatch(setInitialState([]));
		}
	}, [isSuccess, dispatch]);

	return !authChecked ? (
		<div>Checking Authentication</div>
	) : (
		<>
			<AppRouter></AppRouter>
			{/* modal */}
			<BookingModal />
			<BillingModal />

		</>
	);
};

export default App;
