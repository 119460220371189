import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "./singleBlog.scss"
import { ImFacebook2 } from "react-icons/im";
import { FaTwitter } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { Link, useParams } from 'react-router-dom';
import { useGetRecentBlogsQuery, useGetSingleBlogQuery } from '../../redux/blog/blogApi';
import ProductLoading from '../../components/Ui/Loder/ProductLoading';


const SingleBlog = () => {
    const { slug } = useParams();
    const { data: blog, isLoading } = useGetSingleBlogQuery(slug);
    const { data: recentBlog, isLoading: recentLoading } = useGetRecentBlogsQuery();
    let content = null;
    if (isLoading) {
        content = <div>
            <div className='loading-product mb-1' style={{ height: "5vh", width: "100%" }}></div>
            <div className='loading-product' style={{ height: "50vh", width: "100%" }}></div>
        </div>
    }

    if (!isLoading && blog) {
        const { image, author, blogTitle, tags, createdAt, description } = blog?.data;
        content =
            <div>
                <h1 className=' text-left blog-title-sm' >{blogTitle}</h1>

                <div className='d-flex justify-content-between mb-2'>
                    <div>
                        <p className='text-left' style={{ color: "#555555" }}>Posted on: {new Date(createdAt).toDateString()}</p>
                    </div>

                </div>
                <div className='single-blog-image-container'>
                    <Image fluid src={image} alt={blogTitle} />
                </div>

                <div className='d-flex gap-2 mt-2 flex-wrap'>
                    {tags?.map(tag => <p className='blog-tag'>{tag}</p>)}
                </div>
                <div>
                    <div

                        dangerouslySetInnerHTML={{ __html: description }}
                    ></div>
                </div>
            </div>

    }

    return (


        <Container className='mt-3'>
            <Row>
                <Col md={9}>
                    {content}
                </Col>
                <Col md={3}>
                    <h4 className='border-bottom mt-2'> Recent Publish Blog</h4>
                    {
                        recentLoading ? <>
                            {
                                Array.from({ length: 5 }).map(e => <div>
                                    <div className='loading-product mb-1' style={{ height: "15px", width: "100%" }}></div>
                                    <div className='loading-product mb-1' style={{ height: "100px", width: "100%" }}></div>
                                    <div className='loading-product mb-1' style={{ height: "5px", width: "100%" }}></div>
                                </div>)
                            }
                        </> : recentBlog?.data?.map(recent => <Row as={Link} to={`/blog/${recent.slug}`} className='related-blog-parent  border-bottom'>
                            <p className='related-blog-title'>{recent?.blogTitle}</p>
                            <div className='related-blog-container related-blog-container-mobile '>
                                <p className='related-blog-description' dangerouslySetInnerHTML={{ __html: recent?.description }} />
                                <img className='related-blog-img' src={recent?.image} alt="" />
                            </div>

                        </Row>)
                    }

                </Col>

            </Row>
        </Container>
    );
};

export default SingleBlog;