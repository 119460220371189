import React from "react";
import ReactStars from "react-rating-stars-component";

import { Col, Row } from "react-bootstrap";
import { MdVerified } from "react-icons/md";
const SingleReview = ({ review }) => {
    const name = review?.user?.name;
    return (
        <Row className="border-top py-5">
            <Col md={3} className="border-end">
                <div>
                    <div className="fw-bold">Product Reviews</div>
                    <MdVerified style={{ color: "green" }} /> Verified Customer
                </div>
                <div>{review?.user?.name ? name : ""}</div>
            </Col>
            <Col md={9} className="ps-4">
                <ReactStars
                    count={5}
                    edit={false}
                    size={24}
                    activeColor="#ffd700"
                    value={review?.rating}
                />
                <date>{new Date(review?.createdAt).toLocaleDateString()}</date>

                <div className="mt-3" style={{ fontSize: "0.9375rem" }}>
                    {review?.description}
                </div>

                <div
                    className="d-flex align-items-center justify-content-end mt-3"
                    style={{ fontSize: "0.85rem" }}
                >
                    <div className="d-flex align-items-center">
                        Was this review helpful?
                        <span className=" ms-2">Yes</span>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default SingleReview;
