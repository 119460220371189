import React from 'react';
import { Image } from 'react-bootstrap';
import noData from '../../../assets/illustrations/no-data.svg';

const NoData = () => {
	return (
		<div className="text-center mt-5">
			<Image src={noData} alt="No Data Found" style={{ width: '148px' }} />
			<div className="text-center mt-3">No Data Found</div>
		</div>
	);
};

export default NoData;
