import React from 'react';
import { Image, Stack } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
	useAddToCartNewDataByEmailMutation,
	useDecreaseQuantityMutation,
	useDeleteCardDataByEmailIdMutation,
} from '../../redux/addToCart/addToCartApi';
import {
	quantityIncrease,
	removeFromCart,
} from '../../redux/addToCart/addToCartSlice';
import SmallLoader from '../Ui/loading/SmallLoader';
import { calculateDiscount } from '../../config/calculateDiscount';

const ProductHorizontalCard = ({ cart }) => {
	const userEmail = useSelector((state) => state?.user?.user?.email);
	const dispatch = useDispatch();
	const [deleteCardDataByEmailId, { isLoading: deleteLoading }] =
		useDeleteCardDataByEmailIdMutation();

	const [
		addToCartNewDataByEmail,
		{ isLoading: updateLoading },
	] = useAddToCartNewDataByEmailMutation();

	const [
		decreaseQuantity,
		{ isLoading: decreaseLoading, },
	] = useDecreaseQuantityMutation();

	const handleDelete = (id) => {
		const productId = { productId: id };

		if (userEmail) {
			deleteCardDataByEmailId({ productId, userEmail });
		} else {
			dispatch(removeFromCart(id));
		}
	};

	// calculate discount if exists or not
	const priceAfterDiscount = calculateDiscount(cart?.product); // call the function to calculate discount

	const incrementCounter = (id) => {
		const data = { product: id };

		if (userEmail) {
			addToCartNewDataByEmail({ userEmail, data });
		} else {
			dispatch(quantityIncrease({ id, add: true }));
		}
	};

	const decrementCounter = (id, quantity) => {
		if (userEmail) {
			// decrease quantity
			const data = { minusQty: quantity, product: cart?.product?._id };
			decreaseQuantity({ userEmail, data });
		} else {
			dispatch(quantityIncrease({ id, add: false }));
		}
	};
	const { _id, name, images, price } = cart?.product || {};
	return (
		<Stack direction="horizontal" gap={3} className="pb-3 border-bottom">
			<div style={{ width: '100px' }}>
				<Image
					key={_id}
					src={images?.[0]}
					style={{ width: '100px', height: '100px' }}
				/>
			</div>
			<div className="w-100">
				<div className="d-flex align-items-start justify-content-between">
					<div style={{ fontSize: '14px', fontWeight: 'bold' }}>
						{name}
					</div>
					<button
						onClick={() => handleDelete(_id)}
						className="d-flex border-0 rounded-circle align-items-center justify-content-center p-2"
					>
						{deleteLoading ? <SmallLoader /> : <AiOutlineDelete size={16} />}
					</button>
				</div>
				<div style={{ fontSize: '12px' }}>30ml</div>
				<div className="mt-2 d-flex align-items-center justify-content-between">
					<div
						className="d-flex align-items-center justify-content-between border"
						style={{ minWidth: '104px' }}
					>
						<button
							onClick={() =>
								decrementCounter(_id, cart?.quantity)
							}
							disabled={cart?.quantity <= 1}

							cursor={'pointer'}
							className="px-2 py-1 d-flex align-items-center justify-content-center border-0 btn-decrease"
						>
							{decreaseLoading ? (
								<SmallLoader />
							) : (
								<MdRemove size={16} />
							)}
						</button>
						{cart?.quantity}
						<button
							onClick={() => incrementCounter(_id)}
							cursor={'pointer'}
							className="px-2 py-1 d-flex align-items-center justify-content-center border-0 btn-increase"
						>
							{updateLoading ? <SmallLoader /> : <MdAdd size={16} />}
						</button>
					</div>
					<div style={{ fontSize: '15px' }}>
						{' '}
						{priceAfterDiscount < price ? (
							<span>
								৳ {priceAfterDiscount}
								<br />
								<strike className="text-secondary">
									৳ {price}
								</strike>
							</span>
						) : (
							<span>৳ {price}</span>
						)}
					</div>
				</div>
			</div>
		</Stack>
	);
};

export default ProductHorizontalCard;
