import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import 'react-loading-skeleton/dist/skeleton.css';
import Slider from 'react-slick';
import { useLayoutEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useWindowSize } from '../../../hooks/useWindowSize';
import ProductCard from '../ProductCard/ProductCard';
import './swiper.css';
import { ArrowLeft, ArrowRight } from '../Button/SliderButtonj';

const SwiperCarousel = ({
	productList,
}) => {
	const sliderRef = useRef(null);


	const size = useWindowSize();
	const [isSmallDevice, setSmallDevice] = useState();

	useLayoutEffect(() => {
		if (size.width < 800) {
			setSmallDevice(true);
		} else {
			setSmallDevice(false);
		}
	}, [size]);


	const handlePrev = () => {
		sliderRef.current.slickPrev();
	};

	const handleNext = () => {
		sliderRef.current.slickNext();
	};

	const settings = {
		dots: true,
		slidesToShow: 4,
		slidesToScroll: 2,
		prevArrow: !isSmallDevice && <ArrowLeft />,
		nextArrow: !isSmallDevice && <ArrowRight />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 320,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 2,
				},
			},
		],

	};

	return (
		<div>
			<Slider
				{...settings}
				style={{ minHeight: isSmallDevice ? '0' : '420px' }}
				className="shop-by-tab-tiles-container"
				ref={sliderRef}
			>
				{productList &&
					productList?.map((product) => (
						<div
							key={product?._id}
							className="h-auto d-flex justify-content-center"
						>
							<ProductCard cart={product} height={true} shortHeight={true} />
						</div>
					))}
			</Slider>

			{isSmallDevice && (
				<div
					className="arrow-margin "

					style={{
						display: 'flex',
						justifyContent: 'space-between',
						position: 'relative',
						padding: "0px 3rem",
						bottom: '0px',
						zIndex: '1000',
					}}
				>
					<div style={{ cursor: 'pointer' }} onClick={handlePrev}>
						<BsArrowLeft size={25} />
					</div>

					<div style={{ cursor: 'pointer' }} onClick={handleNext}>
						<BsArrowRight size={25} />
					</div>
				</div>
			)}
		</div>
	);
};

export default SwiperCarousel;
