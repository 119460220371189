import React from "react";
import { Container } from "react-bootstrap";
import "./Heading.scss";

const Heading = ({ children, className }) => {
  return (
    <Container className={className}  >
      <h1 className="mt-4 fw-bold heading-font">{children}</h1>
      <div className="doted-container"></div>
    </Container>
  );
};

export default Heading;
