import { apiSlice } from "../api/apiSlice";

export const reviewApi = apiSlice.injectEndpoints({
    tagTypes: ["AllReviews", "SingleReview"],
    endpoints: (builder) => ({
        // review api

        //get all reviews
        getAllReviewsByProductId: builder.query({
            query: (id) => ({
                url: `/review/allByProductId/${id}`,
                method: "POST"
            }),
            providesTags: ["AllReviews"],
        }),

        //get all reviews BY EMAIL
        getAllReviewsByEmail: builder.query({
            query: (userEmail) => ({
                url: `/review/get/${userEmail}`,
                method: "POST"
            }),
            providesTags: ["SingleReview"],
        }),

        //add a  reviews
        addSingleReview: builder.mutation({
            query: ({ data, userEmail }) => ({
                url: `/review/${userEmail}`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["AllReviews", "SingleProductGet", "SingleReview"],
        }),
    }),
});

export const {
    useGetAllReviewsByProductIdQuery,
    useGetAllReviewsByEmailQuery,
    useAddSingleReviewMutation,
} = reviewApi;
