import { useSelector } from "react-redux";

function useAuth() {
  const user = useSelector((state) => state.user);

  if (user?.accessToken && user?.user) {
    return true;
  } else {
    return false;
  }
}
export default useAuth;
