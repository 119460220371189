import { apiSlice } from "../api/apiSlice";

export const blogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get all blogs

    getAllBlogsData: builder.query({
      query: () => ({
        url: `/blogs`,
        method: "POST",
      }),
    }),
    // get single blog by slug
    getSingleBlog: builder.query({
      query: (slug) => ({
        url: `/blogs/single/${slug}`,
        method: "POST",
      }),
    }),

    // get recent blogs
    getRecentBlogs: builder.query({
      query: () => ({
        url: `/blogs/recent`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetAllBlogsDataQuery,
  useGetSingleBlogQuery,
  useGetRecentBlogsQuery,
} = blogApi;
