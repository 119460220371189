import React from 'react';
import CategoryCarusel from './ShopBy/CategoryCarusel';

const BestSeller = ({ products }) => {



    return <CategoryCarusel product={true} data={products} />;

};

export default BestSeller;
