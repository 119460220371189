import React from 'react';

function SlickIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="17"
			viewBox="0 0 22 17"
			style={{
				stroke: 'rgb(222, 225, 192)',
				width: '60%',
				height: '60%',
			}}
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g
					fill="rgb(16, 17, 15)"
					fillRule="nonzero"
					transform="translate(-1347 -1226)"
				>
					<g transform="matrix(-1 0 0 1 1369 1226)">
						<path d="M0 8.298c.011.291.131.628.331.84l6.519 6.926c.441.397 1.288.561 1.782.102.486-.452.472-1.328-.013-1.782L4.036 9.52h16.742a1.222 1.222 0 000-2.444H4.036l4.583-4.864c.417-.418.494-1.325.013-1.783C8.15-.028 7.273.12 6.85.531L.33 7.458a1.176 1.176 0 00-.331.84z"></path>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default SlickIcon;
