import React from "react";
import { Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import verificationImage from "../../../assets/images/verificationEmail.png";
import "./VerificationEmail.scss";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const VerificationEmail = () => {

  const userEmail = useSelector((state) => state?.user?.user?.email);

  const navigate = useNavigate();
  // resend email
  const handleResendEmail = async () => {
    try {
      if (userEmail) {
        // await sendEmailVerification();
        toast.success("Email has been sent, check your mail");
      } else {
        toast.error("Login first and try again.");
      }
    } catch (error) { }
  };

  //handle navigate
  const handleNavigate = () => {
    navigate("/account/login");
  };

  return (
    <div>
      <h1 className="text-center ">Verify Your Email</h1>
      <h4 className="text-center px-2 ">
        Check your email/spam &amp; Click the link to activate your account.
      </h4>
      <div className="d-flex justify-content-center align-items-center my-4">
        <Image
          className="verification_image"
          fluid
          src={verificationImage}
        ></Image>
      </div>
      {/* {error && <p className="text-danger font-bold">{verificationError}</p>} */}
      <div className="d-flex justify-content-center align-items-center gap-2">
        <Button
          variant="dark"
          onClick={handleResendEmail}
          style={{ width: "9.375rem" }}
        >
          {/* {sending ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            "Resend Email"
          )} */}
          Resend Email
        </Button>
        <Button variant="dark" onClick={handleNavigate}>
          Enter New Email
        </Button>
      </div>
    </div>
  );
};

export default VerificationEmail;
