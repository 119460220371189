
//calulate discount
export const calculateDiscount = (product) => {
	let discountPrice = 0;

	if (product?.timeStamps) {
		discountPrice = (
			product.price -
			(product.discountPercentage / 100) * product?.price
		).toFixed(2);

	} else if (product?.offers) {
		const { status, discountType, discount } =
			product.offers;

		if (
			status === 'active'
		) {
			if (discountType === 'percentage') {
				discountPrice = (
					product.price -
					(discount / 100) * product.price
				).toFixed(2);
			} else if (discountType === 'fixedAmount') {
				discountPrice = (product?.price - discount);
			}
		}
	}

	return Math.round(discountPrice || product?.price);
};
