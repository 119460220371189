import Accordion from "react-bootstrap/Accordion";
import "./AccordionSection.scss";
function AccordionSection({ product }) {
  return (
    <Accordion className="mt-5">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="shadow-none" style={{ lineHeight: "0px" }}>
          ACTIVITIES
        </Accordion.Header>
        <Accordion.Body>
          {product?.activities && (
            <div
              dangerouslySetInnerHTML={{ __html: product?.activities }}
            ></div>
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header style={{ lineHeight: "0px" }}>
          DIRECTIONS
        </Accordion.Header>
        <Accordion.Body>
          {product?.directions && (
            <div
              dangerouslySetInnerHTML={{ __html: product?.directions }}
            ></div>
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header className="shadow-none" style={{ lineHeight: "0px" }}>
          INGREDIENTS
        </Accordion.Header>
        <Accordion.Body>
          {product?.ingredients && (
            <div
              dangerouslySetInnerHTML={{ __html: product?.ingredients }}
            ></div>
          )}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header style={{ lineHeight: "0px" }}>
          SHIPPING
        </Accordion.Header>
        <Accordion.Body>
          {product?.shipping && (
            <div dangerouslySetInnerHTML={{ __html: product?.shipping }}></div>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionSection;
