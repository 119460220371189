import React from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';

const Faq = () => {
    return (
        <Container>
            <div className='text-center'>
                <h1 style={{ fontSize: '2.4rem' }} className='fw-bold pt-4'>Do You Have Questions?</h1>
                <h5 className='fw-semibold pt-2 pb-3'>We have answers(well,most of the times!)</h5>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem excepturi temporibus quod? Magnam libero nihil quia autem deleniti tempora, voluptate perferendis deserunt laborum. Esse, iure sunt vel enim maiores assumenda.</p>
            </div>
            <div className='d-flex justify-content-center pt-5'>
                <img style={{ width: '30rem', height: '20rem' }} className='' src="https://img.freepik.com/free-vector/faqs-concept-illustration_114360-5215.jpg?size=626&ext=jpg&ga=GA1.2.785594726.1674109017&semt=sph" alt="" />
            </div>

            <Row className='py-5'>
                <Col xs={12} sm={6} md={6} >
                    <h1 className='text-center' >Our Online Service FAQ</h1>
                    <div>
                        <Accordion >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header >  <span className='fw-bold fs-6'>Question no 1?</span></Accordion.Header>
                                <Accordion.Body  className='text-secondary fw-semibold '>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                            <Accordion.Header >  <span className='fw-bold fs-6'>Question no 1?</span></Accordion.Header>
                                <Accordion.Body className='text-secondary fw-semibold'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                            <Accordion.Header >  <span className='fw-bold fs-6'>Question no 1?</span></Accordion.Header>
                                <Accordion.Body className='text-secondary fw-semibold'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                            <Accordion.Header >  <span className='fw-bold fs-6'>Question no 1?</span></Accordion.Header>
                                <Accordion.Body className='text-secondary fw-semibold'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={6} >
                    <h1 className='text-center' >Product Delivary FAQ</h1>
                    <div>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header >  <span className='fw-bold fs-6'>Question no 1?</span></Accordion.Header>
                                <Accordion.Body className='text-secondary fw-semibold'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                            <Accordion.Header >  <span className='fw-bold fs-6'>Question no 1?</span></Accordion.Header>
                                <Accordion.Body className='text-secondary fw-semibold'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                            <Accordion.Header >  <span className='fw-bold fs-6'>Question no 1?</span></Accordion.Header>
                                <Accordion.Body className='text-secondary fw-semibold'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                            <Accordion.Header >  <span className='fw-bold fs-6'>Question no 1?</span></Accordion.Header>
                                <Accordion.Body className='text-secondary fw-semibold'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Col>

            </Row>
        </Container>
    );
};

export default Faq;