import { apiSlice } from "../api/apiSlice";

export const wishListApi = apiSlice.injectEndpoints({
  tagTypes: ["WishListData"],
  endpoints: (builder) => ({
    //get all add to cart data by email address
    getWishListDataByEmail: builder.query({
      query: (email) => ({
        url: `/wishList/get/${email}`,
        method: "POST",
      }),
      providesTags: ["WishListData"],
    }),

    addToWishListByEmail: builder.mutation({
      query: ({ userEmail, data }) => ({
        url: `/wishList/${userEmail}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["WishListData"],
    }),

    deleteWishListDataByEmailId: builder.mutation({
      query: ({ data, userEmail }) => ({
        url: `/wishList/${userEmail}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["WishListData"],
    }),
  }),
});

export const {
  useGetWishListDataByEmailQuery,
  useAddToWishListByEmailMutation,
  useDeleteWishListDataByEmailIdMutation,
} = wishListApi;
