import { apiSlice } from "../api/apiSlice";

export const productsApi = apiSlice.injectEndpoints({
  tagTypes: ["SingleProductGet", "AllReviews", "SingleReview"],
  endpoints: (builder) => ({
    //get all products
    getAllProducts: builder.query({
      query: () => ({
        url: `/product`,
        method: "POST"
      }),
    }),

    //get search suggestion
    getSearchSuggestion: builder.query({
      query: (query) => ({
        url: `/product/searchSuggestion?search=${query}`,
        method: "POST"
      }),
    }),

    //get all products by search criteria
    getAllProductsBySearch: builder.query({
      query: (queryUrl) => ({
        url: `/product${queryUrl}`,
        method: "POST"
      }),
    }),

    //get campaign products  slug
    getCampaignProducts: builder.query({
      query: (slug) => ({
        url: `/product/campaign-products/${slug}`,
        method: "POST"
      }),
    }),

    //get all products by search criteria
    getLatestProducts: builder.query({
      query: () => ({
        url: `/product/latest-product`,
        method: "POST"
      }),
    }),

    //get related products by product idgetBrandsProductsByBrandId
    getRelatedProductsByProductId: builder.query({
      query: (id) => ({
        url: `/product/related-products/${id}`,
        method: "POST"
      }),
    }),

    //get brands products by product id
    getBrandsProductsByBrandId: builder.query({
      query: ({ brandId, page = "?page=1" }) => ({
        url: `/product/brand-products/${brandId}${page}`,
        method: "POST"
      }),
    }),
    //get brands products by product id
    getBrandProductsByBrandSlug: builder.query({
      query: ({ brandId, page = "?page=1" }) => ({
        url: `/product/brand-products/slug/${brandId}${page}`,
        method: "POST"
      }),
    }),

    //get all products by category
    getAllProductsByCategory: builder.query({
      query: (id) => ({
        url: `/product/category/${id}`,
        method: "POST"
      }),
    }),

    //get single product
    getSingleProduct: builder.query({
      query: (id) => ({
        url: `/product/${id}`,
        method: "POST"
      }),
      providesTags: ["SingleProductGet"],
    }),

    //get new arrivals product
    getProductsByType: builder.query({
      query: (type) => ({
        url: `/product/productType?productType=${type}`,
        method: "POST"
      }),
    }),

  }),
});

export const {
  useGetAllProductsQuery,
  useGetLatestProductsQuery,
  useGetAllProductsByCategoryQuery,
  useGetBrandProductsByBrandSlugQuery,
  useGetSingleProductQuery,
  useGetProductsByTypeQuery,
  useGetAllProductsBySearchQuery,
  useGetRelatedProductsByProductIdQuery,
  useGetBrandsProductsByBrandIdQuery,
  useGetCampaignProductsQuery,
  useGetSearchSuggestionQuery,
} = productsApi;
