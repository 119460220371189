import React from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import "./progressbar.scss";

const ShowOrderDetailsModal = ({ showDetailModal, setShowDetailModal, order }) => {
    const closeModal = () => {
        setShowDetailModal(false)
    }
    const { totalAmount, orderId, discountAmount, products, status, createdAt, shippingCharge } = order || {};

    return (
        <Modal
            className="d-flex  align-items-center"
            show={showDetailModal}
            onHide={closeModal}
            id="orderDetail"
        >
            <Modal.Header closeButton>
                <div>
                    <h6 className="fw-semibold  mt-1">Order details</h6>
                    <div className='d-flex flex-column'>
                        <small className="text-primary">Order Id: {orderId}</small>
                        <small>
                            Date: {`${new Date(createdAt).toLocaleString()}`}
                        </small>
                        <small className="">Total: {totalAmount}</small>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>



                <ul className="progressbar">
                    <li className={`${(status === 'pending' || status === "delivered" || status === 'picked by courier' || status === "processing") ? 'active' : ''} mb-4`}>Pending</li>

                    <li className={`${(status === "delivered" || status === 'picked by courier' || status === "processing") ? 'active' : ''} mb-4`}>Processing</li>

                    <li className={`${(status === "delivered" || status === 'picked by courier') ? 'active' : ''} mb-4`}>Shipped</li>

                    <li className={`${status === "delivered" ? 'active' : ''} mb-4`}>Delivered</li>
                </ul>



                {
                    products?.map((orderData) => (
                        <Row key={orderData?._id} className="mt-4 border">
                            <Col xs={4} md={2}>
                                <Image src={orderData?.product?.images?.[0]} fluid />
                            </Col>
                            <Col xs={6} md={8}>
                                <p className="">{orderData?.product?.name}</p>
                                <small className="order__status">
                                    Order  {status}
                                </small>
                            </Col>
                            <Col xs={2} md={2}>
                                <div className="d-flex align-items-center flex-column mt-0">
                                    <p>Qty</p>
                                    <p>{orderData?.quantity}</p>
                                </div>
                            </Col>
                        </Row>
                    ))
                }

            </Modal.Body>
            <div className='border-bottom '></div>
            <div className='mt-2 px-3 pb-3'>
                <h6 className='fw-bold'>Order summery</h6>
                {<small className='d-flex justify-content-between'>Sub total: <span>
                    {(totalAmount + (discountAmount || 0)) - shippingCharge} ৳
                </span> </small>}
                <small className='d-flex justify-content-between mb-1'>Shipping: <span>{shippingCharge} ৳ </span> </small>

                {discountAmount && <small className='d-flex justify-content-between mb-1 '>Discount: <span>
                    - {discountAmount} ৳
                </span> </small>}
                <small className='d-flex justify-content-between border-top'>Total amount: <span>
                    {totalAmount} ৳
                </span> </small>
            </div>


        </Modal>
    );
};

export default ShowOrderDetailsModal;