import React from 'react';
import { Container } from 'react-bootstrap';
import Heading from '../../../components/Ui/Heading';
import { useGetDeliveryInfoQuery } from '../../../redux/pages/pagesApi';

const DeliveryInformation = () => {
	const { data: deliveryInfo, isLoading } = useGetDeliveryInfoQuery();

	return (
		<Container>
			<Heading>Delivery Information</Heading>
			{deliveryInfo && (
				<div
					dangerouslySetInnerHTML={{
						__html: deliveryInfo?.data?.[0]?.description,
					}}
				/>
			)}

		</Container>
	);
};

export default DeliveryInformation;
