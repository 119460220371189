import React from "react";
import { Container, Row } from "react-bootstrap";


const SubTotal = () => {

  return (
    <Container>
      <Row className="bg-light p-3">
        <div>
          <h4>Order Summery</h4>
        </div>
        <div className="d-flex justify-content-between my-2"  >
          <p className="text-secondary fw-semibold">Subtotal (3 items)</p>
          <p className="fw-semibold">৳ 1,113</p>
        </div>
        <div className="d-flex justify-content-between my-3" >
          <p className="text-secondary fw-semibold">Shipping Fee</p>
          <p className="fw-semibold">৳ 79</p>
        </div>
        <div className="d-flex justify-content-between my-3" >
          <input className=" border-1 p-2 " style={{ width: "16rem", height: "2.5rem" }} placeholder="Enter Voucher Code" type="text" name="" id="" />
          <button style={{ backgroundColor: "#25A5D8" }} className="px-4 text-light fw-bold border-0">Apply</button>
        </div>
        <div className="d-flex justify-content-between my-3" >
          <p className=" fw-semibold">Total</p>
          <p style={{ color: "#F57224" }} className="fw-semibold">৳ 1,192
          </p>
        </div>
        <button style={{ backgroundColor: "#F57224" }} className="p-2 border-0 text-light ">PROCEED TO CHECKOUT (1)</button>
      </Row>
    </Container>
  );
};

export default SubTotal;
