import { memo, useEffect, useLayoutEffect, useState } from "react";
import { Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { calculateDiscount } from "../../../config/calculateDiscount";
import { spaceToDash } from "../../../config/spaceToDash";
import { useAddToCartNewDataByEmailMutation } from "../../../redux/addToCart/addToCartApi";
import { addToCart } from "../../../redux/addToCart/addToCartSlice";
import { setCartDrawer } from "../../../redux/modal/modalSlice";
import { useAddToWishListByEmailMutation } from "../../../redux/wishList/wishListApi";
import CountDown from "./CountDown";
import "./ProductCard.scss";
import { useWindowSize } from "../../../hooks/useWindowSize";
import SmallLoader from "../loading/SmallLoader";

const ProductCard = ({ cart, height, shortHeight } = {}) => {
  const userEmail = useSelector((state) => state.user?.user?.email);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {

    name,
    price,
    images,

    manufacturer,

    expireDate,


    timeStamps,
    offer,
    slug,
    badge
  } = cart || {};
  const { timeStamps: offerTimeStamps } = offer || {};

  //::::: add to card data redux hook  ::::://
  const [
    addToCartNewDataByEmail,
    {
      isLoading: addCartLoading,
      isSuccess: addToCartSuccess,
      isError: addToCartError,
    },
  ] = useAddToCartNewDataByEmailMutation();

  //::::: add to wishlist data redux hook ::::://
  const [
    addToWishListByEmail,
    {
      isLoading: addWishListLoading,
      isSuccess: wishListSuccess,
      isError: wishListError,
      error: wishListErr,
    },
  ] = useAddToWishListByEmailMutation();

  const priceAfterDiscount = calculateDiscount(cart);

  // handle add to cart  function
  const handleAddToBag = (cart) => {

    if (userEmail) {
      const data = {
        product: cart._id,
        offerPrice: priceAfterDiscount,
      };

      addToCartNewDataByEmail({ userEmail, data });
    } else {
      dispatch(
        addToCart({
          product: cart,
          qty: 1,
          // color: color,
          // size: size,
        })
      );
      toast.success("Item added to bag");
    }
  };

  // add to wishlist handler
  const handleWishList = (productId) => {
    const data = { product: productId };
    if (userEmail) {
      addToWishListByEmail({ userEmail, data });
    } else {
      navigate("/account/login");
    }
  };
  // add to wishlist handler
  const handleNavigate = (slug) => {
    navigate(`/product-detail/${slug}`)
  };


  useEffect(() => {
    toast.dismiss();

    addToCartError && toast.error("Failed add to cart", { id: "fError" });
    wishListError &&
      toast.error(wishListErr.data.message || "Wishlist added failed", {
        id: "wError",
      });
    wishListSuccess &&
      toast.success("Successfully added to wishList", { id: "wSuccess" });
    if (addToCartSuccess && !addToCartError) {
      dispatch(setCartDrawer(true));
    }
  }, [addToCartSuccess, wishListSuccess, wishListError, addToCartError, dispatch, wishListErr]);

  const [isSmallDevice, setSmallDevice] = useState();
  const size = useWindowSize();

  useLayoutEffect(() => {
    if (size.width <= 800) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  }, [size]);

  return (
    <div
      className={`main-card ${height && "height"} ${shortHeight && "shortHeight"
        } mx-auto`}
    >
      <Card className="border-0 ">
        <Link
          className="text-decoration-none"
          to={`/product-detail/${slug ? slug : spaceToDash(name)}`}
        >
          <div
            style={{ width: "100%", height: "100%" }}
            className=" card-image-container img-container d-flex justify-content-center  position-relative"
          >
            {expireDate && (
              <p className="expire-date exp-mbl">
                <p className="px-2 ">
                  Exp: {new Date(expireDate).toLocaleDateString()}
                </p>
              </p>
            )}
            <Image
              className="px-2 py-2 "
              src={images?.[0]}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
            {/* clearance sale offer */}
            {(timeStamps || offerTimeStamps) && (
              <CountDown timeStamps={timeStamps || offerTimeStamps} />
            )}
            {/* {timeStamps && <CountDown timeStamps={timeStamps} />} */}
          </div>
          <div style={{ minHeight: "26px" }} className="d-flex justify-content-center product-div">
            {badge?.map((b, index) => <button key={index} className="product-btn1 btn-mbl-1">{b?.name}</button>)}
          </div>
        </Link>
        <Card.Body className="pt-2 pb-0">
          <h5 className=" price-mbl text-center fw-semibold text-black mb-2">
            <small>{manufacturer?.name}</small>
          </h5>
          <h6 className="cart-title ">{name}</h6>

          <h5 className="fs-6 mb-1 text-center fw-semibold text-secondary">
            <p className="price-mbl" style={{}}>
              {price !== priceAfterDiscount && <s>৳{price}</s>}
              <span className="ms-1" style={{ color: "black" }}>
                ৳ {priceAfterDiscount}
              </span>
            </p>
          </h5>

          {/* hover section */}
        </Card.Body>
      </Card>
      <div className="container  intro pt-0">
        <div className="d-flex justify-content-between  text-center   ">
          <button className="border-0 bg-transparent" onClick={() => handleWishList(cart?._id)}>
            <AiOutlineHeart
              size={isSmallDevice ? 20 : 25}
              className="icon-mbl-1 ms-2 ms-md-2 mt-md-2 mt-2"
            />
          </button>
          <button
            onClick={() => handleAddToBag(cart)}
            className="cart-btn border-0 cart-lg px-4 text-light fw-bold button-height"
            style={{ backgroundColor: "#DC3545" }}
          >
            <div className="auto-scroll demo-cls">
              <p className="lg-p mb-p">ADD TO CART </p>{" "}
              {addCartLoading ? <SmallLoader /> : <span className="d-flex align-items-center justify-content-center gap-1"><small className="mt-2 mt-md-1">{priceAfterDiscount}  </small>  <AiOutlineShoppingCart size={18} className="mt-2" /></span>}
            </div>
          </button>{" "}
          <button className="border-0 bg-transparent" onClick={() => handleNavigate(cart?.slug)}>
            <MdSearch
              size={isSmallDevice ? 20 : 25}
              className="icon-mbl me-2 me-md-2 mt-md-2 mt-2"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(ProductCard);
