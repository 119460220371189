import React from "react";
import { Card, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./CategoryImages.scss";

const CategoryImages = ({ category, isDragging }) => {
  const navigate = useNavigate();
  const handleProductClick = (e) => {
    if (isDragging) {
      e.preventDefault();
    }
    navigate(`/products?category=${category?.slug}`)
  };
  return (
    <Container>
      <Card>
        <Link
          to={`/products?category=${category?.slug}`}
          className="text-black"
          style={{ position: "relative" }}
          onClick={handleProductClick}
        >
          <Card.Img
            variant="top"
            src={
              category?.image ||
              "https://cdn.shopify.com/s/files/1/0249/1218/files/Then-I-Met-You-Cleansing-Balm-2-resized_600x.jpg"
            }
            alt="Product Image"
          />
          <div
            style={{
              position: "absolute",
              bottom: "5px",
              left: "50%",
              transform: "translateX(-50%)",
              textAlign: "center",
              width: "100%",
            }}
          >
            <h4 style={{ fontSize: "1rem" }} className="">
              {category?.title}
            </h4>
          </div>
        </Link>
      </Card>
    </Container>
  );
};

export default CategoryImages;
