import React from "react";

const SslCommerzPayment = () => {
  return (
    <div>
      <h1 className="text-center">SSL Commerz payment page</h1>
    </div>
  );
};

export default SslCommerzPayment;
