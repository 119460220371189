import { Container } from "react-bootstrap";
import CategorizedProductCarousel from "../../components/Home/CategorizedProductCarousel";
import NewArrivalsTabs from "../../components/Home/NewArrivalsTabs/NavBarTabs";
import FeaturedProducts from "./FeaturedProducts";
import PopularProducts from "./PopularProducts";
import { useGetProductsByTypeQuery } from "../../redux/products/productsApi";


const ShopAll = () => {
  const {
    data: regularProducts,
    isLoading,
    isError,
  } = useGetProductsByTypeQuery("regular-product");


  return (
    <>


      <NewArrivalsTabs />
      <FeaturedProducts />
      <PopularProducts />

      <Container>
        {
          <CategorizedProductCarousel
            title={"Regular Products"}
            productList={regularProducts?.result}
            isLoading={isLoading}
            isError={isError}
          />
        }
      </Container>
    </>
  );
};
export default ShopAll;
