import { useLayoutEffect, useRef, useState } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import "react-loading-skeleton/dist/skeleton.css";
import Slider from "react-slick";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import CategoryImages from "../../MobileDropdown/CategoryImages";
import ProductCard from "../../../Ui/ProductCard/ProductCard";
import { ArrowLeft, ArrowRight } from "../../../Ui/Button/SliderButtonj";
import "./CategoryCarusel.scss";

const CategoryCarusel = ({ data, product }) => {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const [isSmallDevice, setSmallDevice] = useState();
  const size = useWindowSize();

  useLayoutEffect(() => {
    if (size.width <= 800) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  }, [size]);

  const settings = {
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    prevArrow: !isSmallDevice && <ArrowLeft />,
    nextArrow: !isSmallDevice && <ArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => {
      setIsDragging(true);
    },
    afterChange: () => {
      setIsDragging(false);
    },
  };
  return (
    <div>
      <Slider
        {...settings}
        style={{ minHeight: isSmallDevice ? "0" : "420px" }}
        ref={sliderRef}
      >
        {data &&
          data.map((category) => (
            <div
              key={category?._id}
              className="h-auto d-flex justify-content-center mb-2"
            >
              {product ? (
                <ProductCard cart={category} height={true} shortHeight={true} />
              ) : (
                <CategoryImages category={category} isDragging={isDragging} />
              )}
            </div>
          ))}
      </Slider>
      {isSmallDevice && (
        <div
          className="arrow-margin "
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            bottom: "0px",
            padding: "0px 3rem",
            zIndex: "1",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={handlePrev}>
            <BsArrowLeft size={25} />
          </div>

          <div style={{ cursor: "pointer" }} onClick={handleNext}>
            <BsArrowRight size={25} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryCarusel;
